<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Referrals"/>

    <div class="generalBox border shadow-sm">
        <ul class="nav nav-pills">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="pill" href="#referral">Referrals</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#commission">Commissions</a>
            </li>
        </ul>

        <div class="tab-content mt-4">
            <!-- Tab: referral -->
            <div class="tab-pane container active" id="referral">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="card">
                            <div class="card-header">
                                <a class="btn text-start" data-bs-toggle="collapse" href="#dashShare" style="width: 100%" @click="showRefShare = !showRefShare">Refer Friends
                                    <span class="float-end" :hidden="showRefShare === false"><fa icon="chevron-up" /></span>
                                    <span class="float-end" :hidden="showRefShare === true"><fa icon="chevron-down" /></span>
                                </a>
                            </div>

                            <div id="dashShare" class="collapse p-3" :class="showRefShare === true ? 'show' : ''" >
                                <div class="mb-2">
                                    <div class="mb-1">Referral Code</div>
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="referralCode" class="form-control"  />
                                        <button class="btn btn-secondary" @click="copyReferralCode">
                                            <fa icon="copy" class="me-2"/>Copy
                                        </button>
                                    </div>
                                </div>

                                <div class="mb-2">
                                    <div class="mb-1">Referral Link</div>
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="referralLink" class="form-control"  />
                                        <button class="btn btn-secondary" @click="copyReferralLink">
                                            <fa icon="copy" class="me-2"/>Copy
                                        </button>
                                    </div>
                                </div>

                                <div class="mb-2">
                                    <div class="mb-1">Referral Image</div>
                                    <div class="row">
                                        <div class="col-6 text-center">
                                            <div class="mb-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlShareGallery" @click="referralImage = 'referral_share_img_1.jpg'">
                                                <img alt="refShareImg1" src="../../../public/referral_share_img_1.jpg" class="border img-fluid">
                                            </div>
                                            <button class="btn btn-secondary" @click="downloadReferralImage('referral_share_img_1.jpg')">
                                                <fa icon="download" class="me-2"/>Download
                                            </button>
                                        </div>
                                        <div class="col-6 text-center">
                                            <div class="mb-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlShareGallery" @click="referralImage = 'referral_share_img_2.jpg'">
                                                <img alt="refShareImg2" src="../../../public/referral_share_img_2.jpg" class="border img-fluid">
                                            </div>
                                            <button class="btn btn-secondary" @click="downloadReferralImage('referral_share_img_2.jpg')">
                                                <fa icon="download" class="me-2"/>Download
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="card">
                            <div class="card-header">
                                <a class="btn text-start" data-bs-toggle="collapse" href="#dashComm" style="width: 100%" @click="showRefComm = !showRefComm">Referral Commission
                                    <span class="float-end" :hidden="showRefComm === false"><fa icon="chevron-up" /></span>
                                    <span class="float-end" :hidden="showRefComm === true"><fa icon="chevron-down" /></span>
                                </a>
                            </div>

                            <div id="dashComm" class="collapse p-3" :class="showRefComm === true ? 'show' : ''" >
                                <table class="table table-borderless innerBox mb-0">
                                    <tr>
                                        <td width="47%" class="text-center p-2">
                                            <div class="fw-bold">3</div>
                                            <div class="small">Referee</div>
                                        </td>
                                        <td width="6%" class="text-center">
                                            <div class="d-flex" style="height: 30px;">
                                                <div class="mt-2 vr"></div>
                                            </div>
                                        </td>
                                        <td width="47%" class="text-center p-2">
                                            <div class="fw-bold">63.90</div>
                                            <div class="small">Commission, USD</div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tab: referral End -->

            <!-- Tab: commission -->
            <div class="tab-pane container" id="commission">
                <div class="row mb-2">
                    <div class="col-lg-5 mb-2">
                        <table>
                            <tr>
                                <td>
                                    <VueDatePicker v-model="dateFr" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                </td>
                                <td class="px-1">-</td>
                                <td>
                                    <VueDatePicker v-model="dateTo" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-lg-4 mb-2">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <input type="text" v-model="filterEmail" class="form-control" placeholder="Referee Name / Email">
                            <span v-if="filterEmail && filterEmail.length > 0" class="input-group-text clearSearch" @click="filterEmail = ''">
                                <fa icon="xmark" />
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-3 mb-2">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <input type="text" v-model="filterCode" class="form-control" placeholder="Referral Code">
                            <span v-if="filterCode && filterCode.length > 0" class="input-group-text clearSearch" @click="filterCode = ''">
                                <fa icon="xmark" />
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-lg-12 d-flex flex-row">

                        <div class="summBox">
                            <div class="fw-bold">3</div>
                            <div class="small">Referee</div>
                        </div>
                        <div class="summBox">
                            <div class="fw-bold">63.90</div>
                            <div class="small">Commission, USD</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 px-1" ref="scrollComponent">
                        <div v-if="isLoading === true" class="mb-3 text-center">
                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                        </div>
                        <div v-else class="mb-3 text-end small fst-italic">{{totalShown}} of {{total}}</div>
                        
                        <table class="table table-hover" border="0">
                            <thead>
                                <tr>
                                    <th class="align-top">Referee</th>
                                    <th>
                                        <div class="row">
                                            <div class="col-12 col-lg-4"><br /></div>
                                            <div class="col-6 col-lg-2">Date</div>
                                            <div class="col-6 col-lg-2 text-end">Payment, USD</div>
                                            <div class="col-6 col-lg-2 text-end">Commission, USD</div>
                                            <div class="col-6 col-lg-2 text-end">Referral Code</div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, itemIdx) in arrDgItem" :key="item">
                                    
                                    <td width="50px" class="text-center">
                                        <i class="fa-solid fa-user fa-2xl align-bottom"></i>
                                        <!-- <ImgAvatar :key="itemIdx" :isUser="true" id="" displayName="" isVerified="" isActive="" privilege="" width="30" height="30" /> -->
                                        <!-- <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" isVerified="" isActive="" width="30" height="30" /> -->
                                    </td>
                                
                                    <td class="align-middle boder">
                                        <div class="row">
                                            <div class="col-12 col-lg-4">
                                                <div class="fw-bold">{{item.name}}</div>
                                                <div class="text-grey small">{{item.email}}</div>
                                            </div>
                                            <div class="col-6 col-lg-2 text-grey small">{{ func.convDateTimeFormat(item.dateCreate, 'datetext') }}</div>
                                            <div class="col-6 col-lg-2 text-end">
                                                <span class="float-start"><span class="badge bg-secondary">{{item.plan}}</span></span>
                                                <span>{{item.payment}}</span>
                                            </div>
                                            <div class="col-3 col-lg-1 text-end">{{item.commissionPer}}%</div>
                                            <div class="col-3 col-lg-1 text-end">{{item.commission}}</div>
                                            <div class="col-6 col-lg-2 text-end">{{item.referralCode}}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- Tab: commission End -->
        </div>
    </div>
   
    <!-- Modal: Share gallery --> 
    <div
        class="modal fade"
        id="mdlShareGallery"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Referral Image</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <!-- <div class="p-5 text-center"> -->
                                <div v-if="referralImage === 'referral_share_img_1.jpg'">
                                    <!-- <img alt="kolShare1" src="../../assets/signon_kolShare_1.jpg" class="border img-fluid"> -->
                                    <img alt="refShareImg1" src="../../../public/referral_share_img_1.jpg" class="border img-fluid">
                                </div>
                                <div v-if="referralImage === 'referral_share_img_2.jpg'">
                                    <!-- <img alt="kolShare1" src="../../assets/signon_kolShare_2.jpg" class="border img-fluid"> -->
                                    <img alt="refShareImg2" src="../../../public/referral_share_img_2.jpg" class="border img-fluid">
                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="downloadReferralImage(referralImage)">
                        <fa icon="download" class="me-2" />Download
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import funcs from '@/functions/function'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'Referral',
    components: {
        TopNavigation, Alert, Popper, /* Avatar, */ ImgAvatar, VueDatePicker
    },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const dgMode = ref('all')    // all: load all, part: query db part by part
        const dgCutOffRcd = ref(30)  // decide dgMode if >= dgCutOffRcd part, else all ORIG: 30
        const total = ref(0)
        const totalShown = ref(0)   // total records has been displayed
        const rcdFr = ref(0)        // record index from
        const rcdDisplay = ref(15)  // total record to display per scroll, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const arrItem = ref([])       // for dgMode=all, different fr arrDgItem
        const arrFilterItem = ref([]) // for dgMode=all only - handle filter and remain orig data in arrItem
        const arrDgItem = ref([])     // record shown in dg, total length shown as totalShown

        const filterEmail = ref('')
        const filterCode = ref('')
        const dateFr = ref(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 13))
        const dateTo = ref(new Date())
        const totalFilter = ref(0)

        const showRefShare = ref(true) // Toggle refer friend box
        const showRefComm = ref(true)  // Toggle Referral Commission box

        const referralCode = ref(null)
        const referralLink = ref(null)
        const referralImage = ref(null)


        const downloadReferralImage = async (referralImage) => {

            // Download image in public/, iage src to display in assets
            let imgPath = window. location.origin + '/' + referralImage
            const response = await fetch(imgPath)
            const blobImage = await response.blob()

            const href = URL.createObjectURL(blobImage)

            const link = document.createElement('a')
            link.href = href
            link.download = referralImage

            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            window.URL.revokeObjectURL(href)
        } 

        const copyReferralCode = () => {
            const copyText = referralCode.value
            const tmpTextarea  = document.createElement('textarea')
            tmpTextarea.textContent = copyText
            document.body.append(tmpTextarea)
            tmpTextarea.select()
            const res = document.execCommand('copy')
            tmpTextarea.remove()
            if (res === true) {
                alert.value.push({
                    class: "info",
                    title: "INFO",
                    message: "Referral code copied to clipboard."
                })
            }
        }

        const copyReferralLink = () => {
            const copyText = referralLink.value
            const tmpTextarea  = document.createElement('textarea')
            tmpTextarea.textContent = copyText
            document.body.append(tmpTextarea)
            tmpTextarea.select()
            const res = document.execCommand('copy')
            tmpTextarea.remove()
            if (res === true) {
                alert.value.push({
                    class: "info",
                    title: "INFO",
                    message: "Referral link copied to clipboard."
                })
            }
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(async () => {

            func.clearPrevLocalStorage()

            //temp hardcoded
            referralCode.value = "12345"
            referralLink.value = window. location.origin + '/r/' + referralCode.value

            // hardcoded
            isLoading.value = false
            arrDgItem.value = [
                { "name": "Alice", "email": "alice@gmail.com", "dateCreate": "2023-08-02T02:49:20.000Z", "plan": "Plan 30", "payment": "55.00", "commissionPer": 10, "commission": "5.50", "referralCode": "4ab43430"},
                { "name": "Betrice Yong", "email": "betrice@gmail.com", "dateCreate": "2023-07-30T01:39:20.000Z", "plan": "Plan 300", "payment": "464.00", "commissionPer": 10, "commission": "46.40", "referralCode": "4ab43430"},
                { "name": "Jolie", "email": "jolie@gmail.com", "dateCreate": "2023-07-29T02:20:20.000Z", "plan": "Plan 1000", "payment": "120.00","commissionPer": 10, "commission": "12.00", "referralCode": "1195de62"}
            ]

        })

        return { 
            route, router, alert, func, closeAlert, 
            scrollComponent, dgMode, dgCutOffRcd, total, totalShown, rcdFr, rcdDisplay, isLoading, isLoadingMore, 
            arrItem, arrDgItem, total, totalShown, arrFilterItem, filterEmail, filterCode, dateFr, dateTo, totalFilter, 
            showRefShare, showRefComm, referralCode, referralLink, referralImage, copyReferralCode, copyReferralLink, downloadReferralImage,
         }
    }

}
</script>

<style>
.card {
    border: 1px solid var(--accent-color);
    background-color: var(--bgcolor-body-color);
    padding: 15px;
    margin-bottom: 20px;
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--bgcolor-body-color);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
}

.innerBox {
    border-radius: 10px;
    padding: 10px;
    background-color: var(--auth-box-bg);
}

.summBox {
    border-radius: 10px;
    padding: 10px;
    background-color: var(--auth-box-bg);
    width: 160px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
}
</style>