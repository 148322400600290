<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Configuration" icon="fa-solid fa-screwdriver-wrench" />

    <div class="generalFullWidthBox border shadow-sm">

        <ul class="nav nav-pills">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="pill" href="#general">General</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#maintenance">System Maintenance</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#smtp">SMTP</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#plan">Plan</a>
            </li>
        </ul>

        <div class="tab-content mt-4">
            <div class="tab-pane container active" id="general">
                <div class="row">
                </div>
            </div>

            <div class="tab-pane container fade" id="maintenance">
                <div class="row">
                    <div class="col-lg-2 mb-3">Maintenance</div>
                    <div class="col-lg-6 mb-3">
                        <div class="form-check form-switch mb-3">
                            <input class="form-check-input" type="checkbox" v-model="showMaintenance" value="true" checked>
                            <span v-if="showMaintenance" class="ms-1">Enable</span>
                            <span v-else class="ms-1">Disable</span>
                        </div>
                        <div>
                            <textarea class="form-control" rows="5" v-model="maintenanceMsg" placeholder="Maintenance Message" :readonly="!showMaintenance"></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3 text-grey small">
                        All users (except SU) will be force logout within 180 seconds upon  Maintenance Mode activation, with the Maintenance Message display to users
                    </div>
                    <div class="col-12 text-center mt-3">
                        <button class="btn btn-secondary" @click="updProfile">
                            <fa icon="floppy-disk" size="lg" class="me-2" />Update
                        </button>
                    </div>
                </div>
            </div>

            <div class="tab-pane container fade" id="smtp">
                <div class="row">
                    <div class="col-lg-2 mb-1">Host</div>
                    <div class="col-lg-10 mb-3"><input type="text" v-model="host" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">Port</div>
                    <div class="col-lg-10 mb-3"><input type="text" v-model="port" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">Username</div>
                    <div class="col-lg-10 mb-3"><input type="text" v-model="username" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">Password</div>
                    <div class="col-lg-10 mb-3"><input type="password" v-model="password" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">SMTP Auth</div>
                    <div class="col-lg-10 mb-3">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" v-model="smtpAuth" value="true" checked> 
                            <span v-if="smtpAuth" class="ms-1">Enable</span>
                            <span v-else class="ms-1">Disable</span>
                        </div>
                    </div>

                    <div class="col-lg-2 mb-1">SMTP Secure</div>
                    <div class="col-lg-10 mb-3">
                        <select v-model="smtpSecure" class="form-select">
                            <option :value="s.value" v-for="s in smtpSecureOpt" :key="s.value">{{s.label}}</option>
                        </select>
                    </div>

                    <div class="col-12 text-center mt-3">
                        <button class="btn btn-secondary" @click="updProfile">
                            <fa icon="floppy-disk" size="lg" class="me-2" />Update
                        </button>
                    </div>
                </div>
            </div>

            <div class="tab-pane container fade" id="plan">
                <div class="row">
                    <div class="col-6 mb-4">
                        <table>
                            <tr>
                                <td class="pb-2" :class="payInYear === true ? '' : 'text-primary'">Pay Monthly</td>
                                <td class="ps-3 pe-2">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" v-model="payInYear" value="true">
                                    </div>
                                </td>
                                <td class="pb-2" :class="payInYear === true ? 'text-primary' : ''">Pay Yearly</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-6 text-end mb-4">
                        <span data-bs-toggle="modal" data-bs-target="#mdlPlan" @click="clearPlan" class="me-2 text-primary isLink">
                            <fa icon="boxes-stacked" />
                            <span class="me-2 fw-bold" style="font-size: 14px">+</span>Add plan
                        </span>
                    </div>

                    <div class="col-12 col-lg-6 d-grid" v-for="p in arrPlan" :key="p">
                        <div class="generalContentBox border shadow-sm">
                            <div class="fw-bold text-center mb-1">
                                <span>{{p.name}}</span>
                                <span class="float-end">
                                    <div class="dropdown dropstart">
                                        <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                            <fa icon="ellipsis-vertical" class="px-1 text-secondary"/>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-secondary">
                                            <li><a class="dropdown-item" href="#" @click="getPlan(p)" data-bs-toggle="modal" data-bs-target="#mdlPlan">Modify</a></li>
                                            <li><a class="dropdown-item" href="#" @click="getPlan(p)" data-bs-toggle="modal" data-bs-target="#mdlDelPlan">Remove</a></li>
                                        </ul>
                                    </div>
                                </span>

                            </div>
                            <div class="text-center"><hr /></div>
                            
                            <div class="fw-bold mb-2">Features</div>
                            <div>
                                <ul>
                                    <li><span class="fw-bold">{{p.credit}}</span> credits / user</li>
                                    <li><span class="fw-bold">{{p.storage.total}}{{p.storage.unit.toUpperCase()}}</span> storage / user</li>
                                </ul>
                            </div>
                            <div class="fw-bold mt-3 mb-2">Pricing</div>
                            <div>
                                <ul>
                                    <li v-for="pr in p.price" :key="pr">
                                        <span class="fw-bold text-uppercase">{{pr.currency}}</span>
                                        <span v-if="payInYear === false" class="ps-1">{{pr.month}}</span>
                                        <span v-if="payInYear === true" class="ps-1">{{pr.year}}</span>
                                        <span v-if="payInYear === false"> / month</span>
                                        <span v-if="payInYear === true"> / year</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal - Add/Edit plan --> 
    <div
        class="modal fade"
        id="mdlPlan"
        tabindex="-1"
        aria-labelledby="EditPlan"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="planId === null" class="modal-title" id="exampleModalLabel"> Add Plan</h5>
                    <h5 v-else class="modal-title" id="exampleModalLabel"> Edit Plan</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <input type="text" v-model="planName" class="form-control" placeholder="Plan name" />
                        </div>

                        <div class="col-lg-6 mb-3">
                            <div class="input-group">
                                <input type="text" v-model="planCredit" class="form-control" placeholder="Monthly credit">
                                <span class="input-group-text">credits</span>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <div class="input-group">
                                <input type="text" v-model="planStorage" class="form-control" placeholder="Storage">
                                <select v-model="planStorageUnit" class="form-select">
                                    <option :value="u.value" v-for="u in arrUnit" :key="u.value">{{u.label}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-12 fw-bold mb-2">Pricing</div>
                        <div class="col-12" v-for="(p, pIdx) in planPrice" :key="p">
                            <div class="row">
                                <div class="col-lg-4 mb-3">
                                    <select v-model="p.currency" class="form-select">
                                        <option value="" >Select currency</option>
                                        <option :value="cur.value" v-for="cur in arrCur" :key="cur.value">{{cur.value}} - {{cur.label}}</option>
                                    </select>
                                </div>
                                <div class="col-lg-4 mb-3">
                                    <div class="input-group">
                                        <input type="text" v-model="p.month" class="form-control" placeholder="Price">
                                        <span class="input-group-text">Monthly</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 mb-3">
                                    <div class="input-group">
                                        <input type="text" v-model="p.year" class="form-control" placeholder="Price">
                                        <span class="input-group-text">Yearly</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 small text-primary text-end isLink" @click="addPrice">
                            + Add price
                        </div>

                        <div class="col-12">
                            <input class="form-check-input me-3" type="checkbox" v-model="planPublish" value="true"> Publish
                        </div>
                    </div>
                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button v-if="planId === null && planName !== ''" @click="savePlan" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                    <button v-if="planId !== null && planName !== ''" @click="updPlan" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Update
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Confirm Del plan --> 
    <div
        class="modal fade"
        id="mdlDelPlan"
        tabindex="-1"
        aria-labelledby="DelPlan"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Delete Plan</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">Confirm to delete following plan?</div>
                        <div class="col-12 mb-3">
                            <input type="text" v-model="planName" class="form-control" placeholder="Plan name" />
                        </div>

                        <div class="col-lg-6 mb-3">
                            <div class="input-group">
                                <input type="text" v-model="planCredit" class="form-control" placeholder="Monthly credit">
                                <span class="input-group-text">credits</span>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <div class="input-group">
                                <input type="text" v-model="planStorage" class="form-control" placeholder="Storage">
                                <select v-model="planStorageUnit" class="form-select">
                                    <option :value="u.value" v-for="u in arrUnit" :key="u.value">{{u.label}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-12 fw-bold mb-2">Pricing</div>
                        <div class="col-12" v-for="(p, pIdx) in planPrice" :key="p">
                            <div class="row">
                                <div class="col-lg-4 mb-3">
                                    <select v-model="p.currency" class="form-select">
                                        <option value="" >Select currency</option>
                                        <option :value="cur.value" v-for="cur in arrCur" :key="cur.value">{{cur.value}} - {{cur.label}}</option>
                                    </select>
                                </div>
                                <div class="col-lg-4 mb-3">
                                    <div class="input-group">
                                        <input type="text" v-model="p.month" class="form-control" placeholder="Price">
                                        <span class="input-group-text">Monthly</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 mb-3">
                                    <div class="input-group">
                                        <input type="text" v-model="p.year" class="form-control" placeholder="Price">
                                        <span class="input-group-text">Yearly</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 small text-primary text-end isLink" @click="addPrice">
                            + Add price
                        </div>

                        <div class="col-12">
                            <input class="form-check-input me-3" type="checkbox" v-model="planPublish" value="true"> Publish
                        </div>
                    </div>
                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button @click="delPlan" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Remove
                    </button>
                   
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'

export default {
    name: 'BOConfiguration',
    components: { Alert, TopNavigation, Popper },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const showMaintenance = ref(false)
        const maintenanceMsg = ref(null)
        const smtpAuth = ref(true)
        const smtpSecure = ref('SSL')
        const smtpSecureOpt = [
            { label: 'SSL', value: 'SSL'},
            { label: 'TLS', value: 'TLS'}
        ]

        const arrPackage =ref([])
        const arrCur = ref(null)
        const arrUnit = ref(null)

        const planId = ref(null)
        const planName = ref(null)
        const planCredit = ref(null)
        const planStorage = ref(null)
        const planStorageUnit = ref('mb')
        const planPublish = ref(false)
        const planPrice = ref([{ currency: '', month: null, year: null}])
        const payInYear = ref(false)

        const arrPlan = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const addPrice = () => {
            planPrice.value.push({ currency: '', month: null, year: null})
        }

        const getPlan = (data) => {
            console.info('data', JSON.stringify(data))

            planId.value = data.id
            planName.value = data.name
            planCredit.value = data.credit
            planStorage.value = data.storage.total
            planStorageUnit.value = data.storage.unit
            planPublish.value = data.publish
            planPrice.value = data.price
        }

        const clearPlan = () => {

            planId.value = null
            planName.value = null
            planCredit.value = null
            planStorage.value = null
            planStorageUnit.value = 'mb'
            planPublish.value = false
            planPrice.value = [{ currency: '', month: null, year: null}]
        }

        const updPlan = () => {
            console.info('------------------ updPlan', planId.value, planName.value, planCredit.value, planStorageUnit.value, planStorage.value)
            console.info('plaPrice', JSON.stringify(planPrice.value))
        }

        const savePlan = () => {
            console.info('------------------ savePlan', planName.value, planCredit.value, planStorageUnit.value, planStorage.value)
            console.info('plaPrice', JSON.stringify(planPrice.value))
        }

        const delPlan = () => {
            console.info('del plan', planId.value)
        }

        onMounted(() => {
            arrCur.value = [
                { label: 'MYR', value: 'myr'},
                { label: 'USD', value: 'usd'}
            ]

            arrUnit.value = [ 
                { label: 'MB', value: 'mb'},
                { label: 'GB', value: 'gb'}
            ]

            arrPackage.value = [
                {
                    id: '1001',
                    name: 'Free',
                    price: 0,
                    currency: 'myr',
                    period: 'year',
                    publish: true,
                    maxUser: 1,
                    maxDoc: 3,
                    invitation: false,
                    pricePerSignee: null,
                    storage: {
                        unit: 'MB',
                        total: 100
                    }
                },
                {
                    id: '1002',
                    name: 'Personal',
                    price: 200,
                    currency: 'myr',
                    period: 'year',
                    publish: true,
                    maxUser: 1,
                    maxDoc: 0,
                    invitation: false,
                    pricePerSignee: 1,
                    storage: {
                        unit: 'GB',
                        total: 1
                    }
                },
                {
                    id: '1003',
                    name: 'Team',
                    price: 360,
                    currency: 'myr',
                    period: 'year',
                    publish: false,
                    maxUser: 200,
                    maxDoc: 0,
                    invitation: true,
                    pricePerSignee: 0,
                    storage: {
                        unit: 'GB',
                        total: 0
                    }
                },
            ]

            arrPlan.value = [
                {
                    id: '1001',
                    name: 'Free',
                    price: [
                        { currency: 'usd', month: 'FOC', year: 'FOC'}
                    ],
                    publish: true,
                    credit: 2,
                    storage: {
                        unit: 'mb',
                        total: 100
                    }
                },
                {
                    id: '1002',
                    name: 'Plan 30',
                    price: [
                        { currency: 'usd', month: '5', year: '55'},
                        { currency: 'myr', month: '15', year: '166'}
                    ],
                    publish: true,
                    credit: 30,
                    storage: {
                        unit: 'gb',
                        total: 1
                    }
                },
                {
                    id: '1003',
                    name: 'Plan 300',
                    price: [
                        { currency: 'usd', month: '42', year: '464'},
                        { currency: 'myr', month: '126', year: '1391'}
                    ],
                    publish: true,
                    credit: 300,
                    storage: {
                        unit: 'gb',
                        total: 5
                    }
                },
                {
                    id: '1004',
                    name: 'Plan 1000',
                    price: [
                        { currency: 'usd', month: '120', year: '1325'},
                        { currency: 'myr', month: '360', year: '3974'}
                    ],
                    publish: true,
                    credit: 1000,
                    storage: {
                        unit: 'gb',
                        total: 30
                    }
                },
            ]
        })

        return {
            alert, closeAlert, route, router, func, store, 
            showMaintenance, maintenanceMsg, smtpAuth, smtpSecure, smtpSecureOpt,
            arrPlan, planId, planName, planPrice, planStorage, planStorageUnit, planCredit, planPublish, payInYear,
            addPrice, savePlan, updPlan, getPlan, clearPlan, delPlan,
            arrCur, arrUnit
        }
    }

}
</script>

<style>
</style>