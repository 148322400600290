<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Team" icon="fa-solid fa-users" />

    <div class="generalBorderlessBox">
        <div class="row mb-4">
            <div class="col-12">
                 <ul class="nav nav-pills">
                    <li class="nav-item">
                        <router-link :to="{ name: 'Team', query: route.query }">
                            <div class="text-secondary nav-link"><fa icon="users" class="me-2" />Team</div>    
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" href="#"><fa icon="user" class="me-2" />User</a>
                    </li>
                </ul> 
            </div>
        </div>

        <div class="row">
            <!-- Left box -->
            <div class="col-lg-12">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-lg-8 mb-2">
                            <div class="input-group">
                                <span class="input-group-text"><fa icon="search" /></span>
                                <select class="form-select" v-model="filterTeamId">
                                    <option value="">All Team</option>
                                    <option :value="t.teamId" v-for="t in teamOpt" :key="t.teamId">{{ t.teamName }}</option>
                                </select>
                                <input type="text" v-model="filterName" class="form-control" placeholder="Name / Email" >
                                <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="filterName = ''">
                                    <fa icon="xmark" />
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-2 text-end">
                            <span class="isLink me-3" data-bs-toggle="modal" data-bs-target="#mdlPendingMbr" @click="getPendingMbr">
                                <fa icon="user-clock" class="me-2"/>Pending user
                            </span>
                            <span class="isLink" data-bs-toggle="modal" data-bs-target="#mdlInviteMbr">
                                <fa icon="user-plus" class="me-2"/>Invite user
                            </span>
                        </div>
                    </div>

                    <!-- Member list -->
                    <div class="row">
                        <div class="col-12" ref="scrollComponent">
                            <div v-if="isLoading" class="mb-3 text-center">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>

                            <table width="100%" border="0">
                                <tr>
                                    <td width="55px">
                                        <div v-if="checkedUser && checkedUser.length > 0">
                                            <span class="mx-2 text-grey isLink">
                                                <fa icon="trash" size="lg" style="padding-left: 9px" />
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="checkedUser && checkedUser.length > 0">
                                            <span class="text-grey small">{{checkedUser.length}} selected</span>
                                        </div>
                                    </td>
                                    <td class="text-end py-2">
                                        <div v-if="!isLoading" class="small fst-italic text-grey">{{totalShown}} of {{total}}</div>
                                    </td>
                                </tr>
                            </table>

                            <table class="table table-hover" border="0">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrDg" :key="item">
                                        
                                        <td width="50px" class="text-center">
                                            <!-- Large screen -->
                                            <div class="d-none d-md-block">
                                                <div v-if="checkedUser.includes(itemIdx)" style="font-size: 16px; margin-left: -5px; padding-top: 6px; padding-bottom: 6px">
                                                    <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                </div>
                                                <div v-else>
                                                    <span class="hoverHide" :id="'avatar' + itemIdx">
                                                        <ImgAvatar :isUser="item.isUser" :id="item.jAvatar.userId" displayName="" :isVerified="item.isVerified" :isActive="item.userStatus" privilege="" :key="itemIdx" align="center" width="30" height="30" />
                                                        <!-- <ImgAvatar :isUser="item.isUser" :id="item.jAvatar.userId" displayName="" :isVerified="item.isVerified" :isActive="item.userStatus" privilege="" :key="itemIdx" align="center" width="120" height="120" /> -->
                                                    </span>
                                                    <span class="hoverShow" style="z-index:1000; font-size: 15px; margin-top: -30px; margin-left: -10px; position:absolute;">
                                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- End Large screen -->

                                            <!-- Small screen -->
                                            <div class="d-block d-md-none">
                                                <span class="hoverHide" :id="'avatar' + itemIdx">
                                                    <ImgAvatar :isUser="item.isUser" :id="item.jAvatar.userId" displayName="" :isVerified="item.isVerified" :isActive="item.userStatus" privilege="" :key="itemIdx" align="center" width="60" height="30" />
                                                    <!-- <Avatar :key="itemIdx" :isUser="item.isUser" align="center" :avatar="item.jAvatar.imgBase64" displayName="" :isVerified="item.isVerified" width="30" height="30" /> -->
                                                </span>
                                            </div>
                                            <!-- End Small screen -->
                                        </td>
                                    
                                        <td class="align-middle">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="isLink">
                                                        <router-link :to="'/team/member/' + item.userId">
                                                            <span class="fw-bold">{{item.fullName}}</span>
                                                            <span v-if="item.displayName !== ''" class="text-grey"><fa icon="at" class="mx-1"/>{{ item.displayName }}</span>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="col-3 text-grey small">{{item.userId}}</div>
                                                <div class="col-lg-3 small text-end">

                                                    <Popper class="popperDark" arrow hover content="Team">
                                                        <span class="badge isLinkBadgeIcon me-4" data-bs-toggle="modal" data-bs-target="#mdlViewTeam" @click="getMbrTeamList(item)">
                                                            <fa icon="users" size="lg" /> {{ item.jTeam.length }}
                                                        </span>
                                                    </Popper>

                                                    <Popper class="popperDark" arrow hover content="Permission">
                                                        <span class="isLinkIcon" data-bs-toggle="modal" data-bs-target="#mdlViewPermission" @click="getPermission(item.userId, item.fullName)">
                                                            <fa icon="user-shield" size="lg" class="mx-1"/>
                                                        </span>
                                                    </Popper>
                                                    
                                                </div>
                                                <div class="col-lg-2 text-end d-none d-md-block hoverShow">
                                                    <span class="text-grey isLink me-4" data-bs-toggle="modal" data-bs-target="#mdlAddTeam" @click="viewTeamMbr(item)">
                                                        <Popper class="popperDark" arrow hover content="Add team">
                                                            <fa icon="users" />
                                                        </Popper>
                                                        <span class="fw-bold me-2" style="font-size: 14px">+</span>
                                                    </span>

                                                    <span class="text-grey isLink me-2" data-bs-toggle="modal" data-bs-target="#mdlDel" @click="mbrSelected = item">
                                                        <Popper class="popperDark" arrow hover content="Delete">
                                                            <fa icon="trash" size="lg" />
                                                        </Popper>
                                                    </span>
                                                </div>
                                                <!-- Small screen -->
                                                <div class="col-12 text-end d-block d-md-none">
                                                    <span class="text-grey isLink me-4" data-bs-toggle="modal" data-bs-target="#mdlAddTeam" @click="viewTeamMbr(item)">
                                                        <fa icon="users"/><span class="fw-bold me-2" style="font-size: 14px">+</span>
                                                    </span>
                                                    <span class="text-grey isLink me-4" data-bs-toggle="modal" data-bs-target="#mdlDel" @click="mbrSelected = item">
                                                        <fa icon="trash" size="lg" />
                                                    </span>
                                                    <span>
                                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                    </span>
                                                </div>
                                                <!-- End Small screen -->
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="isLoadingMore" class="mt-3 text-center">
                                        <td colSpan="2">
                                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-12 mt-3"><LegendUser /></div>
                    </div>
                    <!-- End of Member list -->

                </div>
            </div>
            <!-- End of Left box -->
            
        </div>
    </div>

    <!-- Modal - Invite Mbr --> 
    <div class="modal fade" id="mdlInviteMbr" tabindex="-1" aria-labelledby="EditProj" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Invite user</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="mb-2"><input type="text" v-model="mbrEmail" class="form-control" placeholder="Email" @focusout="getDisplayName()" @keyup.enter="getDisplayName()" @focus="true" /></div>
                            <div class="mb-2"><input type="text" v-model="mbrDisplayName" class="form-control" placeholder="Display name" @focus="true" /></div>
                            <div class="mb-3">
                                <select class="form-select" v-model="mbrExp">
                                    <option value="7">7 days expiry</option>
                                    <option value="14">14 days expiry</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-1 d-none d-lg-block"></div>
                        <div class="col-12 col-lg-5">
                            <div class="fw-bold mb-2">Select team (Compulsory)</div>
                            <div class="mb-2">
                                <div class="input-group">
                                    <span class="input-group-text"><fa icon="search" /></span>
                                    <input type="text" v-model="filterT" class="form-control" placeholder="Team" >
                                </div>
                            </div>
                            <div class="table-responsive" style="height: 300px">
                                <table class="table table-hover" border="0">
                                    <tbody>
                                        <tr v-for="(item, itemIdx) in teamOpt" :key="item" >
                                            <td width="35px" class="align-middle">
                                                <input v-model="mbrChecked" type="checkbox" class="form-check-input" :value="item.teamId" />
                                            </td>
                                            <td class="align-middle">
                                                {{item.teamName}}
                                            </td>
                                            <td>
                                                <div class="small text-grey text-end">{{ item.total }}<fa icon="user" class="ms-2" /></div>
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button type="button" class="btn btn-secondary" @click="invMember" data-bs-dismiss="modal">
                        <fa icon="envelope" class="me-2" />Invite
                    </button>

                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <!-- Modal - View Team --> 
    <div
        class="modal fade"
        id="mdlViewTeam"
        tabindex="-1"
        aria-labelledby="ViewTeam"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">{{ checkedMbrName }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-12">
                            <table class="table table-hover">
                                <thead>
                                    <th><td>Team</td></th>
                                </thead>
                                <tbody>
                                    <tr v-for="m in arrMbrTeam" :key="m"><td>{{m.teamName}}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Add team --> 
    <div
        class="modal fade"
        id="mdlAddTeam"
        tabindex="-1"
        aria-labelledby="AddTeam"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Add team :: {{ checkedMbrName }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-12">
        
                            <div class="table-responsive" style="height: 300px">
                                <table class="table table-hover" border="0">
                                    <tbody>
                                        <tr v-for="item in teamOpt" :key="item">
                                            <td width="35px" class="align-middle">
                                                <input v-model="arrT" type="checkbox" class="form-check-input" :value="item.teamId" />
                                            </td>
                                            <td class="align-middle">{{item.teamName}}</td>
                                            <td class="text-end">
                                                <Popper class="popperDark" arrow hover content="Team user">
                                                    <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> {{ item.totalMember }}</span>
                                                </Popper>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" @click="updTeamForMbr" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Update
                    </button>
                </div>

            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - View Permission --> 
    <div
        class="modal fade"
        id="mdlViewPermission"
        tabindex="-1"
        aria-labelledby="ViewPermission"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-md text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Permission :: {{ checkedMbrName }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="table-responsive" style="height: 300px">
                                <table class="table">
                                <thead>
                                    <tr class="fw-bold">
                                        <td>
                                            <div class="row">
                                                <div class="col-3">Category</div>
                                                <div class="col-3">Menu</div>
                                                <div class="col-6">Security group</div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>   
                                <tbody v-for="(menus, cat) in arrPermission" :key="cat">
                                    <tr v-for="(menu, menuIdx) in menus" :key="menu">
                                        <td>
                                            <div class="row">
                                                <div class="col-3">
                                                    <span v-if="menuIdx === 0" class="fw-bold">{{ cat }}</span>
                                                </div>
                                                <div class="col-3">{{ menu.menu }}</div>
                                                <div class="col-6">
                                                    <div class="row mt-1" v-for="p in menu.jPermission" :key="p">{{ p.name }}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>    
                                </table>
                                <!-- <table class="table">
                                    <thead>
                                        <tr class="fw-bold">
                                            <td>Category</td>
                                            <td>Menu</td>
                                            <td>Security group</td>
                                        </tr>
                                    </thead>   
                                    <tbody v-for="item in arrPermission" :key="item">
                                        <tr v-for="(menu, menuIdx) in item.menus" :key="menu"> 
                                            <td><div v-if="menuIdx === 0">{{item.category}}</div></td>
                                            <td>{{menu.name}}</td>
                                            <td><div v-for="(p, pIdx) in menu.permissions" :key="p">{{ p.name }}</div></td>
                                        </tr>
                                    </tbody>    
                                </table> -->
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>

            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Pending member list --> 
    <div
        class="modal fade"
        id="mdlPendingMbr"
        tabindex="-1"
        aria-labelledby="PendingMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Pending user</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive" style="height: 300px">
                                
                                <table class="table table-hover" border="0">
                                    <tbody>
                                        <tr v-for="p in arrPendingMbr" :key="p">
                                            <td>
                                                <div>
                                                    <span class="fw-bold">{{p.fullName}}</span>
                                                    <span class="text-grey"><fa icon="at" class="mx-1"/>{{ p.displayName }}</span>
                                                </div>
                                                <div class="small">{{ p.userId }}</div>
                                            </td>
                                            <td class="text-end">
                                                <!-- <span v-if="func.convDateTimeFormat(p.dateExpiry, 'datetime') <= func.convDateTimeFormat(new Date(), 'datetime')" data-bs-toggle="modal" data-bs-target="#mdlExtend" @click.prevent="mbrSelected = p" class="text-grey isLink me-4 hoverShow"> -->
                                                <span data-bs-toggle="modal" data-bs-target="#mdlExtend" @click.prevent="mbrSelected = p" class="text-grey isLink me-4 hoverShow">
                                                    <Popper class="popperDark" arrow hover content="Edit">
                                                        <fa icon="pen" size="lg" />
                                                    </Popper>
                                                </span>

                                                <span data-bs-toggle="modal" data-bs-target="#mdlResend" @click.prevent="mbrSelected = p" class="text-grey isLink me-4 hoverShow">
                                                    <Popper class="popperDark" arrow hover content="Resend">
                                                        <fa icon="paper-plane" size="lg" />
                                                    </Popper>
                                                </span>

                                                <span data-bs-toggle="modal" data-bs-target="#mdlDel" @click.prevent="mbrSelected = p" class="text-grey isLink hoverShow">
                                                    <Popper class="popperDark" arrow hover content="Delete">
                                                        <fa icon="trash" size="lg" />
                                                    </Popper>
                                                </span>
                                            </td>
                                            <td class="text-end">
                                                <div v-if="func.convDateTimeFormat(p.dateExpiry, 'datetime') <= func.convDateTimeFormat(new Date(), 'datetime')" class="sty-italic">expired</div>
                                                <div v-else class="sty-italic">expires in {{ func.getDateTimeDiffSimple(new Date(), func.convDateTimeFormat(p.dateExpiry)) }}</div>
                                                <div><span v-for="t in p.jTeam" :key="t.teamId" class="badge bg-secondary me-1">{{t.teamName}}</span></div>
                                            </td>
                                        </tr>
                                        <!-- <tr v-for="(item, itemIdx) in teamOpt" :key="item" >
                                            <td width="35px" class="align-middle">
                                                <input v-model="arrT" type="checkbox" class="form-check-input" :value="itemIdx" />
                                            </td>
                                            <td class="align-middle">
                                                {{item.teamName}}
                                            </td>
                                            <td>
                                                <div class="small text-grey text-end">{{ item.total }}<fa icon="user" class="ms-2" /></div>
                                            </td>
                                        </tr> -->
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>

            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Resend member invitation --> 
    <div
        class="modal fade"
        id="mdlResend"
        tabindex="-1"
        aria-labelledby="ResendMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Resend invitation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-12">
                            Confirm resend user invitation for <span>{{mbrSelected.fullName}}</span> <span>@{{mbrSelected.displayName}} ({{mbrSelected.userId}})?</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="resendMember" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Resend
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Del pending member --> 
    <div
        class="modal fade"
        id="mdlDel"
        tabindex="-1"
        aria-labelledby="DelPendingMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Delete invitation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-12">
                            Confirm delete user invitation for <span>{{mbrSelected.fullName}}</span> <span>@{{mbrSelected.displayName}} ({{mbrSelected.userId}})?</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="delMember" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Delete
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Extend pending member --> 
    <div
        class="modal fade"
        id="mdlExtend"
        tabindex="-1"
        aria-labelledby="ExtPendingMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Extend user invitation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-12 mb-2">
                            Extend user invitation for <span>{{mbrSelected.fullName}}</span> <span>@{{mbrSelected.displayName}} ({{mbrSelected.userId}})</span>
                        </div>
                        <div class="col-6 mb-2">
                            <select class="form-select" v-model="mbrExtend">
                                <option value="7">7 days expiry</option>
                                <option value="14">14 days expiry</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="extendMember" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import LegendUser from '@/components/LegendUser.vue'

export default {
    name: 'Member',
    components: { Alert, TopNavigation, Popper, /* Avatar, */ ImgAvatar, LegendUser },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const arrItem = ref([])
        const dgMode = ref('all')  // all: load all, part: query db part by part
        const dgCutOffRcd = ref(30)  // decide dgMode if >= dgCutOffRcd part, else all
        const total = ref(0)
        const totalShown = ref(0)   // total records has been displayed
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const arrMbr = ref([])       // for dgMode=all, different fr arrDg
        const arrFilterMbr = ref([]) // for dgMode=all only - handle filter and remain orig data in arrMbr
        const arrDg = ref([])     // record shown in dg, total length shown as totalShown

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])

        const filterName = ref('')
        const filterTeamId = ref('')
        const filterT = ref('')   // searchTeam in Invite mbr modal 

        const email = ref(null)
        const fullLegalName = ref(null)
        const displayName = ref(null)
        const title = ref('Mr')
        const gender = ref('M')
        const userPackage = ref('FREE')
        const gmt = ref('+8:00')
        const language = ref('EN')
        const currency = ref('MYR')

        const checkedMbrId = ref(null)
        const checkedMbrName = ref(null)
        const mbrEmail = ref(null)
        const mbrDisplayName = ref(null)
        const mbrExp = ref(7)
        const mbrExtend = ref(7)
        const mbrChecked = ref([])
        const mbrSelected = ref({}) // selected pending member for edit/delete

        const arrPendingMbr = ref([])
        const arrMbrTeam = ref([])
        const arrT = ref([]) // checkbox in mdlAddTeam

        const gmtOpt = ref(null)
        const titleOpt = ref(null)
        const teamOpt = ref([])
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const arrPermission = ref([])

 
        watch([filterT], () => {
            getTeamOpt()
        })

        watch([filterName, filterTeamId], () => {

            if (dgMode.value === 'all') {
                console.info('- - WATCH all', filterName.value.length)
                if (filterName.value.length === 0 && filterTeamId.value === '') {
                    iniDg() // reset filter

                } else if (filterName.value.length >= 3 || filterTeamId.value !== '') {
                    dgAllFilter()
                    proDgAllData()
                }
            }
            else {
                console.info('- - WATCH part', filterName.value.length)
                if (filterName.value.length === 0 && filterTeamId.value === '') {
                    arrMbr.value = []
                    arrDg.value = []
                    iniDg() // reset filter

                } else if (filterName.value.length >= 3 || filterTeamId.value !== '') {
                    arrDg.value = []
                    getMbrList()
                }
            }
        })

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getDisplayName = () => {
            const p = {
                userId: mbrEmail.value,
            }

            axios.post('/user/member/user', p)
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        mbrDisplayName.value = res.data.data.displayName
                    } 
                    
                })
                .catch((error) => {
                    console.info('- - - getDisplayName: Err', error)
                    func.addLog('teamMember', 'getDisplayName - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                   
                })
        }

        const iniDg = async () => {
            total.value = 0
            totalShown.value = 0
            rcdFr.value = 0

            arrMbr.value = []
            arrDg.value = []

            getMbrListIni()
        }

        // Get initial member list, without search criteria & rcd limit to decide dgMode
        const getMbrListIni = async () => {
            console.info('- - - func: getMbrListIni')
            isLoading.value = true

            let datagridMode = dgMode.value 
            await axios.post('/entity/member/list', {})
                .then((res) => {

                    // console.info('- - - getMbrListIni', JSON.stringify(res))
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        total.value = res.data.description.rowCount
                        // console.info('- - - total', total.value, dgCutOffRcd.value)

                        if (total.value >= dgCutOffRcd.value) {
                            // console.info('- - - part', res.data.data.length)
                            datagridMode = 'part'
                            dgMode.value = 'part'
                            arrMbr.value = res.data.data
                            proDgAllData() // set arrDg to get partial data fr arrMbr(full, no search criteria & rcd limit)
                            arrMbr.value = arrDg.value  // Set back arrMbr same as arrDgTeam for 

                        } 
                        else 
                        {
                            // console.info('- - - all', res.data.data)
                            datagridMode = 'all'
                            dgMode.value = 'all'
                            arrMbr.value = res.data.data
                            proDgAllData()

                        }

                        // console.info('dgMode', dgMode.value)
                    } else {
                        func.addLog('teamMember', 'getMbrListIni', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                    isLoading.value = false
                    
                })
                .catch((error) => {
                    console.info('- - - getMbrListIni: Err', error)
                    isLoading.value = false
                    func.addLog('teamMember', 'getMbrListIni - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                   
                })

            // dgMode.value = datagridMode
        }

        // for dgMode = part, initial access once only
        // for dgModel = all, access when initial access, scroll more, filter
        const proDgAllData = async () => {

            let len = rcdFr.value + rcdDisplay.value

            if (filterName.value.length >= 3 || filterTeamId.value !== '') {
                // console.info('- - - proDgAllData - filter', rcdFr.value, rcdDisplay.value, 'arrFilterTeam', arrFilterTeam.value.length, JSON.stringify(arrFilterTeam.value))
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilterMbr.value[i] !== undefined) {
                        arrDg.value.push(arrFilterMbr.value[i])
                    }
                }
                totalShown.value = arrDg.value.length

            }
            else
            {
                // console.info('- - - proDgAllData', rcdFr.value, rcdDisplay.value, 'len', len)
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrMbr.value[i] !== undefined) {
                        arrDg.value.push(arrMbr.value[i])
                    }
                }
                totalShown.value = arrDg.value.length

            }
        }

        const dgAllFilter = () => {
            console.info('- - func: dgAllFilter')
            let arrTmp = []
            for(var i = 0; i < arrMbr.value.length; i++) {
                if (filterName.value !== '' && filterTeamId.value === '') {
                    // filter name only
                    if ( arrMbr.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true || arrMbr.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase())) {
                        arrTmp.push(arrMbr.value[i])
                    }

                } else if (filterName.value === '' && filterTeamId.value !== '') {
                    // filter team only
                    for(var j = 0; j < arrMbr.value[i].jTeam.length; j++) {
                        console.info('- - item', arrMbr.value[i].jTeam[j].teamId)
                        if (arrMbr.value[i].jTeam[j].teamId === filterTeamId.value) {
                            arrTmp.push(arrMbr.value[i])
                        }
                    }

                } else if(filterName.value !== '' && filterTeamId.value !== '') {
                    // console.info('filter 3')
                    // filter name and team
                    if ( arrMbr.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true || arrMbr.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase())) {
                        for(var j = 0; j < arrMbr.value[i].jTeam.length; j++) {
                            if (arrMbr.value[i].jTeam[j].teamId === filterTeamId.value) {
                                arrTmp.push(arrMbr.value[i])
                            }
                        }
                    }
                }
            }

            // console.info('res ', arrTmp.length, JSON.stringify(arrTmp))
            total.value = arrTmp.length
            totalShown.value = 0
            rcdFr.value = 0
            arrFilterMbr.value = arrTmp
            arrDg.value = []
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                // console.info('- - handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    
                    let tmp = rcdFr.value + rcdDisplay.value
                    if (tmp < total.value) {
                        isLoadingMore.value = true
                        rcdFr.value = rcdFr.value + rcdDisplay.value

                        if (dgMode.value === 'all') {
                            proDgAllData()
                            isLoadingMore.value = false

                        }
                        else
                        {
                            getMbrList()
                            isLoadingMore.value = false
                            
                        }
                    }
                }
            }
        }

        // For dgMode = part - to get more member list
        const getMbrList = async () => {
            console.info('- - - getMbrList PART')

            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value 
            }

            if (filterTeamId.value !== '') {
                p.teamId = filterTeamId.value
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.strSearch = filterName.value
            }

            axios.post('/entity/member/list', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        total.value = res.data.description.rowCount
    
                        if (res.data.data.length > 0) {
                            // console.info('- - - getMbrList RES', res.data.data.length, JSON.stringify(res.data.data), 'REQ', JSON.stringify(p))
                            console.info('- - - getMbrList REQ', JSON.stringify(p))
                            let tmp = []
                            tmp = res.data.data

                            for (var i = 0; i < tmp.length; i++) {
                                arrDg.value.push(tmp[i])
                            }

                            totalShown.value = arrDg.value.length
                            arrDg.value = arrDg.value
                        }

                    } else {
                        func.addLog('teamMember', 'getMbrList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                        
                })
                .catch((error) => {
                    func.addLog('teamMember', 'getMbrList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getTeamOpt = () => {
            
            const p = {}
            if (filterT.value !== '') {
                p.strSearch = filterT.value
            }

            axios.post('/entity/team/list', p)
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        teamOpt.value = res.data.data

                    } else {
                        func.addLog('teamMember', 'getTeamOpt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMember', 'getTeamOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const viewTeamMbr = (item) => {
            checkedMbrId.value = item.userId
            checkedMbrName.value = item.fullName
            arrT.value = []

            for (var i = 0; i < item.jTeam.length; i++) {
                arrT.value.push(item.jTeam[i].teamId)
            }
        }

        const updTeamForMbr = () => {
            const p = {
                jTeamId: arrT.value
            }

             axios.put('/entity/member/' + checkedMbrId.value, p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        iniDg()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Member is updated."
                        })

                    } else {
                        func.addLog('teamMember', 'updTeamForMbr', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMember', 'updTeamForMbr - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getGmt = () => {
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        gmtOpt.value = res.data.data

                    } else {
                        func.addLog('teamMember', 'getGmt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMember', 'getGmt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getPendingMbr = () => {
            const p = { status: 'PENDING' }
            axios.post( '/entity/invitee/list', p)
            .then((res) => {
                if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    // console.info('getPendingMbr', JSON.stringify(res.data.data))
                    arrPendingMbr.value = res.data.data

                } else {
                    func.addLog('teamMember', 'getPendingMbr', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                func.addLog('teamMember', 'getPendingMbr - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const getMbrTeamList = (data) => {
            checkedMbrId.value = data.userId
            checkedMbrName.value = data.fullName
            if (data.displayName) {
                checkedMbrName.value += ' @ ' + data.displayName 

            }
            arrMbrTeam.value = data.jTeam 
        }

        const resendMember = () => {
            const p = {
                frontendUrl: "/e/"
            }
            axios.put( '/entity/invitee/' + mbrSelected.value.userId + '/resend', p)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Invitation is resent."
                            })

                        } else {
                            func.addLog('teamMember', 'resendMember', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('teamMember', 'resendMember  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })

        }

        const delMember = () => {
            axios.delete( '/entity/invitee/' + mbrSelected.value.userId)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            
                            getPendingMbr()

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Invitation is deleted."
                            })

                        } else {
                            func.addLog('teamMember', 'delMember', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('teamMember', 'delMember  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
        }

        const extendMember = () => {
            const p = { exDay: mbrExtend.value }

            axios.put( '/entity/invitee/' + mbrSelected.value.userId, p)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            
                            getPendingMbr()

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Invitation is extended."
                            })

                        } else {
                            func.addLog('teamMember', 'extendMember', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('teamMember', 'extendMember  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
        }

        const invMember = () => {
            if (mbrChecked.value.length === 0) {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Please select at least one team.'
                })

            } else {
                const p = {
                    username: mbrEmail.value,
                    displayName: mbrDisplayName.value,
                    jTeamId: mbrChecked.value,
                    exDay: mbrExp.value,
                    frontendUrl: "/e/"
                }

                // console.info('inv', JSON.stringify(p))
                axios.post( '/entity/member/invite', p)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            mbrEmail.value = null
                            mbrDisplayName.value = null
                            mbrExp.value = 7
                            mbrChecked.value = []

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Invitation is sent."
                            })

                        } else {
                            func.addLog('teamMember', 'invMember', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                if (res.data.message === 'duplicate_user') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'You already invited this user.'
                                    })
                                    
                                } else if (res.data.message === 'member_quota_exceeded') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Your current plan has exceeded its user limit. To accommodate additional users, please upgrade to a higher-tier plan.'
                                    })

                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('teamMember', 'invMember  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }
        }

        const getPermission = (userId, userName) => {
            checkedMbrId.value = userId
            checkedMbrName.value = userName

            axios.get('/entity/member/' + userId)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        arrPermission.value = res.data.data.jMenu

                    } else {
                        func.addLog('teamMember', 'getPermission', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMember', 'getPermission - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
           
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            
            iniDg()
            getGmt()
            getTeamOpt()

            axios.get('/reference/title')
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        titleOpt.value = res.data.data

                    } else {
                        func.addLog('teamMember', 'onMounted', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMember', 'onMounted - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
            
        })

        return {
             alert, closeAlert, route, router, func, store, scrollComponent, 
            total, totalShown, clsAvatar, clsCheckbox, checkedUser, 
            arrMbr, arrFilterMbr, arrDg, rcdFr, rcdDisplay, isLoading, isLoadingMore, dgMode, dgCutOffRcd,
            email, fullLegalName, displayName, title, gender, userPackage, 
            gmt, language, currency, gmtOpt, titleOpt, genderOpt, filterName, 
            filterTeamId, teamOpt, mbrEmail, mbrDisplayName, mbrExp, invMember, filterT, 
            checkedMbrId, checkedMbrName, arrPermission, mbrChecked, 
            getPendingMbr, getMbrTeamList, arrT, mbrSelected, mbrExtend, 
            arrPendingMbr, arrMbrTeam, getPermission, viewTeamMbr, updTeamForMbr,
            extendMember, delMember, resendMember, getDisplayName
        }
    }

}
</script>

<style>
.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

.table-hover > tbody > tr .hoverHide {
    visibility: visible;
}

.table-hover > tbody > tr:hover .hoverHide {
    visibility: hidden;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>