<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Signature Verification" icon="fa-solid fa-signature" />

    <div class="generalBorderlessBox">
        <div class="row">
            <!-- Left column -->

            <div v-if="showPuzzle === true" class="col-12 d-grid text-center border">
                <Vcode :show="showPuzzle" @success="puzzleSuccess" @close="puzzleClose" :failText="msgFailed" :successText="msgSuccess" :sliderText="msgRemark" className="verifyPuzzle" />
            </div>

            <div v-if="showPuzzle === false" class="col-12 col-lg-8 d-grid">

                <div v-if="status === undefined" class="alert alert-danger headerFailed">
                    <table border="0">
                        <tr>
                            <td rowSpan="2" width="70px"><fa icon="circle-xmark" style="font-size: 50px" /></td>
                        </tr>
                        <tr >
                            <td>
                                <div class="fw-bold">Scam Alert</div>
                                <div>Signature not found.</div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div v-if="status === 'transaction_not_match'" class="alert alert-danger headerFailed">
                    <table border="0">
                        <tr>
                            <td rowSpan="2" width="70px"><fa icon="circle-xmark" style="font-size: 50px" /></td>
                        </tr>
                        <tr >
                            <td>
                                <div class="fw-bold">Scam Alert</div>
                                <div>Signature and Document do not matched.</div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div v-if="status === 'transaction_not_found'" class="alert alert-danger headerFailed">
                    <table border="0">
                        <tr>
                            <td rowSpan="2" width="70px"><fa icon="circle-xmark" style="font-size: 50px" /></td>
                        </tr>
                        <tr >
                            <td>
                                <div class="fw-bold">Scam Alert</div>
                                <div>Signature does not match transaction.</div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div v-if="status === 'success'">
                    <div class="alert alert-success mb-0 headerSuccess">
                        <table border="0">
                            <tr>
                                <td rowSpan="2" width="70px"><fa icon="circle-check" style="font-size: 50px" /></td>
                            </tr>
                            <tr >
                                <td>
                                    <div class="fw-bold">Signature Verified</div>
                                    <div>Supplementary information is provided for verification purpose</div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="bodySuccess p-3">
                        <div class="row">
                            <div v-if="sign.jSignee.jEntity.entityName !== ''" class="col-lg-3 pb-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlEntityInfo" @click="getEntityInfo(sign)">
                                <ImgLogo :id="sign.jSignee.jEntity.jLogo.entityId" :isVerified="sign.jSignee.jEntity.isVerified" :width="sign.jSignee.jEntity.jLogo.width" :height="sign.jSignee.jEntity.jLogo.height" />
                                <!-- <Logo :url="sign.jSignee.jEntity.jLogo.imgBase64" :status="sign.jSignee.jEntity.status" :width="sign.jSignee.jEntity.jLogo.width" :height="sign.jSignee.jEntity.jLogo.height" /> -->
                                <div class="mt-1 fw-bold">{{sign.jSignee.jEntity.entityName}}</div>
                            </div>

                            <div class="pb-2" :class="sign.jSignee.jEntity.entityName === '' ? 'col-lg-9' : 'col-lg-6'">
                                <table width="100%">
                                    <tr>
                                        <td class="align-middle" width="50px">
                                            <ImgAvatar :isUser="sign.jSignee.isUser" :id="sign.jSignee.jAvatar.userId" displayName="" :isVerified="sign.jSignee.isVerified" :isActive="sign.jSignee.userStatus" :privilege="sign.jSignee.privilege" :key="sign.jSignee.userId + '0'" align="left" width="35" height="35" />
                                            <!-- <Avatar :isUser="sign.jSignee.isUser" :avatar="sign.jSignee.jAvatar.imgBase64" :isVerified="sign.jSignee.isVerified" :isActive="sign.jSignee.userStatus" :privilege="sign.jSignee.privilege" :key="sign.jSignee.signeeId + '0'" width="35" height="35" align="left" /> -->
                                        </td>
                                        <td>
                                            <div v-if="sign.jSignee.fullName !== '' && sign.jSignee.displayName !== ''">
                                                <span>{{sign.jSignee.fullName}}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{sign.jSignee.displayName}}</span>
                                            </div>
                                            <div v-else>
                                                <span v-if="sign.jSignee.fullName">{{sign.jSignee.fullName}}</span>
                                                <span v-if="sign.jSignee.displayName">
                                                    {{sign.jSignee.displayName}}
                                                </span>
                                            </div>
                                            <div class="lblTiny text-grey pb-2">{{sign.jSignee.userId}}</div>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Joined <span class="small">{{func.convDateTimeFormat(sign.jSignee.dateJoin, 'text')}}</span> <span class="small fst-italic">({{ func.getDateTimeDiff(func.convDateTimeFormat(sign.jSignee.dateJoin), new Date()) }})</span></td>
                                    </tr>
                                </table>
                                
                            </div>

                            <div class="col-lg-3 text-end">
                                <div class="fw-bold">{{sign.jSignee.signingStatus}}</div>
                                <div class="small">{{func.convDateTimeFormat(sign.jSignee.dateSigned, 'text')}}</div>
                                <div class="small fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(sign.jSignee.dateSigned), new Date()) }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="border shadow-sm generalContentBox">
                        <div class="row pb-2">
                            <div class="col-lg-2 mb-3"><fa icon="file-pdf" style="font-size: 100px; opacity: 0.5" /></div>
                                <div class="col-lg-10">
                                    <div class="row mb-2">
                                        <div class="col-xl-7 mb-2">
                                            <div class="pt-2">
                                                <span class="p-2 text-white" style="background-color: var(--text-grey-link)">Document ID</span>
                                                <span class="p-2 bg-grey text-white">{{sign.documentId}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-xl text-end pt-2 align-middle">
                                            <div class="fw-bold">
                                                <span v-if="sign.jSignee.isRejected === true">
                                                    <span class="text-danger">REJECTED</span>
                                                </span>
                                                <span v-else-if="sign.jSignee.signingStatus === 'PENDINGSIGN' || sign.jSignee.signingStatus === 'PENDING'" class="text-success">
                                                    SIGNING IN PROGRESS
                                                </span>
                                                <span v-else class="text-pink">{{ sign.status }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-lg-7"></div>
                                        <div class="col-lg-5 text-end small">{{func.convDateTimeFormat(sign.dateModify, 'text')}}</div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-7">
                                            <span v-if="sign.jFile">{{ func.convSize(sign.jFile.size) }}<fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />{{ sign.jFile.totalPage }} pages</span>
                                        </div>
                                        <div class="col-lg-5 text-end"></div>
                                    </div>
                                </div> 

                                <div class="col-12 fw-bold">{{sign.jFile.name}}</div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-1"><hr class="my-3" /></div>

                                <div class="col-lg-3 pb-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlEntityInfo" @click="getEntityInfo(sign)">
                                    <ImgLogo :id="sign.jEntity.jLogo.entityId" :isVerified="sign.jEntity.isVerified" :width="sign.jEntity.jLogo.width" :height="sign.jEntity.jLogo.height" />
                                    <!-- <Logo :url="sign.jEntity.jLogo.imgBase64" :status="sign.jEntity.status" :width="sign.jEntity.jLogo.width" :height="sign.jEntity.jLogo.height" /> -->
                                    <div class="mt-1 fw-bold">{{sign.jEntity.entityName}}</div>
                                </div>

                                <div class="col-lg-6 pb-2">
                                    <table width="100%">
                                        <tr>
                                            <td class="align-middle" width="50px">
                                                <ImgAvatar :isUser="sign.jCreator.isUser" :id="sign.jCreator.jAvatar.userId" displayName="" :isVerified="sign.jCreator.isVerified" :isActive="sign.jCreator.userStatus" :privilege="sign.jCreator.privilege" :key="sign.jCreator.userId + '1'" align="left" width="35" height="35" />
                                                <!-- <Avatar :isUser="sign.jCreator.isUser" :avatar="sign.jCreator.jAvatar.imgBase64" :isVerified="sign.jCreator.isVerified" :isActive="sign.jCreator.userStatus" :privilege="sign.jCreator.privilege" :key="sign.jCreator.userId + '1'" width="35" height="35" align="left" /> -->
                                            </td>
                                            <td>
                                                <div v-if="sign.jCreator.fullName !== '' && sign.jCreator.displayName !== ''">
                                                    <span>{{sign.jCreator.fullName}}</span>
                                                    <span class="text-grey"><fa icon="at" class="mx-1"/>{{sign.jCreator.displayName}}</span>
                                                </div>
                                                <div v-else>
                                                    <span v-if="sign.jCreator.fullName">{{sign.jCreator.fullName}}</span>
                                                    <span v-if="sign.jCreator.displayName">
                                                        {{sign.jCreator.displayName}}
                                                    </span>
                                                </div>
                                                <div class="lblTiny text-grey pb-2">{{sign.jCreator.userId}}</div>
                                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Joined <span class="small">{{func.convDateTimeFormat(sign.jCreator.dateJoin, 'text')}}</span> <span class="small fst-italic">({{ func.getDateTimeDiff(func.convDateTimeFormat(sign.jCreator.dateJoin), new Date()) }})</span></td>
                                        </tr>
                                    </table>
                                    
                                </div>

                                <div class="col-lg-3 text-end">
                                    <div class="fw-bold">UPLOADED BY</div>
                                    <div class="small">{{func.convDateTimeFormat(sign.dateCreate, 'text')}}</div>
                                    <div class="small fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(sign.dateCreate), new Date()) }}</div>
                                </div>

                        </div>
                    </div>

                </div><!-- success End -->

            </div><!-- Left column End -->

            <!-- Right column -->
            <div v-if="showPuzzle === false && status === 'success'" class="col-12 col-lg-4" :class="showDownload === true && showFingerprint === true ? 'd-grid' : ''">

                <!-- Download -->
                <div v-if="showDownload === true" class="generalContentBox border shadow-sm">
                    <table width="100%" border="0">
                        <tr>
                            <td width="60px"><fa icon="download" style="font-size: 45px" /></td>
                            <td>
                                <div class="dropdown d-grid" style="width: 100%">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-block" data-bs-toggle="dropdown">
                                        Download
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" style="font-size: 14px">
                                        <li><a class="dropdown-item" href="#" @click="downloadDoc"><fa icon="file-signature" class="me-1" />Original Signed Version</a></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li v-if="sign.jFile.dlAuditRptUrl !== '' && sign.jFile.dlAuditRptUrl !== null && sign.jFile.dlAuditRptUrl !== undefined">
                                            <a class="dropdown-item" href="#" @click="downloadAuditLog"><fa icon="file-alt" class="me-2" />Audit Report</a>
                                        </li>
                                    </ul>
                                </div> 
                            </td>
                        </tr>
                    </table>
                </div>

                <!-- Fingerprint -->
                <div v-if="showFingerprint === true" class="generalContentBox border shadow-sm">
                    <table width="100%" border="0">
                        <tr>
                            <td width="60px" class="align-top"><fa icon="fingerprint" style="font-size: 45px" /></td>
                            <td>
                                <div class="fw-bold mb-1">Fingerprint</div>
                                <div class="text-break mb-1">{{sign.jFile.fingerprint}}</div>
                                <div class="text-end small">{{ func.convDateTimeFormat(sign.jFile.dateFingerprint, 'text')}}</div>
                            </td>
                        </tr>
                    </table>

                    <div><hr /></div>

                    <div class="pb-3">Fingerprint reassures your document is the original signed version without tampering.</div>
                    <div v-if="showFPUpload === true" class="mb-2">
                        <DropZone @drop.prevent="droppedFingerprint" @change="selectedFingerprint" :isFingerprint="true" />
                    </div>

                    <div v-if="showFPUpload === false && showFPResult === true" class="mb-3">
                        <div class="pb-2">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" :style="{width: + fPProgress + '%'}">{{fPProgress}} % </div>
                            </div>
                        </div>

                        <div class="p-3" style="background-color: var(--auth-box-bg); border-radius: 10px;">
                            <div class="pb-2 fw-bold">Result</div>
                            <div v-if="fPResult === 'Fingerprint matches with the Original Signed Version.'" class="pb-2"><fa icon="circle-check" size="lg" class="me-1 text-success" />{{fPResult}}</div>
                            <div v-if="fPResult === 'Wrong fingerprint. Either you uploaded wrong file or your file is tampered.' || fPResult === 'No fingerprint.'" class="pb-2"><fa icon="circle-xmark" size="lg" class="me-1 text-danger" />{{fPResult}}</div>
                            <div class="pb-2 text-end">
                                <span @click="showFPUpload = true; showFPResult= false; fPProgress = 0" class="text-primary isLink">Reset</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Entity info --> 
    <div class="modal fade" id="mdlEntityInfo">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Organization information</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="entity">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td width="40%"><ImgLogo :id="entity.jEntity.jLogo.entityId" :isVerified="entity.jEntity.isVerified" :width="entity.jEntity.jLogo.width" :height="entity.jEntity.jLogo.height" /></td>
                                    <td>
                                        <div class="mb-3">
                                            <div class="lblTiny">Name of organization</div>
                                            <div>{{ entity.jSignee.jEntity.entityName }}</div>
                                        </div>
                                        <div class="mb-3">
                                            <div class="lblTiny">Status</div>
                                            <div class="text-capitalize">{{ entity.jSignee.jEntity.status.toLowerCase() }}</div>
                                        </div>
                                        <div class="mb-3">
                                            <div class="lblTiny">KYC Verified</div>
                                            <div>
                                                <span v-if="entity.jSignee.jEntity.isVerified === true || entity.jSignee.jEntity.isVerified === 'true'">Verified</span>
                                                <span v-else>-</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: Entity info End -->
     
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'
import DocStatus from '@/components/DocStatus.vue'
// import Avatar from '@/components/Avatar.vue'
// import Logo from '@/components/Logo.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ImgLogo from '@/components/ImgLogo.vue'
import DropZone from '@/components/DropZone.vue'
import Vcode from 'vue3-puzzle-vcode'

// https://github.com/javaLuo/vue-puzzle-vcode/blob/HEAD/README3.md
// npm install vue3-puzzle-vcode --save

export default {
    name: 'VerifySign',
    components: { 
        TopNavigation, Alert, Popper, DocStatus, /* Avatar, Logo, */ImgAvatar, ImgLogo, DropZone, Vcode
    },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const signatureId = ref(route.params.signatureId)
        const sign = ref(null)
        const status = ref(null)
        const showDownload = ref(false)
        const showFingerprint = ref(false)

        const showFPUpload = ref(true)      // true/false show/hide fingerprint drga and drop
        const showFPResult = ref(false)     // true/false show/hide fingerprint compare result
        const fPResult = ref(null)          // fingerprint compare result
        const fPProgress = ref(0)           // fingerprint upload progress 

        const objLSSignature = ref({})
        const showPuzzle = ref(true)
        const msgFailed = ref('Failed, plaese try again')
        const msgSuccess = ref('Success')
        const msgRemark = ref('Drag to complete puzzle')

        const entity = ref(null)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getEntityInfo = (data) => {
            entity.value = data
            // console.info('get entity info', JSON.stringify(data))

        }

        const puzzleSuccess = () => {
            showPuzzle.value = false

            objLSSignature.value = { isHuman: true, date: new Date() }
            localStorage.setItem('human', func.encodeJsonToStrSimple(objLSSignature.value))

            getSignature()

        }   

        const puzzleClose = () => {
            showPuzzle.value = true
        }

        const downloadDoc = async () => {

            const existingPdfBytes = await fetch(sign.value.jFile.dlUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = sign.value.jFile.name

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)
            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }

        const downloadAuditLog = async () => {

            const existingPdfBytes = await fetch(sign.value.jFile.dlAuditRptUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = sign.value.jFile.name.substring(0, sign.value.jFile.name.length-4) + '_AuditLog.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)
            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
           
        }

        const droppedFingerprint = async (e) => {
            // Drag file should be one file ya, for verification
            const files = ref([])
            files.value = e.dataTransfer.files

            for (var i = 0; i < files.value.length; i++) {

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: 'include',
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        fPProgress.value = 0
                        fPProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }

                let formData = new FormData()
                formData.append('file', files.value[i])

                await axios.post( '/signon/verify/document/' + sign.value.documentId, formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            fPResult.value = "Fingerprint matches with the Original Signed Version."
                            showFPResult.value = true
                            showFPUpload.value = false

                        } else {

                            func.addLog('verifySign', 'droppedFingerprint', res)

                            if (res.data.message === 'invalid_format') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Invalid file format.'
                                })

                            } else if (res.data.message === 'fingerprint_unmatched') {
                                fPResult.value = "Wrong fingerprint. Either you uploaded wrong file or your file is tampered."
                                showFPResult.value = true
                                showFPUpload.value = false

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                        
                    })
                    .catch((error) => {
                        func.addLog('verifySign', 'droppedFingerprint - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }
        }

        const selectedFingerprint = async (e) => {
            const files = ref({})
            files.value = document.querySelector('.dropzoneFile').files

            for (var i = 0; i < files.value.length; i++) 
            {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: 'include',
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        fPProgress.value = 0
                        fPProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }

                let formData = new FormData()
                formData.append('file', files.value[i])

                await axios.post( '/signon/verify/document/' + sign.value.documentId, formData, config)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {

                            fPResult.value = "Fingerprint matches with the Original Signed Version."
                            showFPResult.value = true
                            showFPUpload.value = false

                        } else {
                            func.addLog('verifySign', 'selectedFingerprint', res)

                            if (res.data.message === 'invalid_format') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Invalid file format.'
                                })

                            } else if (res.data.message === 'fingerprint_unmatched') {
                                fPResult.value = "Wrong fingerprint. Either you uploaded wrong file or your file is tampered."
                                showFPResult.value = true
                                showFPUpload.value = false

                            } else if (res.data.message === 'no_fingerprint') {
                                fPResult.value = "No fingerprint."
                                showFPResult.value = true
                                showFPUpload.value = false

                            }  else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('verifySign', 'selectedFingerprint - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                        
                    })
            } // for 
        }

        const getSignature = () => {
            axios.get('/signon/verify/transaction/' + signatureId.value)
                .then((res) => {
                    console.info('getSign', JSON.stringify(res.data.data))
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        sign.value = res.data.data
                        status.value = "success"

                        if (res.data.data.jFile.dlUrl && res.data.data.jFile.dlUrl !== '' && res.data.data.jFile.dlUrl !== null && res.data.data.jFile.dlUrl !== undefined) {
                            showDownload.value = true
                        }

                        if (res.data.data.jFile.fingerprint && res.data.data.jFile.fingerprint !== '' && res.data.data.jFile.fingerprint !== null && res.data.data.jFile.fingerprint !== undefined) {
                            showFingerprint.value = true
                        }

                    } else {
                        status.value = res.data.message
                        func.addLog('verifySign', 'getSignature', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    status.value = error.status
                    func.addLog('verifySign', 'getSignature - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        onMounted(async () => {

            if (func.isEmptyObject(localStorage.getItem('human')) === false) {
                objLSSignature.value = func.decodeStrToJsonSimple(localStorage.getItem('human'))

                showPuzzle.value = false
                getSignature()
            } else {
                showPuzzle.value = true
            }

        })

        return {
            axios, route, router, alert, func, closeAlert, 
            signatureId, sign, status, showDownload, showFingerprint, showFPUpload, showFPResult, fPResult, fPProgress, 
            downloadDoc, downloadAuditLog, droppedFingerprint, selectedFingerprint,
            showPuzzle, objLSSignature, msgFailed, msgSuccess, msgRemark, puzzleSuccess, puzzleClose, getEntityInfo, entity
        }
    }
}
</script>

<style>

body {
    color: #6E777E;
    font-size: 14px;
    overflow: scroll;
    padding-bottom: 10px;
}

.headerSuccess {
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px; 
    border-bottom-right-radius: 0px;
}

.bodySuccess {
    border:1px solid #badbcc; 
    border-top: none; 
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    margin-bottom: 25px;
}

.headerFailed {
    border-radius: 10px;
}

</style>