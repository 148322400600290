<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Reset Password" :hideLogin="true" />

    <div class="mainBox mainBox30 border shadow-sm">
        <div class="row">
            <div class="col-12 pb-2">
                <input type="password" v-model="password1" placeholder="New password" class="form-control" @input="valPassword" />
            </div>

            <div v-if="!pwd_length || !pwd_number || !pwd_lowercase || !pwd_uppercase || !pwd_special" class="col-12 pb-3">
                <div>
                    <i v-if="!pwd_length" class="fa-solid fa-circle-exclamation fa-lg text-danger pe-2"></i>
                    <i v-else class="fa-solid fa-circle-check fa-lg text-success pe-2"></i>At least 11 character
                </div>
                <div>
                    <i v-if="!pwd_number" class="fa-solid fa-circle-exclamation fa-lg text-danger pe-2"></i>
                    <i v-else class="fa-solid fa-circle-check fa-lg text-success pe-2"></i>Minimum 1 numeric digit
                </div>
                <div>
                    <i v-if="!pwd_lowercase" class="fa-solid fa-circle-exclamation fa-lg text-danger pe-2"></i>
                    <i v-else class="fa-solid fa-circle-check fa-lg text-success pe-2"></i>Minimum 1 lowercase letter
                </div>
                <div>
                    <i v-if="!pwd_uppercase" class="fa-solid fa-circle-exclamation fa-lg text-danger pe-2"></i>
                    <i v-else class="fa-solid fa-circle-check fa-lg text-success pe-2"></i>Minimum 1 capital letter
                </div>
                <div>
                    <i v-if="!pwd_special" class="fa-solid fa-circle-exclamation fa-lg text-danger pe-2"></i>
                    <i v-else class="fa-solid fa-circle-check fa-lg text-success pe-2"></i>Minimum 1 special character
                </div>

            </div>

            <div class="col-12 pb-1" v-if="(password2 && password2.length >= 11) || passwordFormat">
                <input type="password" v-model="password2" placeholder="Reconfirm password" class="form-control" @input="valPasswordMatch"/>
                <div v-if="password2 && password2.length > 0 && !passwordMatch" class="pt-2">
                    <i class="fa-solid fa-circle-exclamation fa-lg text-danger pe-2"></i>Password not match
                </div>
                <div v-if="passwordFormat && passwordMatch" class="pt-2">
                    <i class="fa-solid fa-circle-check fa-lg text-success pe-2"></i>Password matched
                </div>
            </div>

            <div class="col-12 pb-3" >
                
            </div>

            <div class="col-12 text-end">
                <button class="btn btn-secondary" :class="passwordFormat && passwordMatch ? '' : 'disabled'" @click="resetPassword">Submit</button>
            </div>

        </div>
    </div>    
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import auth from '@/functions/auth'
import funcs from '@/functions/function'

export default {
    name: 'ResetPassword',
    components: { TopNavigation, Alert },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const authFunc = auth
        
        const password1 = ref(null)
        const password2 = ref(null)

        const passwordFormat = ref(false)
        const passwordMatch = ref(false)

        const pwd_length = ref(false)
        const pwd_number = ref(false)
        const pwd_lowercase = ref(false)
        const pwd_uppercase = ref(false)
        const pwd_special = ref(false)

        const proceedRedirect = ref(false)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const valPassword = () => {
            pwd_length.value = password1.value.length >= 11
            pwd_number.value = /\d/.test(password1.value)
            pwd_lowercase.value = /[a-z]/.test(password1.value)
            pwd_uppercase.value = /[A-Z]/.test(password1.value)
            pwd_special.value = /[!@#\$%\^\&*\)\(+=._-]/.test(password1.value)

            if (pwd_length.value && pwd_number.value && pwd_lowercase.value && pwd_uppercase.value && pwd_special.value) {
                passwordFormat.value = true
                valPasswordMatch()
            } else {
                passwordFormat.value = false
            }
        }

        const valPasswordMatch = () => {
            if (password1.value === password2.value) {
                passwordMatch.value = true
            } else {
                passwordMatch.value = false
            }
        }

        const resetPassword = async () => {

            if (password1.value !== password2.value) {

                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Password and reconfirm password do not match. '
                })

            } else {

                await resetPasswordSub()
                setTimeout(() => redirectLogin(), 3000)
            }
        }

        const redirectLogin = () => {
            console.info('--- redirectLogin()')

            if (proceedRedirect.value === true) {
                console.info('proceedRedirect TRUE')
                // router.push('/auth/login')
                router.push({path: '/auth/login', query: { u: route.query.u}})
                .then(() => {
                    // refresh the page so that can capture sessionId in store
                    router.go() 
                })
            } 
            else 
            {
                console.info('proceedRedirect FALSE')
            }
        }

        const resetPasswordSub = () => {
            const p = {
                password: password1.value
            }
            
            let url = '/auth/resetPassword/' + route.params.activationCode

            return axios.put( url, p )
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        proceedRedirect.value = true

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Password is reset."
                        })

                    } else {
                        func.addLog('resetPassword', 'resetPasswordSub', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'invalid_action' || res.data.message === 'hacking_attempt' || res.data.message === 'refId_not_found') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Your reset password link is not working. Please report the error (error code) to our support if you continue to face the same problem. (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'refId_expired') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Your reset password link is expired. (' + res.data.status + ')'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }

                        }

                    }
                })
                .catch((error) => {
                    proceedRedirect.value = false
                    func.addLog('resetPassword', 'resetPasswordSub - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
                
        }

        onMounted(() => {
            authFunc.clearStorage()

            if (route.params.activationCode === null || route.params.activationCode === undefined) {
                // Redirect to page not found if no activationCode was detected
                router.push('/pageNotFound')

            }

            password1.value = null

        })

        return { 
            alert, closeAlert, password1, password2, resetPassword, valPassword, 
            pwd_length, pwd_number, pwd_lowercase, pwd_uppercase, pwd_special, 
            passwordFormat, passwordMatch, valPasswordMatch, 
        }
    }
}
</script>

<style>

</style>