<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Folder Overview" icon="fa-solid fa-chart-line" />

    <div class="mainBox mainBox58 shadow-sm border">

        <div v-if="permToAccessProject === true">
            <div v-if="project === null || project === undefined" class="row">
                <div class="col-12">
                    <fa icon="spinner" size="lg" class="me-2" />Loading...
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 col-md-8 mb-2">
                    <ProjTitle v-if="project" :projId="project.folderId" :isFavourite="project.isFavourite" :mode="project.mode" :permission="project.permission" :projTitle="project.folderName" :totalSignee="project.jSignee.length" :totalDoc="project.totalDocument" :status="project.status" :shred="project.shred" :privacy="project.privacy" />
                    <span class="ms-1">
                        <div v-if="!showDropdown" class="badge tag" @click.prevent="testStar" v-for="(t, tIdx) in project.jTag" :key="t">
                            <fa icon="hashtag" class="pe-1"/>{{ t }}
                        <!-- Small screen -->
                        <span class="d-lg-none">
                            <a href="#" class="ms-1" @click="delTag(t, tIdx)"><fa icon="xmark" /></a>
                        </span>
                        <!-- Big screen -->
                        <span class="tagClose" @click="delTag(t, tIdx)"><fa icon="xmark" /></span>
                        </div>
                        
                        <div class="badge btnTag isLink text-white" @click="showDropdown = !showDropdown">
                            <span v-if="showDropdown">
                                <Popper class="popperDark" arrow hover content="Close add tag">
                                <fa icon="minus" />
                                </Popper>
                            </span>
                            <span v-else>
                                <Popper class="popperDark" arrow hover content="Add tag">
                                <fa icon="plus" />
                                </Popper>
                            </span>
                        </div>

                        <!-- Tag -->
                        <div v-if="showDropdown" class="custom-dropdown">
                            <Tag :tags="project.jTag" @getTags="getTags" @delTag="delTag" :key="project.jTag.length" />
                            <input type="text" v-model="projTag" ref="projTagRef" class="inputTag my-2" autocomplete="off" 
                            @keyup.enter="enterProjTag()"  autofocus />
                        </div>
                        <!-- Tag End -->

                    </span>
                </div>

                <div class="col-12 col-lg-4">
                    <span class="float-end">
                        <!-- Allow seal proj ONLY if proj mode is STRICT and all docs ALLSIGNED / VIEWONLY -->
                        <span v-if="showSeal === true" class="text-primary isLink me-3">
                            <fa icon="stamp" class="me-2"/>Seal
                        </span>
                        <span class="text-primary isLink me-3" @click="router.push({ path: '/p/' + route.params.projectId, query: { fr: 'overview'} })">
                            <fa icon="file-lines" class="me-2" />Detail
                        </span>
                        <span v-if="project.status === 'WIP'" class="text-primary isLink" @click="router.push('/uploadDoc/' + route.params.projectId)">
                            <fa icon="pencil" class="me-2"/>Modify
                        </span>
                        <span v-if="project.status === 'CIRCULATING' || project.status === 'SEALED'" class="text-primary isLink" data-bs-toggle="modal" data-bs-target="#mdlModify">
                            <fa icon="pencil" class="me-2"/>Modify
                        </span>
                </span>
                </div>

                <div class="col-12 mb-2">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>
                                    <div class="row">
                                        <div class="col-12 col-lg-6">Document</div>
                                        <div class="col-12 col-lg-6">
                                            <div class="row">
                                                <div class="col-12 col-lg-9"></div>
                                                <div class="col-12 col-lg-3">Signee</div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="doc in document" :key="doc">
                                <td>
                                    <div class="row">

                                        <div class="col-12 col-lg-9 isLink" @click.prevent="router.push('/pdf/view/' + route.params.projectId + '/' + doc.documentId)">
                                            <DocStatus :status="doc.status" :shred="doc.shred" class="" />
                                            <span class="ms-1 me-1" :class="doc.status === 'DELETE' ? 'text-grey' : ''">{{ doc.name.replace('.pdf', '') }}</span>

                                            <span v-if="doc && doc.isEncrypted === true && doc.totalOldSignature > 0">
                                                <Popper class="popperDark" arrow hover :content="'This document is encrypted and has ' + doc.totalOldSignature + ' existing signature' + (doc.totalOldSignature > 1 ? 's' : '') + ' found before processing, before upload'">
                                                    <fa icon="triangle-exclamation" size="lg" class="text-warning" />
                                                </Popper>
                                            </span>
                                            <span v-else-if="doc && doc.isEncrypted === true">
                                                <Popper class="popperDark" arrow hover :content="'This document is encrypted'">
                                                    <fa icon="triangle-exclamation" size="lg" class="text-warning" />
                                                </Popper>
                                            </span>
                                            <span v-else-if="doc && doc.totalOldSignature > 0">
                                                <Popper class="popperDark" arrow hover :content="'This document has ' + doc.totalOldSignature + ' existing signature' + (doc.totalOldSignature > 1 ? 's' : '') + ' found before processing, before upload'">
                                                    <fa icon="triangle-exclamation" size="lg" class="text-warning" />
                                                </Popper>
                                            </span>

                                            <span v-if="doc.remark || doc.note" class="ms-2" size="lg">
                                                <Popper class="popperDark" arrow hover content="Note">
                                                    <fa icon="clipboard" class="text-brown"/>
                                                </Popper>
                                            </span>
                                        </div>
                                            
                                        <div class="col-12 col-lg-3">
                                            <div class="row">
                                                <div class="col-12 col-lg-7 pt-1">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped" :style="{width: doc.progress + '%'}">{{ doc.progress }}% </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-5">
                                                <table width="100%">
                                                    <tr><td>
                                                            <span v-if="doc.jSignee.length > 0">
                                                            <Popper class="popperDark" arrow hover>
                                                                <span class="badge bg-grey isTooltipsLabel"><fa icon="user" /> {{doc.jSignee.length}}</span>
                                                                <template #content >
                                                                    <div v-for="s in doc.jSignee" :key="s" class="text-start">
                                                                        <span>
                                                                            <span v-if="s.seq > 0" class="badge bg-grey me-1">{{s.seq}}</span>
                                                                            {{ s.signeeName }} <span v-if="s.signeeId !== ''">({{s.signeeId}})</span> - {{ s.status === '' ? '?' : s.status}}
                                                                        </span>
                                                                    </div>
                                                                </template>
                                                            </Popper>
                                                            </span>
                                                            <span v-else class="badge bg-grey"><fa icon="user" /> 0</span>
                                                        </td>
                                                        <td class="text-end">
                                                        <div class="dropdown dropstart">
                                                            <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                                                <fa icon="ellipsis-h" class="text-secondary"/>
                                                            </a>
                                                            <ul class="dropdown-menu dropdown-menu-secondary">
                                                                <li v-if="doc.remark || doc.note">
                                                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="docId = doc.documentId; docDetail = doc">Update note <span class="float-end"><fa icon="clipboard" /></span></a>
                                                                </li>
                                                                <li v-else>
                                                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="docId = doc.documentId; docDetail = doc">Add note <span class="float-end"><fa icon="clipboard" /></span></a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAuditLog" @click="docId = doc.documentId; docDetail = doc">View audit log <span class="float-end"><fa icon="file-lines" /></span></a>
                                                                </li>
                                                                <li v-if="doc.status === 'DRAFT'">
                                                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="docId = doc.documentId; docName = doc.name">Delete <span class="float-end"><fa icon="trash" /></span></a>
                                                                </li>
                                                                <!-- <li v-if="doc.status === 'ALLSIGNED'">
                                                                    <a class="dropdown-item" href="#" @click="storeInVault(doc.documentId)">Store in vault <span class="float-end"><fa icon="database" /></span></a>
                                                                </li> -->
                                                            </ul>
                                                        </div>
                                                        </td></tr>
                                                </table>
                                                </div>
                                            </div>
                                            
                                        </div> 
                                    </div>
                                    <!-- row -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12 small text-grey">Modified 
                <Popper v-if="project.dateModify === '0'" class="popperDark" arrow hover :content="func.convDateTimeFormat(project.dateCreate, 'datetime')">
                    <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(project.dateCreate), new Date()) }}</span>
                </Popper>
                <Popper v-else class="popperDark" arrow hover :content="func.convDateTimeFormat(project.dateModify, 'datetime')">
                    <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(project.dateModify), new Date()) }}</span>
                </Popper>
                </div>
            </div>
        </div>
        <div v-if="permToAccessProject === false">
            <div class="row">
                <div class="col-12">Folder not found</div>
            </div>
        </div>
        
        <div><Legend folderStatus="true" docStatus="true" /></div>
    </div>

    <!-- <Legend /> -->
    
    <!-- Modal: Add note --> 
    <div
      class="modal fade"
      id="mdlAddNote"
      tabindex="-1"
      aria-labelledby="AddNote"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 class="modal-title" id="exampleModalLabel"> Add Note</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <div class="row">
                      <div class="col-12 mb-3">
                          <label class="form-label text-secondary"><fa icon="clipboard" size="lg" class="text-brown px-1 me-2" />Public editor note to signee(s)</label>
                          <textarea v-model="publicNote" rows="5" class="form-control"></textarea>
                      </div>
                      <div class="col-12">
                          <label class="form-label text-secondary"><fa icon="note-sticky" size="lg" class="text-brown me-2" />Private note to editor(s) only</label>
                          <textarea v-model="selfNote" rows="2" class="form-control"></textarea>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Cancel
                </button>
                <button class="btn btn-secondary" data-bs-dismiss="modal" @click="addNote">
                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                </button>
              </div>
          </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
    <!-- Modal End--> 

    <!-- Modal: Del doc --> 
    <div
      class="modal fade"
      id="mdlDelDoc"
      tabindex="-1"
      aria-labelledby="DelDoc"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 class="modal-title" id="exampleModalLabel"> Delete document</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <div>Confirm to delete document: {{docName}}?</div>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-secondary" data-bs-dismiss="modal" @click="delDoc">Delete</button>
              </div>
          </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->

    <!-- Modal: AuditLog --> 
    <div
      class="modal fade"
      id="mdlAuditLog"
      tabindex="-1"
      aria-labelledby="AuditLog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 class="modal-title" id="exampleModalLabel"> Audit Log</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <div v-if="docDetail" class="border border-light p-1">
                    <div style="max-height: 300px; overflow-y: auto; overflow-x: none;" >
                        <table class="table table-hover">
                            <tbody>
                                <tr v-for="al in docDetail.jAuditLog" :key="al">
                                    <td>{{ func.convDateTimeFormat(al.date, 'datetime') }}</td>
                                    <td>{{al.description}}</td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                  </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Close
                </button>
              </div>
          </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
    <!-- Modal End--> 

    <!-- Modal: Modify CIRCULATING/SEALED project --> 
    <div
      class="modal fade"
      id="mdlModify"
      tabindex="-1"
      aria-labelledby="ModifyProj"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 class="modal-title" id="exampleModalLabel"> Modify Circulating Folder</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                    <div class="mb-2">Modify folder now will block all signees from accessing the documents in the folder. You will need to send Sign Resend again.</div>
                    <div>Confirm to proceed?</div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="proceedModify" data-bs-dismiss="modal">
                    <fa icon="check" class="me-2" />Yes
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Cancel
                </button>
              </div>
          </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
    <!-- Modal End--> 
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import Tag from '@/components/ProjTag.vue'
import ProjStatus from '@/components/ProjStatus.vue'
import DocStatus from '@/components/DocStatus.vue'
import ProjPrivacy from '@/components/ProjPrivacy.vue'
import Legend from '@/components/Legend.vue'
import ProjTitle from '@/components/ProjTitle.vue'

export default {
  name: 'ProjectOverview',
  components: {
    TopNavigation, Alert, Popper, Tag, ProjStatus, DocStatus, Legend, ProjPrivacy, ProjTitle
  },
  setup () {
    const alert = ref([])

    const axios = inject('axios')
    const route = useRoute()
    const router = useRouter()
    const func = funcs

    const project = ref(null)
    const document = ref(null)
    const docId = ref(null)   // DocumentId to add note / delete
    const docName = ref(null)   // Document name to delete
    const docDetail = ref(null)   // Document detail to add note

    const showDropdown = ref(false)
    const projTag = ref(null)
    const projTagRef = ref(null)
    const projTagOptRef = ref(null)

    const publicNote = ref(null)
    const selfNote = ref(null)
    const permToAccessProject = ref(true)   // Permission to access project
    const showSeal = ref(false)  // show sela button only if project.mode=STRICT && all docs are ALLSIGNED / VIEWONLY 
    const showSealDoc = ref(true) // If all doc status are ALLSIGNED / VIEWONLY 

    const closeAlert = (index) => {
        alert.value.splice(index, 1)
    }

    watch([docDetail], () => {
        publicNote.value = docDetail.value.remark
        selfNote.value = docDetail.value.note
    })

    const proceedModify = async () => {
        axios.put( '/signon/' + route.params.projectId + '/wip', {})
            .then((res) => {
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    router.push('/uploadDoc/' + route.params.projectId)

                } else {
                    func.addLog('folderOverview', 'proceedModify', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                func.addLog('folderOverview', 'proceedModify - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            })
    }

    const addNote = () => {
        const  p = {
            remark: publicNote.value,
            note: selfNote.value
        }

        axios.put( '/signon/' + route.params.projectId + '/' + docId.value, p)
            .then((res) => {
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    
                    docDetail.value = {}
                    getDocument()

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Note is added."
                    })

                } else {
                    func.addLog('folderOverview', 'addNote', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                func.addLog('folderOverview', 'addNote - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            })
    }

    const enterProjTag = () => {
        if (projTag.value.length > 0) {
            if (project.value.jTag.includes(projTag.value)) {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Tag already exists.'
                })

            } else {
                updTag('add', projTag.value)

            }
        }
    }

    const setProjTagFocus = () => {
      // No need drop down menu since not allow duplicate tag?
        if (project.value.jTag.length > 0) {
            // projTagRef.value.focus() // Not working
            if (projTagOptRef.value.classList.value.includes('show')) {
                projTagOptRef.value.classList.remove('show')

            } else {
                projTagOptRef.value.classList.add('show')

            }
        }
    }

    const selectProjTag = (val) => {
        if (project.value.jTag.includes(val)) {
            alert.value.push({
                class: "danger",
                title: "ERROR",
                message: 'The tag already exists.'
            })

        } else {
            updTag('add', val)

        }
    }

    const delTag = (val, idx) => {
        updTag('del', val, idx)

    }

    const updTag = async (opt, val, idx=0) => {
        const tmpTag = project.value.jTag

        if (opt === 'add') {
            tmpTag.push(val)
            
        } else {
            const tmpTag = project.value.jTag
            tmpTag.splice(idx, 1)

        }

        const  p = {
            jTag: tmpTag
        }

        axios.put( '/signon/' + route.params.projectId, p)
            .then((res) => {

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    projTag.value = null
                    getProject()

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: opt === 'add' ? "Tag is added." : "Tag is removed."
                    })

                } else {
                    func.addLog('folderOverview', 'updTag', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                func.addLog('folderOverview', 'updTag - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            })
    }

    const getProject = () => {
        axios.post('/signon/' + route.params.projectId, {})
            .then((res) => {
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    permToAccessProject.value = true
                    project.value = res.data.data

                } else {
                    func.addLog('folderOverview', 'updTag', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                permToAccessProject.value = false
                func.addLog('folderOverview', 'updTag - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            
            })
    }

    const getDocument = () => {
        axios.post('/signon/' + route.params.projectId, {})
        .then((res) => {

            if (res.data.status === 1001) {
                router.push({ path: '/auth/login', query: {e: 'ns'} })

            } else if (res.data.status === 1) {
                document.value = res.data.data.jDocument
                
                // Add project.documents.progress to display in progress bar
                res.data.data.jDocument.forEach((item, idx) => {
                    if (item.status === '' || item.status === 'DRAFT' || item.status === 'INVITED' || item.status === 'SIGNED') {
                        showSealDoc.value = false
                    }

                    if (item.jSignee.length === 0) {
                        document.value[idx].progress = 0
                        document.value[idx].totalSignee = 0

                    } else {
                        let totalSignee = item.jSignee.length
                        let totalSigned = 0

                        item.jSignee.forEach((sItem, sIdx) => {
                            if (sItem.status === 'SIGNED' || sItem.status === 'APPROVED' || sItem.status === 'REJECTED') {
                                totalSigned++
                            }
                        })

                        if (totalSigned === 0) {
                            document.value[idx].progress = 0

                        } else {
                            document.value[idx].progress = ((totalSigned/totalSignee) * 100).toFixed(0) * 1

                        }
                        document.value.totalSignee = totalSignee

                    }
                })

                if (showSealDoc.value === true && project.value.mode === 'STRICT') {
                    showSeal.value = true

                } else {
                    showSeal.value = false

                }

            } else {
                func.addLog('folderOverview', 'getDocument', res)

                if (res && res.data !== null && res.data !== undefined) {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.getError(res.data)
                    })

                }
            }
        })
        .catch((error) => {
            func.addLog('folderOverview', 'getDocument - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
           
        })
    }

    const delDoc = () => {
        axios.delete( '/signon/' + route.params.projectId + '/' + docId.value)
            .then((res) => {

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    getProject()
                    getDocument()
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Document: " + docName.value + " is deleted."
                    })

                } else {
                    func.addLog('folderOverview', 'delDoc', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }

                }
            })
            .catch((error) => {
                func.addLog('folderOverview', 'delDoc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            })
    }

    const storeInVault = (id) => {
        axios.put( '/signon/' + route.params.projectId + '/' + id + '/store', {})
            .then((res) => {

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    getProject()
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Document is stored."
                    })

                } else {
                    func.addLog('folderOverview', 'storeInVault', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                func.addLog('folderOverview', 'storeInVault - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
    }

    onMounted(() => {
        func.clearPrevLocalStorage()

        // First time login
        if (route.query.fl === true || route.query.fl === 'true') {
            alert.value.push({
                class: "success",
                title: "SUCCESS",
                message: "Welcome to SignOn."
            })
        }

        // Not first time login
        if (route.query.fl === false || route.query.fl === 'false') {
            if (route.query.l) { 
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                })
            }
        }
        getProject()
        getDocument()
        
    })

    return { 
      route, router, alert, closeAlert, func, project, document, docId, docName, 
      showDropdown, projTag, projTagRef, projTagOptRef, 
      enterProjTag, setProjTagFocus, selectProjTag, delTag, delDoc, storeInVault, 
      docDetail, addNote, publicNote, selfNote, proceedModify, permToAccessProject, showSeal
    }
  }

}
</script>

<style>
.custom-dropdown {
    text-align: left;
    display: block;
    width: 100%;
    padding: 4px 5px;
    font-size: 1rem;
    font-weight: 400;
    height: auto;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;
    margin-bottom: 5px;
}

.inputTag {
    width: 80px;
    height: 30px;
    font-size: 12px;
    border: 1px dashed #ced4da;
}

.inputTag:focus { 
    outline: none; 
    border: 1px dashed grey;
}
</style>