<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="2" disabledSignee="false" :disabledSignatory="disabledSignatory" :disabledReview="disabledReview"/>

    <div v-if="objLSProj.status !== 'WIP'" class="generalBox border shadow-sm">
        <div class="row">
            <div class="col-5 col-lg-4 mb-2 text-center">
                <fa icon="triangle-exclamation" style="font-size: 120px"/>
            </div>
            <div class="col-7 col-lg-8 mb-2">
                <div class="fw-bold mb-3" style="font-size: 20px">Hmmm... We're sorry, but you don't have permission to view this page.</div>
                <div>Please email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a> for more information.</div>
                <div>Or you may search <span class="text-primary isLink" @click="$router.push('/project')">folder</span> from the rack again.</div>
            </div>
            <div class="col-12"><hr class="my-3"/></div>
            <div class="col-12 text-center">
                <button class="btn btn-secondary" @click="$router.push({ path: '/project' })">
                    <fa icon="folder" size="lg" class="me-2" />Folder
                </button>
            </div>
        </div>
    </div>

    <div v-if="objLSProj.status === 'WIP'" class="generalBox border shadow-sm">
        <div v-if="project && project.folderName" class="row">
            <div class="col-12">
                <ProjTitle :projId="project.folderId" :isFavourite="project.isFavourite" :mode="project.mode" :permission="project.permission" :projTitle="project.folderName" :totalSignee="totalSignee" :totalDoc="project.totalDocument" :status="project.status" :shred="project.shred" :privacy="project.privacy" :isEdit="true" @refreshProj="getProject" :key="randKey"/>
            </div>

            <div class="col-12"><hr class="mb-2 mt-3 py-0" /></div>

            <div class="col-md-9 mb-3">
                <span>You may include all the signees (participants) in the folder</span>
            </div>
            <div class="col-md-3 text-end pe-3 mb-3">
                <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#mdlAddSignee" @click="resetSignee" class="text-primary isLink"> -->
                <a href="#" data-bs-toggle="modal" data-bs-target="#mdlAddSignee" class="text-primary isLink" @click="filterName = ''">
                    <fa icon="plus" class="me-2"/>Add Signee
                </a>
            </div>

            <div class="col-12">
                <table class="table table-hover">
                    <tbody>
                        <tr v-for="(s, sIdx) in signees" :key="s.signeeId">
                            <td class="align-middle">
                                <div class="row">
                                    <!-- <div class="text-danger small">signeeName: {{s.signeeName}}, full:{{s.fullName}}, displayname: {{s.displayName}}</div> -->
                                    <!-- <div class="text-danger small">{{s}}</div> -->
                                    <div class="col-12 col-lg-8 align-text-top">
                                        <table>
                                            <tr>
                                                <td class="pe-2">
                                                    <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" :key="s.signeeId + s.privilege + sIdx" width="35" height="35" />
                                                    <!-- <Avatar :isUser="s.isUser" :avatar="s.jAvatar.imgBase64" displayName="" :isVerified="s.isVerified" :isActive="s.status" :key="s.signeeId + s.privilege + sIdx" :privilege="s.privilege" class="fw-bold" width="35" height="35" /> -->
                                                </td>
                                                <td class="ms-1 align-middle">
                                                    <div>{{s.signeeName}}</div>
                                                    <div class="lblTiny text-grey">{{s.signeeId}}</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <table width="100%" border="0">
                                            <tr>
                                                <td><span class="badge bg-grey">{{s.role}}</span></td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart">
                                                        <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                                            <fa icon="ellipsis-h" class="text-secondary"/>
                                                        </a>
                                                        <ul class="dropdown-menu dropdown-menu-secondary">
                                                            <li><a class="dropdown-item" href="#" @click="modifySignee(s, index)" data-bs-toggle="modal" data-bs-target="#mdlAddSignee">Modify</a></li>
                                                            <!-- <li><a class="dropdown-item" href="#">Add private attachments</a></li> -->
                                                            <li><a class="dropdown-item" href="#" @click="delSignee(s, s.signeeId)">Remove</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12 mb-3">
                All signees will receive an email invitation to sign/view the documents, and the signing process is not reversible. <br>The final signed copy will be distributed to all the signees and archived in our secure file vault.
            </div>

            <div class="col-12"></div>

        </div>
        <div v-else class="row">
            <div class="col-12">
                <fa icon="spinner" size="lg" class="me-2" />Loading...
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr class="my-4"/>
            </div>
            <div class="col-6 mb-1">
                <button class="btn btn-outline-secondary" @click="$router.push({ path: '/uploadDoc/' + objLSProj.id })">
                    <fa icon="circle-arrow-left" size="lg" class="me-2" />Back
                </button> 
            </div>
            <div class="col-6 text-end mb-1">
                <button class="btn btn-secondary" @click="$router.push({ path: '/signatory' })" :class="disabledSignatory === true ? 'disabled' : ''">
                    <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                </button> 
            </div>
        </div>
    </div>

    <!-- Modal: Add signee --> 
    <div
        ref="mdlAddSignee"
        class="modal fade"
        id="mdlAddSignee"
        tabindex="-1"
        aria-labelledby="AddSignee"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" :class="signeeId === null ? 'modal-lg' : ''">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="showMdl === false && signeeId === null" class="modal-title" id="exampleModalLabel"> Add Signee</h5>
                    <h5 v-else-if="showMdl === false" class="modal-title" id="exampleModalLabel"> Modify Signee</h5>
                    <h5 v-if="showMdl === true" class="modal-title" id="exampleModalLabel"> Signee Added</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="showMdl === true" class="row">
                        <div class="col-12 mb-2">
                            Signee is added successfully.
                        </div>

                        <div v-if="showMdl === true && allowAddUser === true" class="col-12">
                            Would you like to invite the user '{{ mbrDisplayName }}' to join your organization?
                        </div>
                        <div v-if="showMdl === true && allowAddUser === true" class="col-12 mt-3">
                            <div class="isLinkPrimary mb-2" @click="showInviteRemark = !showInviteRemark"><fa icon="circle-question" size="lg" class="me-2" />What is the difference between invite user automatically and manually?</div>
                            <div v-if="showInviteRemark" class="px-1 pb-2">
                                <div>
                                    <span class="fw-bold">Invite User Automatically</span>
                                    <span class="fw-light"> will add the signee as an user and assign them to the system's default team.</span>
                                </div>
                                <div>
                                    <span class="fw-bold">Invite User Manually</span>
                                    <span class="fw-light"> allows you to add the signee as a nuser and assign them to a custom team.</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div v-if="showMdl === false" class="row">
                        <div :class="signeeId === null ? 'col-12 col-lg-7' : 'col-12'">
                            <div class="row">
                                <input type="hidden" class="form-control" v-model="signeeId" >
                                <div class="col-12 mb-3">
                                    <input type="email" class="form-control" v-model="signeeEmail" @focusout="getSigneeDisplayName()" @keyup.enter="getSigneeDisplayName()" placeholder="Email *" :disabled="signeeId !== null">
                                    <div v-if="AddSigneeErr === true" class="text-danger small">Compulsory</div>
                                </div>

                                <div class="col-12 mb-3">
                                    <input type="text" class="form-control" v-model="signeeName" placeholder="Name *">
                                </div>

                                <div class="col-12 mb-3">
                                    <label class="form-label text-secondary">Role (E.g. BUYERWITNESS, SELLERWITNESS)</label>
                                    <div v-if="roleList.length === 0" class="custom-dropdown" @click="setRoleFocus">
                                        <Tag :tags="roleList" @getTags="getTags" />
                                        <input type="text" v-model="role" ref="roleRef" class="role" autocomplete="off" maxlength="10" 
                                            @input="roleChange" @keyup.enter="enterRole()" @focusout="enterRole()" autofocus style="text-transform: uppercase" />
                                        <ul class="dropdown-menu dropdown-menu-secondary" ref="roleOptRef">
                                            <li class="dropdown-item" v-for="(role, idx) in roleOption" :key="role">
                                                <span @click="selectRole(role)" class="pe-3 text-decoration-none" style="cursor: pointer">{{role}}</span>
                                                <fa icon="trash" class="float-end isLink text-white pt-1" @click="delRoleOption(role, idx)"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="custom-dropdown" >
                                        <Tag :tags="roleList" @getTags="getTags" @showRoleInput="role = null"/>
                                    </div>
                                </div>

                                <div v-if="signeePrivilege !== 'OWNER'" class="col-12 mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" v-model="signeeIsEditor" value="true" >
                                        <label class="form-check-label" for="mySwitch">Editor</label>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <!-- Signee list -->
                        <div v-if="signeeId === null" class="col-12 col-lg-5">
                            <div class="row">

                                <div class="col-12 mb-2">
                                    <div class="generalContentBox border shadow-sm greyBox">
                                        <div class="mb-2 p-0">
                                            <table width="100%">
                                                <tr>
                                                    <td><span class="filter-item" :class="filSig === 'recent' ? 'active' : ''" @click="setFilter('recent')">Recent</span></td>
                                                    <td><span class="filter-item" :class="filSig === 'freq' ? 'active' : ''"  @click="setFilter('freq')">Frequent</span></td>
                                                    <!-- enerson request hide tmp <td><span class="filter-item" :class="filSig === 'contact' ? 'active' : ''"  @click="setFilter('contact')">Contact</span></td> -->
                                                </tr>
                                                <tr>
                                                    <td colspan="3">
                                                        <input type="text" class="form-control mt-2" v-model="filterName" @dblclick="filterName= ''" placeholder="Name / Email / Tag" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div>
                                            <table class="table table-sm table-hover">
                                                <tbody>
                                                    <tr v-for="r in arrList" :key="r">
                                                        <td class="isLink" @click="selectSignee(r)">
                                                            <div>{{ r.signeeName === undefined ? '-' : r.signeeName }}</div>
                                                            <div class="lblTiny">{{r.userId}}</div>
                                                            <div>
                                                                <span class="badge tag isLabel" v-for="t in r.jTag" :key="t">
                                                                    <fa icon="hashtag" class="pe-1"/>{{ t }}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button v-if="showMdl === false" type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <button v-if="showMdl === false && signeeId === null" type="button" @click="addSignee" class="btn btn-secondary">
                        <i class="fa-solid fa-floppy-disk me-2"></i>Save
                    </button>
                    <button v-else-if="showMdl === false" type="button" @click="updSignee" class="btn btn-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-floppy-disk me-2"></i>Update
                    </button>

                    <div v-if="showMdl === true && allowAddUser === true">
                        <button type="button" class="btn btn-secondary me-2" @click="sysAutoInviteUser" data-bs-dismiss="modal">
                            <i class="fa-solid fa-user fa-lg me-2"></i>Invite User Automatically
                        </button>
                        <button type="button" class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#mdlInviteMember" data-bs-dismiss="modal">
                            <i class="fa-solid fa-user fa-lg me-2"></i>Invite User Manually
                        </button>
                    </div>
                    
                    <button v-if="showMdl === true" type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Close
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End --> 

    <!-- Modal - Invite Mbr --> 
    <div class="modal fade" id="mdlInviteMember" tabindex="-1" aria-labelledby="InviteMember" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Invite user</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        

                        <div class="col-12 col-lg-6">
                            <div v-if="mbrEmail === null || mbrEmail === ''" class="text-danger mb-3">Please enter email.</div>
                            <div v-else-if="mbrDisplayName === null || mbrDisplayName === ''" class="text-danger mb-3">Please enter display name.</div>

                            <div class="mb-2"><input type="text" v-model="mbrEmail" class="form-control" placeholder="Email" @focus="true" /></div>
                            <div class="mb-2"><input type="text" v-model="mbrDisplayName" class="form-control" placeholder="Display name" @focus="true" /></div>
                            <div class="mb-3">
                                <select class="form-select" v-model="mbrExp">
                                    <option value="7">7 days expiry</option>
                                    <option value="14">14 days expiry</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-1 d-none d-lg-block"></div>
                        <div class="col-12 col-lg-5">
                            <div v-if="mbrChecked.length === 0" class="text-danger mb-2">Please select at least one team.</div>
                            <div v-if="toggleAddTeam === true">
                                <div class="fw-bold mb-2">Add team</div>
                                <div class="mb-3">
                                    <div class="input-group">
                                        <input type="text" v-model="newTeam" class="form-control" placeholder="Team name" >
                                        <!-- <span class="text-danger">{{ newTeam.length }}</span> -->
                                        <button type="button" class="btn btn-secondary" :disabled="newTeam.length < 2" @click="addTeam" data-bs-toggle="modal" data-bs-target="#mdlTeamPermission" data-bs-dismiss="modal">
                                            <i class="fa-solid fa-users-medical me-2"></i>Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mb-2">
                                <span class="fw-bold">Select team (Compulsory)</span>
                                <span v-if="userPerm && userPerm.includes('team.write') && userPerm.includes('permission.write')" class="small isLinkPrimary float-end" @click="toggleAddTeam = !toggleAddTeam">Add Team</span>
                            </div>
                            <div class="mb-2">
                                <div class="input-group">
                                    <span class="input-group-text"><fa icon="search" /></span>
                                    <input type="text" v-model="filterT" class="form-control" placeholder="Team" >
                                </div>
                            </div>
                            <div class="table-responsive" style="height: 300px">
                                <table class="table table-hover" border="0">
                                    <tbody>
                                        <tr v-for="(item, itemIdx) in teamOpt" :key="item" >
                                            <td width="35px" class="align-middle">
                                                <input v-model="mbrChecked" type="checkbox" class="form-check-input" :value="item.teamId" />
                                            </td>
                                            <td class="align-middle">
                                                {{item.teamName}} <!-- {{ item.teamId }} -->
                                            </td>
                                            <td v-if="userPerm && userPerm.includes('permission.read')" width="30px">
                                                <Popper class="popperDark" arrow hover content="Team permission">
                                                    <span class="text-grey isLink" @click="viewPermission(item.teamId, item.teamName)" data-bs-toggle="modal" data-bs-target="#mdlTeamPermission">
                                                        <i class="fa-solid fa-user-shield"></i>
                                                    </span>
                                                </Popper>
                                            </td>
                                            <td class="ps-0 pe-1" width="55px">
                                                <span class="badge bg-grey float-end me-1"><i class="fa-solid fa-user"></i> {{ item.totalMember }}</span>
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>

                    <button type="button" class="btn btn-secondary" @click="invMember" :disabled="mbrEmail === '' || mbrDisplayName === '' || mbrChecked.length === 0" data-bs-dismiss="modal">
                        <i class="fa-solid fa-envelope me-2"></i>Send Invitation
                    </button>

                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <div class="modal fade" id="mdlTeamPermission" tabindex="-1" aria-labelledby="TeamPermission" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Team Permission :: {{ newTeam }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12" style="height: 400px; overflow-y: scroll;">
                            <table class="table">
                                <thead>
                                    <tr class="fw-bold">
                                        <td>
                                            <div class="row">
                                                <div class="col-5 col-lg-2">Category</div>
                                                <div class="col-7 col-lg-2">Menu</div>
                                                <div class="col-lg-8">Security group</div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>   
                                <tbody v-for="(menus, cat) in arrPermOpt" :key="cat">
                                    <tr v-for="(menu, menuIdx) in menus" :key="menu">
                                        <td>
                                            <div class="row">
                                                <div class="col-5 col-lg-2 d-none d-lg-block">
                                                    <span v-if="menuIdx === 0" class="fw-bold">{{ cat }}</span>
                                                </div>
                                                <div class="col-5 col-lg-2 d-lg-none fw-bold">{{ cat }}</div>
                                                <div class="col-7 col-lg-2">{{ menu.menu }}</div>
                                                <div class="col-lg-8">

                                                    <div class="row mt-1" v-for="p in menu.jPermission" :key="p">
                                                        <div class="col-10 mb-2">{{ p.name }}</div>
                                                        <div class="col-2">
                                                            <input type="checkbox" v-model="arrPermChecked" :value="p.id" class="form-check-input" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button type="button" class="btn btn-outline-secondary" @click="newTeam = ''; newTeamId = ''; toggleAddTeam = false" data-bs-toggle="modal" data-bs-target="#mdlInviteMember" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>

                    <button :class="userPerm && userPerm.includes('permission.write') ? '' : 'disabled'" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlInviteMember" @click="updPermission" data-bs-dismiss="modal">
                        <i class="fa-solid fa-floppy-disks me-2"></i>Save
                    </button>

                </div>
            </div>
        </div>
    </div>

    <!-- ISSUE: This method to show Modal caused modal backdrop not show 
    <div
        ref="mdlOptAddStaff"
        class="modal fade"
        id="mdlOptAddStaff"
        tabindex="-1"
        aria-labelledby="OptAddStaff"
        aria-hidden="true" 
        :class="showMdl === true ? 'show' : ''" 
        :style="'display: ' + (showMdl === true ? 'block' : 'none')" 
        data-bs-backdrop="true" data-bs-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered" :class="signeeId === null ? 'modal-lg' : ''">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Signee Added</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close" @click="showMdl = false"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">Signee is added successfully.</div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button type="button" class="btn btn-secondary" @click="$router.push({ path: '/team/member' })" data-bs-dismiss="modal">
                        <fa icon="user" size="lg" class="me-2" />Add member
                    </button>

                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="showMdl = false">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div> -->

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import { ref, inject, onMounted, watch } from 'vue'
import { useStore } from '@/stores/store'
import { useRouter, useRoute } from 'vue-router'
import draggable from 'vuedraggable'
import Alert from '@/components/Alert.vue'
import Tag from '@/components/Tag.vue'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import funcs from '@/functions/function'
import ProjTitle from '@/components/ProjTitle.vue'

const freeEmailDomains = require('free-email-domains')

export default {
    name: 'AddSignee',
    components: {
        TopNavigation, draggable, Alert, Tag, /* Avatar, */ ImgAvatar, ProjTitle
    },
    setup() {
        const alert = ref([])
        const axios = inject('axios')
        const func = funcs
        const route = useRoute()
        const router = useRouter()
        const { getUserId, getDisplayName } = useStore()
        const store = useStore()
        
        const isSignOn = ref(false)
        const isOwner = ref(false)
        const isEditor = ref(false)
        const isFinance = ref(false)
        const showInviteRemark = ref(false)
        const allowAddUser = ref(false)

        const inSequence = ref(false)
        const dragging = ref(false)
        const signees = ref([])
        const signeeEmail = ref(null)
        const signeeName = ref(null)
        const signeeRole = ref(null)
        const signeePrivilege = ref(null)
        const signeeIsEditor = ref(false)
        const signeeId = ref(null)
        const signeeIndex = ref(null) // edit signee
        const totalSignee = ref(0)
        const totalDoc = ref(0)
        const project = ref([])
        const classAll = ref('btn btn-sm btn-secondary active')
        const classRestrict = ref('btn btn-sm btn-secondary')
        const isMeSignee = ref(false)
        const AddSigneeErr = ref(false)

        const role = ref(null)
        const rolePrev = ref(null)
        const roleRef = ref(null)
        const roleOptRef = ref(null)
        const roleList = ref([])
        const roleOption = ref([])

        const arrList = ref([])
        const filSig = ref('recent') // recent/freq/contact
        const filterName = ref('')

        const disabledSignatory = ref(true)
        const disabledReview = ref(true)

        const permToAccessProject = ref(true)
        const objLSProj = ref({})
        const randKey = ref(1)

        const showMdl = ref(false)  // show success added signee with add member option modal
        const isUserSigneePaidDomain = ref(false) // if signee same domain as logined user and use paid domain (i.e. not those free domain like gmail, yahoo etc)

        const mbrEmail = ref(null)
        const mbrDisplayName = ref(null)
        const mbrExp = ref(7)
        const filterT = ref('')   // searchTeam in Invite mbr modal 
        const teamOpt = ref([])
        const mbrChecked = ref([])

        const mdlAddSignee = ref(null)
        const loginUserDomain = ref(null)
        const newTeam = ref('')
        const newTeamId = ref('')
        const toggleAddTeam = ref(false)
        const arrPermOpt = ref([])
        const arrPermChecked = ref([])

        const userPerm = ref(null)  //login user permission - ensure can read / write team permission


        watch([filterName], () => {
            setFilter(filSig.value)

        })

        watch([filterT], () => {
            getTeamOpt()
        })

        const sysAutoInviteUser = () => {
            // console.info('sysAutoInviteUser', mbrEmail.value, mbrDisplayName.value, mbrExp.value)
            // System auto create USER team for this user.
            const p = {
                username: mbrEmail.value,
                displayName: mbrDisplayName.value,
                exDay: mbrExp.value,
                frontendUrl: "/e/"
            }

            // console.info('sysAutoInviteUser p', JSON.stringify(p))
            axios.post( '/entity/member/invite', p)
                .then((res) => {
                    // console.info('invite res', JSON.stringify(res))
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        mbrEmail.value = null
                        mbrDisplayName.value = null
                        mbrExp.value = 7
                        mbrChecked.value = []

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Invitation is sent."
                        })

                    } else {
                        func.addLog('addSignee', 'sysAutoInviteUser', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'duplicate_user') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'You already invited this user.'
                                })

                            } else if (res.data.message === 'member_quota_exceeded') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Your current plan has exceeded its user limit. To accommodate additional users, please upgrade to a higher-tier plan.'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'sysAutoInviteUser  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const viewPermission = (teamId, teamName) => {
            newTeam.value = teamName
            newTeamId.value = teamId

            getTeamPerm(teamId)

        }

        const getTeamPerm = async (teamId) => {
            arrPermChecked.value = []

            await axios.get('/entity/team/' + teamId + '/permission/list', {})
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        arrPermOpt.value = res.data.data

                    } else {
                        func.addLog('addSignee', 'getTeamPerm', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'getTeamPerm - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

                

            if (arrPermOpt.value.MAIN !== undefined) {
                arrPermOpt.value.MAIN.forEach((sec, index) => {
                    if (sec.jPermission !== undefined) {
                        for (var i = 0; i < sec.jPermission.length; i++) {
                            if (sec.jPermission[i].canAccess === true) {
                                arrPermChecked.value.push(sec.jPermission[i].id)
                            }
                        }
                    }
                })
            }

            if (arrPermOpt.value.TOOL !== undefined) {
                arrPermOpt.value.TOOL.forEach((sec, index) => {
                    if (sec.jPermission !== undefined) {
                        for (var i = 0; i < sec.jPermission.length; i++) {
                            if (sec.jPermission[i].canAccess === true) {
                                arrPermChecked.value.push(sec.jPermission[i].id)
                            }
                        }
                    }
                })
            }

            if (arrPermOpt.value['BACK OFFICE'] !== undefined) {
                arrPermOpt.value['BACK OFFICE'].forEach((sec, index) => {
                    if (sec.jPermission !== undefined) {
                        for (var i = 0; i < sec.jPermission.length; i++) {
                            if (sec.jPermission[i].canAccess === true) {
                                arrPermChecked.value.push(sec.jPermission[i].id)
                            }
                        }
                    }
                })
            }
        }

        const updPermission = async () => {
            const p = {
                jPermission: arrPermChecked.value
            }

            axios.put('/entity/team/' + newTeamId.value + '/permission', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        mbrChecked.value.push(newTeamId.value)

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Permission is updated."
                        })

                        newTeam.value = ''
                        newTeamId.value = ''
                        toggleAddTeam.value = false

                    } else {
                        func.addLog('addSignee', 'updPermission', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'updPermission - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const addTeam = () => {

            const p = { teamName: newTeam.value }

            axios.post('/entity/team', p)
                .then((res) => {
                   
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        console.info('addTeam', JSON.stringify(res.data.data))

                        // newTeam.value = ''
                        newTeamId.value = res.data.data.teamId
                        getTeamPerm(res.data.data.teamId)
                        getTeamOpt()

                    } else {
                        func.addLog('addSignee', 'addTeam', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }

                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'addTeam - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const getTeamOpt = () => {
            
            const p = {}
            if (filterT.value !== '') {
                p.strSearch = filterT.value
            }

            axios.post('/entity/team/list', p)
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        teamOpt.value = res.data.data

                    } else {
                        func.addLog('addSignee', 'getTeamOpt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'getTeamOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const invMember = () => {
            if (mbrChecked.value.length === 0) {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Please select at least one team.'
                })

            } else {
                const p = {
                    username: mbrEmail.value,
                    displayName: mbrDisplayName.value,
                    jTeamId: mbrChecked.value,
                    exDay: mbrExp.value,
                    frontendUrl: "/e/"
                }

                console.info('invite p', JSON.stringify(p))
                axios.post( '/entity/member/invite', p)
                    .then((res) => {
                        console.info('invite res', JSON.stringify(res))
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            mbrEmail.value = null
                            mbrDisplayName.value = null
                            mbrExp.value = 7
                            mbrChecked.value = []

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Invitation is sent."
                            })

                        } else {
                            func.addLog('addSignee', 'invMember', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                if (res.data.message === 'duplicate_user') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'You already invited this user.'
                                    })

                                } else if (res.data.message === 'member_quota_exceeded') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Your current plan has exceeded its user limit. To accommodate additional users, please upgrade to a higher-tier plan.'
                                    })

                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('addSignee', 'invMember  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }
        }
        
        const getSigneeDisplayName = () => {
            if (signeeName.value === null || signeeName.value === '' ) {
                const p = {
                    userId: signeeEmail.value,
                }

                axios.post('/user/member/user', p)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            signeeName.value = res.data.data.displayName
                        } 
                        
                    })
                    .catch((error) => {
                        console.info('- - - getSigneeDisplayName: Err', error)
                        func.addLog('addSignee', 'getSigneeDisplayName - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            }
        }

        const getSignees = () => {
            isMeSignee.value = false
            axios.get( '/signon/' + objLSProj.value.id + '/signee/list')
                .then((res) => {

                    // console.info('getSignees', JSON.stringify(res))
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        signees.value = res.data.data
                        totalSignee.value = res.data.description.rowCount

                        if (signees.value !== []) {
                            signees.value.forEach((item, idx) => {
                                if (item.signeeId === getUserId.value) {
                                    isMeSignee.value = true
                                }
                            })
                        }
                        setTimeout(() => setPermission(), 1000)

                    } else {
                        func.addLog('addSignee', 'getSignees', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'getSignees - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const setPermission = () => {
            if (totalSignee.value === 0 || totalDoc.value === 0) {
                disabledSignatory.value = true
                disabledReview.value = true

            } else {
                disabledSignatory.value = false
                disabledReview.value = false

            }
        }

        const getDomainFromEmail = (email) => {
            const regex = /@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
            const match = email.match(regex)
            return match ? match[1] : ''
        }

        const addSignee = async () => {
            
            const p = {
                email: signeeEmail.value, 
                signeeName: signeeName.value === null || signeeName.value === '' ? signeeEmail.value : signeeName.value,
                role: roleList.value[0] === undefined ? '' : roleList.value[0]
            }   

            const signeeDomain = getDomainFromEmail(signeeEmail.value)
            // const userDomain = getDomainFromEmail(getUserId.value)

            // console.info('login domain', loginUserDomain.value, signeeDomain)

            if (signeeDomain === loginUserDomain.value) {
                const isSigneeDomainFree = freeEmailDomains.includes(signeeDomain)
                if (isSigneeDomainFree === false) {
                    isUserSigneePaidDomain.value = true

                    mbrEmail.value = signeeEmail.value
                    mbrDisplayName.value = signeeName.value === null || signeeName.value === '' ? signeeEmail.value : signeeName.value
                    getTeamOpt()
                }
                
            }

            if (signeeIsEditor.value === true) {
                p.privilege = 'EDITOR'

            } else {
                p.privilege = 'SIGNEE'

            }

            if (signeeEmail.value === null || signeeEmail.value === '') {
                AddSigneeErr.value = true

                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Signee email is compulsory.'
                })

            } else {
                AddSigneeErr.value = false
                axios.post( '/signon/' + objLSProj.value.id + '/signee', p
                ).then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        getSignees()    
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signee is added."
                        })

                        if (isUserSigneePaidDomain.value === true) {
                            showMdl.value = true

                        } else {
                            // Dismiss or close modal
                            const modalElement = mdlAddSignee.value
                            if (modalElement) {

                                modalElement.classList.remove("show")
                                modalElement.style.display = "none"
                                document.body.classList.remove("modal-open")
                                document.body.removeAttribute('class')
                                document.body.removeAttribute('style')

                                const modalBackdrop = document.querySelector('.modal-backdrop')
                                if (modalBackdrop) {
                                    modalBackdrop.parentElement.removeChild(modalBackdrop)
                                }
                            }
                        }

                    } else {
                        func.addLog('addSignee', 'addSignee', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'duplicate_signee') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'The signee already exists.'
                                })
                            
                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'addSignee - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

                resetSignee()
            }
        }

        const modifySignee = (signee, index) => {
            AddSigneeErr.value = false

            signeeIndex.value = index
            signeeName.value = signee.signeeName
            signeeEmail.value = signee.signeeId
            signeeId.value = signee.signeeId
            signeePrivilege.value = signee.privilege

            if (signeePrivilege.value === 'EDITOR') {
                signeeIsEditor.value = true

            } else {
                signeeIsEditor.value = false

            }

            roleList.value = []
            if (signee.role !== '') {
                roleList.value.push(signee.role)

            }
        }

        const updSignee = () => {
            AddSigneeErr.value = false

            const p = {
                signeeName: signeeName.value === null || signeeName.value === '' ? signeeEmail.value : signeeName.value,
                role: roleList.value[0] === undefined ? '' : roleList.value[0]
            }

            if (signeePrivilege.value !== 'OWNER') {
                if (signeeIsEditor.value === true) {
                    p.privilege = 'EDITOR'

                } else {
                    p.privilege = 'SIGNEE'

                }
            }
            axios.put( '/signon/' + objLSProj.value.id + '/signee/' + signeeId.value, p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        resetSignee()
                        getSignees() 

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signee is updated."
                        })

                    } else {
                        func.addLog('addSignee', 'updSignee', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'updSignee - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const delSignee = (signee, id) => {
            AddSigneeErr.value = false
            
            axios.delete( '/signon/' + objLSProj.value.id + '/signee/' + id)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        getSignees()    
                        resetSignee()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signee is removed."
                        })

                    } else {
                        func.addLog('addSignee', 'delSignee', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'no_permission' && res.data.description === 'owner cannot be deleted') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'You are not allow to delete folder owner.'
                                })

                            } else if (res.data.message === 'no_permission' && res.data.description === '') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'You are not allow to delete participating signee.'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'delSignee - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const resetSignee = () => {
            signeeName.value = null
            signeeEmail.value = null
            signeeRole.value = null
            signeePrivilege.value = null
            signeeIsEditor.value = false
            signeeId.value = null
            roleList.value = []         

        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const selectRole = (val) => {
            roleList.value.push(val)
            role.value = null
            signeeRole.value = val

        }

        const getTags = (tags) => {
            roleList.value = tags

        }

        const roleChange = () => {
            if (!roleOptRef.value.classList.value.includes('show')) {
                roleOptRef.value.classList.add('show')
            }

        }

        const enterRole = () => {
            if (role.value !== null && role.value !== '' && role.value.length > 0) {
                console.info('rolePrev: ' + rolePrev.value, typeof rolePrev.value, 'role: ' + role.value.toUpperCase(), typeof role.value.toUpperCase())

                if (roleOption.value.includes(role.value.toUpperCase())) {
                // if (role.value.toUpperCase() === rolePrev.value) {
                    roleList.value = []
                    roleList.value.push(role.value.toUpperCase())
                } else {

                    const p = {
                        signeeRole: role.value.toUpperCase()
                    }

                    axios.post( '/signon/signeeRole', p)
                        .then((res) => {

                            if (res.data.status === 1001) {
                                router.push({ path: '/auth/login', query: {e: 'ns'} })

                            } else if (res.data.status === 1) {
                                roleOption.value.push(role.value.toUpperCase())
                                roleList.value.push(role.value.toUpperCase())
                                rolePrev.value = role.value.toUpperCase()

                                setTimeout(() => {
                                    role.value = null
                                }, 500)

                            } else {
                                func.addLog('addSignee', 'enterRole', res)

                                if (res && res.data !== null && res.data !== undefined) {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })

                                }
                            }
                        })
                        .catch((error) => {
                            func.addLog('addSignee', 'enterRole - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                            
                        })
                    }
                }
        }

        const setRoleFocus = () => {
            if (roleList.value.length === 0 ) {
                roleRef.value.focus()

                if (roleOptRef.value.classList.value.includes('show')) {
                    roleOptRef.value.classList.remove('show')

                } else {
                    roleOptRef.value.classList.add('show')

                }
            }
        }

        const getRoleList = () => {
            axios.get( '/signon/signeeRole/list')
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                       roleOption.value = res.data.data

                    } else {
                        func.addLog('addSignee', 'getRoleList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'getRoleList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const delRoleOption = (val, idx) => {
            axios.delete( '/signon/signeeRole/' + val)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        roleOption.value.splice(idx, 1)

                    } else {
                        func.addLog('addSignee', 'delRoleOption', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'delRoleOption - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const setFilter = (opt) => {
            filSig.value = opt

            if (opt === 'freq') {
                const p = {
                    type: 'FREQUENT',
                    name: filterName.value,
                    limit: "0, 5"
                }

                axios.post( '/signon/proposedSigneeList', p)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            arrList.value = res.data.data

                        } else {
                            func.addLog('addSignee', 'setFilter Frequent', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('addSignee', 'setFilter Frequent - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })

            } else if (opt === 'contact') {

                arrList.value = [
                    { userId: 'alex.hang@yahoo.com', name: 'Hang Kar Meng', tags: [] },
                    { userId: 'joey1980@gmail.com', name: 'Joey80', tags: ['mcis', 'management'] },
                ]

            } else {
                const p = {
                    type: 'RECENT',
                    name: filterName.value,
                    limit: "0, 5"
                }

                axios.post( '/signon/proposedSigneeList', p)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            arrList.value = res.data.data

                        } else {
                            func.addLog('addSignee', 'setFilter Recent', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('addSignee', 'setFilter Recent - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }
        }

        const selectSignee = (s) => {
            signeeEmail.value = s.userId
            signeeName.value = s.signeeName

            filterName.value = ''
        }

        const getProject = (id) => {

            axios.post( '/signon/' + id, {} )
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        project.value = res.data.data
                        totalDoc.value = res.data.data.totalDocument
                        // console.info('getproj', JSON.stringify(project.value))
                        randKey.value = randKey.value + 1

                        if (res.data.data.status === 'WIP') {
                            permToAccessProject.value = true

                        } else {
                            permToAccessProject.value = false

                        }

                        // Update latest project local storage
                        let objLSProj = { id: res.data.data.folderId, name: res.data.data.folderName, status: res.data.data.status}
                        localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj))

                    } else {
                        func.addLog('addSignee', 'getProject', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    permToAccessProject.value = false
                    func.addLog('addSignee', 'getProject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        
        onMounted(async() => {
            if (func.isEmptyObject(localStorage.getItem('project')) === false) {
                objLSProj.value = func.decodeStrToJsonSimple(localStorage.getItem('project'))
            }

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))

                isOwner.value = objLSEntity.isOwner
                isEditor.value = objLSEntity.isEditor
                isFinance.value = objLSEntity.isFinance

                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            if (func.isEmptyObject(localStorage.getItem('permission')) === false) {
                userPerm.value = func.decodeStrToJson(localStorage.getItem('permission'))
                // console.info('userPerm', JSON.stringify(userPerm.value))
            }

            // Either owner or has some team permission can allow to add user
            if (isOwner.value === true || (userPerm && userPerm.includes('team.write') && userPerm.includes('member.invite') && userPerm.includes('permission.write'))) {
                allowAddUser.value = true
            }
            

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    // console.info('detect query l', route.query.l)
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            let objMdl1 = document.querySelector('#mdlAddSignee')
            objMdl1.addEventListener("show.bs.modal", function (event) {
                if (AddSigneeErr.value === false || signeeIndex.value !== null) {
                    resetSignee()
                }
            })
            objMdl1.addEventListener("hidden.bs.modal", function (event) {
                showMdl.value = false
                toggleAddTeam.value = false
                newTeam.value = ''
                newTeamId.value = ''
            })

            loginUserDomain.value = getDomainFromEmail(getUserId.value)
            

            getSignees()
            setFilter('recent')
            getRoleList()

            if (objLSProj.value.id) {
                getProject(objLSProj.value.id)
                
            }
        }) 

        return { 
            alert, closeAlert, inSequence, dragging, signees, store, router, axios, isOwner, isEditor, isFinance, isSignOn, showInviteRemark, 
            delSignee, addSignee, modifySignee, updSignee, resetSignee, getSignees, 
            signeeEmail, signeeName, signeeRole, signeePrivilege, signeeIsEditor, signeeId, signeeIndex,
            getUserId, totalSignee, project, classAll, classRestrict, 
            role, rolePrev, roleRef, roleOption, roleOptRef, roleList, selectRole, getTags, roleChange, enterRole, setRoleFocus, delRoleOption,
            disabledSignatory, disabledReview, isMeSignee, func,
            arrList, filSig, setFilter, selectSignee, filterName, randKey, getProject, permToAccessProject, objLSProj, AddSigneeErr, totalDoc,
            getSigneeDisplayName, showMdl, isUserSigneePaidDomain, mbrEmail, mbrDisplayName, mbrExp, filterT, teamOpt, invMember, mbrChecked, mdlAddSignee, 
            loginUserDomain, addTeam, toggleAddTeam, newTeam, newTeamId, sysAutoInviteUser, arrPermOpt, getTeamPerm, arrPermChecked, updPermission, viewPermission,
            userPerm, allowAddUser
        }
    }
    
}
</script>
// https://www.npmjs.com/package/vue3-form-render-vuedraggable
// npm i -S vuedraggable@next

<style>
.button {
  margin-top: 35px;
}
.handle {
  cursor: move;
}
.close {
  cursor: pointer;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}

.custom-dropdown {
    text-align: left;
    display: block;
    width: 100%;
    padding: 4px 5px;
    font-size: 1rem;
    font-weight: 400;
    height: 40px;
    color: #212529;
    background-color: var(--bgcolor-body-color);
    background-clip: padding-box;
    border: 1px solid var(--accent-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.role {
    width: 80px;
    height: 30px;
    font-size: 12px;
    color: var(--form-control-text);
    background-color: var(--bgcolor-body-color);
    border: 1px solid var(--bgcolor-body-color);
}

.role:focus { 
    outline: none; 
    color: var(--form-control-text);
    background-color: var(--bgcolor-body-color);
}

.circle
{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid;
  background-image:
    linear-gradient(18deg, white 50%, transparent 50%),
    linear-gradient(-90deg, red 50%, transparent 50%);
}

.greyBox {
  background-color: var(--auth-box-bg);
}

.filter-item {
    background: none;
    margin: 5px 5px;
    padding: 5px;
}

.filter-item.active {
  color: var(--bs-primary);
  font-weight: bold;
  border-bottom: 2px solid var(--bs-primary);
}

.filter-item.active:hover {
  color: var(--bs-primary);
  font-weight: bold;
  border-bottom: 2px solid var(--bs-primary);
  cursor: text;
}

.filter-item:hover {
  color: var(--bs-primary);
  cursor: pointer;
} 
</style>