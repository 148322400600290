<template lang="">
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Logs" icon="fa-solid fa-file-lines" />

    <div class="generalFullWidthBorderlessBox">
        <div class="row">
            <div class="col-12 mb-4">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" href="#">Error</a>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'ErrorLog', query: route.query }">
                            <div class="text-secondary nav-link">Process</div>    
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'ErrorLog', query: route.query }">
                            <div class="text-secondary nav-link">Audit</div>    
                        </router-link>
                    </li>
                </ul> 
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-lg-5 mb-2">
                <table width="100%" border="0">
                    <tr>
                        <td>
                            <VueDatePicker v-model="filterDateFr" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                        </td>
                        <td class="text-primary fw-bold px-2" > - </td>
                        <td>
                            <VueDatePicker v-model="filterDateTo" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-lg-3 mb-2 pt-1">
                <span class="filter-border border-0 p-0">
                    <div class="input-group">
                        <span class="input-group-text"><i class="fa-solid fa-user-magnifying-glass"></i></span>
                        <input type="text" v-model="filterName" class="form-control" placeholder="User" @dblclick="filterName = ''">
                        <span v-if="filterName && filterName.length > 0" class="input-group-text text-secondary bg-light isLink" @click="filterName = ''" style="cursor: pointer">
                            <i class="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                </span>
            </div>

            <div class="col-md-6 col-lg-2 mb-2 pt-1">
                <span class="filter-border border-0 p-0">
                    <div class="input-group">
                        <span class="input-group-text"><i class="fa-duotone fa-solid fa-tags"></i></span>
                        <input type="text" v-model="filterCat" class="form-control" placeholder="Category" @dblclick="filterCat = ''">
                        <span v-if="filterCat && filterCat.length > 0" class="input-group-text text-secondary bg-light isLink" @click="filterCat = ''" style="cursor: pointer">
                            <i class="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                </span>
            </div>

            <div class="col-md-6 col-lg-2 mb-2 pt-1">
                <span class="filter-border border-0 p-0">
                    <div class="input-group">
                        <span class="input-group-text"><i class="fa-solid fa-function"></i></span>
                        <input type="text" v-model="filterFunc" class="form-control" placeholder="Function" @dblclick="filterFunc = ''">
                        <span v-if="filterFunc && filterFunc.length > 0" class="input-group-text text-secondary bg-light isLink" @click="filterFunc = ''" style="cursor: pointer">
                            <i class="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <!-- <div class="generalContentBox border shadow-sm"> -->
                <div class="generalFullWidthBorderlessContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-lg-6 mb-3 align-middle">
                            <table>
                                <tr>
                                    <td>Page {{ pgPage }} / {{pgLastPage}}</td>
                                    <td class="px-2"><i class="fa-solid fa-circle-small" style="font-size: 4px; padding-bottom: 5px; color: #dadcde"></i></td>
                                    <td>{{pgRcdFr}} - {{pgRcdTo}} of {{total}} items</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <table class="text-center" align="right">
                                <tr>
                                    <td v-if="total > 0 && !pgArrPage.includes(1)" width="30px">
                                        <a class="isLink" href="#" @click="first"><i class="fa-solid fa-angles-left"></i></a>
                                    </td>
                                    <td v-if="pgArrPage[0] > 1" width="35px">
                                        <a class="isLink" href="#" @click="previous"><i class="fa-solid fa-angle-left"></i></a>
                                    </td>
                                    <td width="30px" v-for="p in pgArrPage" :key="p">
                                        <a @click="pgPage = p" class="isLink" :class="pgPage === p ? 'text-primary' : ''" href="javascript:void(0);">{{p}}</a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="30px">
                                        <a class="isLink" href="#" @click="next"><i class="fa-solid fa-angle-right"></i></a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="35px">
                                        <a class="isLink" href="#" @click="last"><i class="fa-solid fa-angles-right"></i></a>
                                    </td>
                                    <td class="align-middle"><div class="vr ms-1 mt-1"></div></td>
                                    <td>
                                        <a class="nav-link dropdown-toggle" href="#" id="sortLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i v-if="sortField === 'date' && sortOrder === 'asc'" class="fa-solid fa-arrow-down-short-wide text-primary"></i>
                                            <i v-if="sortField === 'date' && sortOrder === 'desc'" class="fa-solid fa-arrow-up-short-wide text-primary"></i>
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="sortLink">
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'asc'">
                                                    <i class="fa-solid fa-arrow-down-short-wide me-1"></i>Date ASC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'desc'">
                                                    <i class="fa-solid fa-arrow-up-short-wide me-1"></i>
                                                    Date DESC
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-12">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="row">
                                                <div class="col-lg-2">Date</div>
                                                <div class="col-lg-2">User</div>
                                                <div class="col-6 col-lg-2">Category</div>
                                                <div class="col-6 col-lg-2">Function</div>
                                                <div class="col-12 col-lg-4">Error</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody v-if="arrDgLog">
                                    <tr v-for="(log, logIndex) in arrDgLog" :key="log">
                                        <td v-if="log"> 
                                            <div class="row">
                                                <div class="col-lg-2">{{func.convDateTimeFormat(log.dateCreate, 'text')}}</div>
                                                <div class="col-lg-2">{{log.jUser.userId}}</div>
                                                <div class="col-6 col-lg-2">{{ log.category }}</div>
                                                <div class="col-6 col-lg-2">{{ log.title }}</div>
                                                <div class="col-12 col-lg-4">
                                                    <div class="small text-wrap" style="max-height: 120px; overflow: auto">
                                                        {{ log.description }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-6 mb-3 align-middle">
                            <table>
                                <tr>
                                    <td>Page {{ pgPage }} / {{pgLastPage}}</td>
                                    <td class="px-2"><i class="fa-solid fa-circle-small" style="font-size: 4px; padding-bottom: 5px; color: #dadcde"></i></td>
                                    <td>{{pgRcdFr}} - {{pgRcdTo}} of {{total}} items</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <table class="text-center" align="right">
                                <tr>
                                    <td v-if="total > 0 && !pgArrPage.includes(1)" width="30px">
                                        <a class="isLink" href="#" @click="first"><i class="fa-solid fa-angles-left"></i></a>
                                    </td>
                                    <td v-if="pgArrPage[0] > 1" width="35px">
                                        <a class="isLink" href="#" @click="previous"><i class="fa-solid fa-angle-left"></i></a>
                                    </td>
                                    <td width="30px" v-for="p in pgArrPage" :key="p">
                                        <a @click="pgPage = p" class="isLink" :class="pgPage === p ? 'text-primary' : ''" href="javascript:void(0);">{{p}}</a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="30px">
                                        <a class="isLink" href="#" @click="next"><i class="fa-solid fa-angle-right"></i></a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="35px">
                                        <a class="isLink" href="#" @click="last"><i class="fa-solid fa-angles-right"></i></a>
                                    </td>
                                    <td class="align-middle">
                                        <div class="vr ms-1 mt-1"></div>
                                    </td>
                                    <td>
                                        <a class="nav-link dropdown-toggle" href="#" id="sortLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i v-if="sortField === 'date' && sortOrder === 'asc'" class="fa-solid fa-arrow-down-short-wide text-primary"></i>
                                            <i v-if="sortField === 'date' && sortOrder === 'desc'" class="fa-solid fa-arrow-up-short-wide text-primary"></i>
                                            <i v-if="sortField === 'status' && sortOrder === 'asc'" class="fa-solid fa-arrow-up-a-z text-primary"></i>
                                            <i v-if="sortField === 'status' && sortOrder === 'desc'" class="fa-solid fa-arrow-down-z-a text-primary"></i>
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="sortLink">
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'asc'">
                                                    <i class="fa-solid fa-arrow-down-short-wide me-1"></i>Date ASC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'desc'">
                                                    <i class="fa-solid fa-arrow-up-short-wide me-1"></i>
                                                    Date DESC
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                
    </div>
</template>

<script>
import { ref, inject, watch, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'ErrorLog',
    components: { Alert, TopNavigation, Popper, VueDatePicker },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const totalFilter   = ref(0)
        const filterDateFr  = ref('')
        const filterDateTo  = ref('')
        const filterName    = ref('')
        const filterCat    = ref('')
        const filterFunc    = ref('')

        const total = ref(0)
        const rcdFr = ref(0) // record index from 
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const arrDgLog = ref([])     // record shown in dg, total length shown as totalShown
        
        const pgRcdFr   = ref(1)    // Pagination rcd fr
        const pgRcdTo   = ref(1)    // Pagination rcd to
        const pgPage    = ref(1)    // Pagination page no selected
        const pgLastPage= ref(1)    // Pagination last page
        const pgNoPageList= ref(3)  // Pagination list n page number between previous and next
        const pgArrPage = ref([])   // Pagination pages listed

        const sortField = ref('date')
        const sortOrder = ref('desc')

        watch([filterName, filterCat, filterFunc, filterDateFr, filterDateTo, sortField, sortOrder], () => {
           
            getLogList(true)
        })
        
        watch([pgPage], () => {
            console.info('- - WATCH: pgPage', pgPage.value)

            if (pgPage.value === 1) {
                rcdFr.value = 0
            } else {
                rcdFr.value = (pgPage.value - 1) * rcdDisplay.value
            }
            
            pgRcdFr.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : (pgPage.value -1 ) * rcdDisplay.value + 1
            pgRcdTo.value = pgRcdFr.value + rcdDisplay.value - 1 >= total.value ? total.value : pgRcdFr.value + rcdDisplay.value - 1
            getLogList(false)
        })

        const first = () => {

            let listBegin = 1
            let listLast = pgLastPage.value >= pgNoPageList.value ? pgNoPageList.value : pgLastPage.value

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 

            if (total.value === 0) pgRcdFr.value = 0
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const previous = () => {
            let listBegin = pgArrPage.value[0] - pgNoPageList.value <= 1 ? 1 : pgArrPage.value[0] - pgNoPageList.value
            let listLast = listBegin + (pgNoPageList.value - 1) >= pgLastPage.value ? pgLastPage.value : listBegin + (pgNoPageList.value - 1)

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const next = () => {
            let listBegin = pgArrPage.value[pgArrPage.value.length-1] + 1
            let listLast = listBegin + (pgNoPageList.value - 1) >= pgLastPage.value ? pgLastPage.value : listBegin + (pgNoPageList.value - 1)

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const last = () => {
            let listBegin = Math.ceil(pgLastPage.value / pgNoPageList.value) - 1 
            listBegin = listBegin * pgNoPageList.value + 1

            pgArrPage.value = []
            for (var i = listBegin; i <= pgLastPage.value; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const setDateFr = (val) => {
            filterDateFr.value = func.convDateTimeFormat(val, 'dateCreate')
        }

        const setDateTo = (val) => {
            filterDateTo.value = func.convDateTimeFormat(val, 'dateCreate')
        }

        const getLogList = async (setToFirst) => {

            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value,
                orderBy: [
                    {
                        field: sortField.value,
                        order: sortOrder.value
                    }
                ] 
            }

            p.dateFrom = filterDateFr.value + ' 00:00:00'
            p.dateTo = filterDateTo.value + ' 23:59:59'

            if (filterName.value !== '' && filterName.value.length >= 3) {
                p.name = filterName.value
            }

            if (filterCat.value !== '' && filterCat.value.length >= 3) {
                p.category = filterCat.value
            }

            if (filterFunc.value !== '' && filterFunc.value.length >= 3) {
                p.title = filterFunc.value
            }
          

            console.info('getLogList REQ',JSON.stringify(p))

            axios.post('/report/su/log/list', p)
            .then((res) => {
                if (res.data.status === 1) {
                    
                    total.value = res.data.description.rowCount
                    arrDgLog.value = res.data.data
                    // console.info('arrDgLog RES', JSON.stringify(arrDgLog.value))
                    console.info('arrDgLog RES', res.data.description.rowCount)
                    
                    // show pagination
                    pgLastPage.value = Math.ceil(total.value / rcdDisplay.value)
                    if (setToFirst === true) {
                        pgPage.value = 1
                        first()
                    }
                }
            })
            .catch((error) => {
                console.log('getLogList | ERR', error)
            
            })
           
        }   

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }


        onMounted(async () => {

            filterDateFr.value = func.convDateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 8), 'date')
            filterDateTo.value = func.convDateTimeFormat(new Date(), 'date')
           
            getLogList()
        })

        return {
            alert, closeAlert, route, router, func, store, 
            totalFilter, filterDateFr, filterDateTo, filterName, filterCat, filterFunc, setDateFr, setDateTo,
            total, rcdDisplay, arrDgLog, rcdFr, rcdDisplay, 
            pgRcdFr, pgRcdTo, pgPage, pgLastPage, pgArrPage, pgNoPageList, 
            first, previous, next, last, sortField, sortOrder
        }
    }
}
</script>

<style>
    .dropdown-item.active {
        background-color: #53BDEB;
        color: #fff;
    }
</style>