<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Report" icon="fa-solid fa-chart-line" />

    <div class="rptContainer">
        <div class="row">
            <div class="col-12 bg-danger text-white fst-italic">
                <span>* Report preview for : </span>
                <span class="isLink" @click="rptType = ''" :class="rptType === '' ? 'fw-bold' : ''">Personal </span>
                <span> | </span>
                <span class="isLink" @click="rptType = 'team'" :class="rptType === 'team' ? 'fw-bold' : ''">Team </span>
                <span>subscriber</span>
            </div>
            <div class="col-lg-5 d-grid">
                <div class="row">
                    <div class="col-12">
                        <div class="rptBox shadow-sm">
                            <div class="fw-bold">Completion</div>
                            <div class="text-grey small mb-4">Mar - Aug 2022</div>
                            <div>
                                <ChartBar :data="progressData" :label="progressLabel" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 d-grid">
                        <div class="rptBox shadow-sm">
                            <ReportFigureBox title="INVITED" leftTitle="352" leftData="DOC" rightTitle="340" rightData="PROJ" />
                        </div>
                    </div>
                    <div class="col-lg-6 d-grid">
                        <div class="rptBox shadow-sm">
                            <ReportFigureBox title="SIGNED" leftTitle="104" leftData="DOC" rightTitle="91" rightData="PROJ" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 d-grid">
                <div class="rptBox shadow-sm">

                    <div class="row p-0">
                        <div class="col-lg-6"><span class="fw-bold">Traffic</span> <div class="text-grey small mb-2">Mar - Aug 2022</div></div>
                        <div class="col-lg-6 mb-4">
                            <div class="btn-group btn-group-sm float-end">
                                <button type="button" class="btn btn-outline-secondary">Day</button>
                                <button type="button" class="btn btn-secondary">Month</button>
                                <button type="button" class="btn btn-outline-secondary">Year</button>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <ChartLine :data="trafficData" :label="trafficLabel" />
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>

        <div v-if="rptType === 'team'" class="row">
            <div class="col-lg-10 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Member</div>
                    <div class="text-grey small mb-4">1,500 registered members</div>
                    <div>
                        <table class="table table-hover text-grey" border="0">
                            <thead>
                                <tr>
                                    <th width="50px"></th>
                                    <th>
                                        <div class="row">
                                            <div class="col-lg-4">Member</div>
                                            <div class="col-lg-3">Usage</div>
                                            <div class="col-lg-2 text-end d-none d-md-block pe-4">Doc</div>
                                            <div class="col-lg-2 text-end d-md-none">Doc</div>
                                            <div class="col-lg-1 text-end d-none d-md-block pe-4">GMT</div>
                                            <div class="col-lg-1 text-end d-md-none">GMT</div>
                                            <div class="col-lg-2">Activity</div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(mbr, mbrIdx) in arrMember" :key="mbr">
                                    <td width="50px" class="text-center">
                                        <ImgAvatar :key="mbrIdx" isUser="true" id="" displayName="" :isVerified="mbr.isVerified" :isActive="mbr.status" privilege="" width="30" height="30" />
                                        <!-- <Avatar :key="mbrIdx" isUser="true" avatar="" displayName="" :isVerified="mbr.isVerified" :isActive="mbr.status" width="30" height="30" /> -->
                                    </td>
                                
                                    <td class="align-middle">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="isLink">
                                                    <router-link :to="'/team/member/' + mbr.userId">
                                                        <span class="fw-bold">{{mbr.lastName}} {{mbr.firstName}}</span>
                                                        <span class="fw-bold text-grey"><fa icon="at" class="mx-1"/>{{ mbr.displayName }}</span>
                                                    </router-link>
                                                    <div class="small text-grey">{{ mbr.userId }}</div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 pt-1">
                                                <div class="progress"> 
                                                    <div class="progress-bar progress-bar-striped" :style="'width: ' + mbr.progress + '%'">{{mbr.progress}}% </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 text-end d-none d-md-block pe-4">{{mbr.totalDoc}}<fa icon="file" class="ms-2"/></div>
                                            <div class="col-lg-2 text-end d-md-none">{{mbr.totalDoc}}</div>
                                            <div class="col-lg-1 text-end d-none d-md-block pe-4">{{mbr.gmt}}</div>
                                            <div class="col-lg-1 text-end d-md-none">{{mbr.gmt}}</div>
                                            <div class="col-lg-2 small">
                                                <div class="small text-grey">Last login</div>
                                                <div class="fw-bold">{{ func.getDateTimeDiff(func.convDateTimeFormat(mbr.dateLastLogin), new Date()) }}</div>
                                            </div>
                                            
                                        </div>
                                    </td>
                                </tr>

                                <tr v-if="isLoadingMore" class="mt-3 text-center">
                                    <td colSpan="2">
                                        <fa icon="spinner" size="lg" class="me-2" />Loading...
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 d-grid">
                <div class="rptBox shadow-sm text-white" style="background-color: #77CEFF">
                    <ReportFigureBox title="STORAGE" leftTitle="3.2 GB" leftData="USED" rightTitle="5 GB" rightData="LIMIT" />
                </div>

                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="KYC" leftTitle="15" leftData="VERIFIED" rightTitle="135" rightData="UNVERIFIED" />
                </div>

                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="MEMBER" leftTitle="64" leftData="MALE" rightTitle="86" rightData="FEMALE" />
                </div>

            </div>
        </div>

        <div v-if="rptType === ''" class="row">
            <div class="col-lg-9 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Document</div>
                    <div class="text-grey small mb-4">320 created documents</div>
                    <div>
                        <table class="table table-hover text-grey" border="0">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="row">
                                            <div class="col-lg-4">Document</div>
                                            <div class="col-lg-2">Status</div>
                                            <div class="col-lg-2 text-end d-none d-md-block pe-4">Signed</div>
                                            <div class="col-lg-2 text-end d-md-none">Signed</div>
                                            <div class="col-lg-2">Progress</div>
                                            <div class="col-lg-2">Update</div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="d in arrDoc" :key="d">
                                    <td>
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="fw-bold">{{d.name}}</div>
                                                <div class="text-grey small">Created {{ func.getDateTimeDiff(func.convDateTimeFormat(d.dateCreate), new Date()) }}</div>
                                            </div>
                                            <div class="col-lg-2">{{d.status}}</div>
                                            <div class="col-lg-2 text-end d-none d-md-block pe-4">{{d.signees.signed}} / {{d.signees.total}}<fa icon="user" class="ms-2" /></div>
                                            <div class="col-lg-2 text-end d-md-none">{{d.signees.signed}} / {{d.signees.total}}<fa icon="user" class="ms-2" /></div>

                                            <div class="col-lg-2 pt-1">
                                                <div class="progress"> 
                                                    <div class="progress-bar progress-bar-striped" :style="'width: ' + d.progress + '%'">{{d.progress}}% </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="small text-grey">Last modified</div>
                                                <div class="fw-bold">{{ func.getDateTimeDiff(func.convDateTimeFormat(d.dateModify), new Date()) }}</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Storage</div>
                    <div class="text-grey small mb-4">300 MB free of 1 GB</div>
                    <ChartPie :data="storeData" :label="storeLabel" :color="storeColor" />
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'

import ChartBar from '@/components/chart/ChartBar.vue'
import ChartLine from '@/components/chart/ChartLine.vue'
import ChartPie from '@/components/chart/ChartPie.vue'

import ReportFigureBox from '@/components/ReportFigureBox.vue'

export default {
    name: 'DashboardReport',
    components: { Alert, TopNavigation, Popper, /* Avatar, */ ImgAvatar, ChartBar, ChartLine, ChartPie, ReportFigureBox },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const rptType = ref('')  // temporary for toggle package report

        const progressData = ref([])
        const progressLabel = ref([])

        const trafficData = ref([])
        const trafficLabel = ref([])

        const storeData = ref([])
        const storeLabel = ref([])
        const storeColor = ref([])

        const arrMember = ref([])   // order by last login
        const arrDoc = ref([])      // order by dateModified

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getProgress = () => {
            progressData.value = [
                {
                    label: 'Document',
                    data: [ 5, 7, 8, 15, 18, 22],
                    backgroundColor: '#77CEFF',
                    borderColor: '#77CEFF',
                }
            ]
            progressLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        }

        const gettraffic = () => {
            trafficData.value = [
                {
                    label: 'Document',
                    data: [65, 59, 80, 81, 86, 55, 90],
                    fill: false,
                    backgroundColor: '#0079AF',
                    borderColor: '#0079AF',
                    tension: 0.4,
                },
                {
                    label: "Project",
                    data: [28, 48, 40, 19, 56, 27, 40],
                    fill: true,
                    backgroundColor: "#77CEFF",
                    tension: 0.4,
                },
            ]
            trafficLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        }

        const getStore = () => {
            storeData.value = [70, 30]
            storeLabel.value = ['Used %', 'Free %']
            storeColor.value = ['#0079AF', '#77CEFF']
        }

        getProgress()
        gettraffic()
        getStore()

        onMounted(() => {

            arrMember.value = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    displayName: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    teams: ['admin', 'developer'],
                    tags: ['colleague', 'development'],
                    dateLastLogin: '2022-08-26T05:23:10.000Z',
                    progress: 20,
                    totalDoc: 25,
                    gmt: '+09:00',
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    displayName: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    teams: ['developer'],
                    tags: ['colleague', 'cs'],
                    dateLastLogin: '2022-08-26T02:00:51.000Z',
                    progress: 55,
                    totalDoc: 33,
                    gmt: '+08:00',
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    displayName: 'Grace',
                    avatar: '',
                    isVerified: false,
                    teams: [],
                    tags: [],
                    dateLastLogin: '2022-08-26T01:00:51.000Z',
                    progress: 80,
                    totalDoc: 150,
                    gmt: '+10:00',
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    displayName: 'Jason',
                    avatar: '',
                    isVerified: false,
                    teams: ['customer service'],
                    tags: ['unifi'],
                    dateLastLogin: '2022-08-23T04:00:51.000Z',
                    progress: 36,
                    totalDoc: 45,
                    gmt: '+08:00',
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    displayName: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    teams: ['admin'],
                    tags: ['colleague', 'support'],
                    dateLastLogin: '2022-07-19T07:40:51.000Z',
                    progress: 88,
                    totalDoc: 12,
                    gmt: '+02:00',
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    displayName: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    teams: ['developer'],
                    tags: ['colleague', 'development'],
                    dateLastLogin: '2022-05-20T07:40:51.000Z',
                    progress: 0,
                    totalDoc: 0,
                    gmt: '+08:00',
                },
            ]

            arrDoc.value = [
                {
                    documentId: '1001',
                    name: 'Agreement - Setia Alam Residential.pdf',
                    dateCreate: '2022-08-25T06:25:03.000Z',
                    dateModify: '2022-08-26T04:34:04.000Z',
                    status: 'SIGNING',
                    size: '69781',
                    signees: {
                        total: 5,
                        signed: 3
                    },
                    progress: 60
                },
                {
                    documentId: '1002',
                    name: 'Sample Agreement.pdf',
                    dateCreate: '2022-08-23T15:37:03.000Z',
                    dateModify: '2022-08-23T06:34:04.000Z',
                    status: 'ALLSIGNED',
                    size: '69781',
                    signees: {
                        total: 5,
                        signed: 5
                    },
                    progress: 100
                },
                {
                    documentId: '1003',
                    name: 'Contact of XXXXXXXXXX XXXXX.pdf',
                    dateCreate: '2022-08-15T15:37:03.000Z',
                    dateModify: '2022-08-15T06:34:04.000Z',
                    status: 'STORED',
                    size: '69781',
                    signees: {
                        total: 2,
                        signed: 2
                    },
                    progress: 100
                },
                {
                    documentId: '1004',
                    name: 'Sample Agreement II.pdf',
                    dateCreate: '2022-07-10T15:37:03.000Z',
                    dateModify: '2022-07-12T06:34:04.000Z',
                    status: 'DRAFT',
                    size: '69781',
                    signees: {
                        total: 0,
                        signed: 0
                    },
                    progress: 0
                },
                {
                    documentId: '1005',
                    name: 'Service Apartment Tenancy Agreement.pdf',
                    dateCreate: '2022-06-25T01:25:03.000Z',
                    dateModify: '2022-06-25T04:34:04.000Z',
                    status: 'SIGNING',
                    size: '69781',
                    signees: {
                        total: 2,
                        signed: 1
                    },
                    progress: 50
                },
                {
                    documentId: '1006',
                    name: 'Payment_agreement.pdf',
                    dateCreate: '2022-06-20T06:25:03.000Z',
                    dateModify: '2022-06-20T04:34:04.000Z',
                    status: 'STORED',
                    size: '69781',
                    signees: {
                        total: 2,
                        signed: 2
                    },
                    progress: 100
                }
            ]
        })

        return {
            alert, closeAlert, router, func, store, rptType, 
            progressData, progressLabel, trafficData, trafficLabel, storeData, storeLabel, storeColor, 
            arrMember, arrDoc,
        }
    }
}
</script>

<style>
.rptContainer {
  width: 96%; 
  margin: 105px auto 20px auto;
}

.rptBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--chart-bg);
}

@media (max-width: 500px) {
    .rptContainer {
      width: 95%;
      margin: 105px auto 20px auto;
    }

}
</style>