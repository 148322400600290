<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Team" icon="fa-solid fa-users" />

    <div class="generalBorderlessBox">
        <div class="row mb-4">
            <div class="col-12">
                 <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" href="#"><fa icon="users" class="me-2" />Team</a>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Member', query: route.query }">
                            <div class="text-secondary nav-link"><fa icon="user" class="me-2" />User</div>    
                        </router-link>
                    </li>
                </ul> 
            </div>
        </div>

        <div class="row">
            <!-- Left column -->
            <div class="col-lg-7">
                <div class="generalContentBox border shadow-sm">

                    <div class="row">
                        <div class="col-6 mb-2">
                            <div class="input-group">
                                <span class="input-group-text"><fa icon="search" /></span>
                                <input type="text" v-model="filterName" class="form-control" placeholder="Team" >
                                <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch isLink" @click="filterName = ''">
                                    <fa icon="xmark" />
                                </span>
                            </div>
                        </div>
                        <div class="col-6 mb-2 text-end">
                            <span class="isLink" data-bs-toggle="modal" data-bs-target="#mdlTeam" @click="teamId = null; teamName = ''">
                                <fa icon="users"/>
                                <span class="fw-bold me-2" style="font-size: 14px">+</span>
                                Add team
                            </span>
                        </div>
                    </div>

                    <!-- Team list -->
                    <div class="row">
                        <div class="col-12" ref="scrollComponent">
                            <div v-if="isLoading" class="mb-3 text-center">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>

                            <table width="100%" border="0">
                                <tr>
                                    <td width="55px">
                                        <div v-if="checkedTeam && checkedTeam.length > 0">
                                            <span class="mx-2 text-grey isLink" @click="delTeams">
                                                <fa icon="trash" size="lg" style="padding-left: 9px" />
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="checkedTeam && checkedTeam.length > 0">
                                            <span class="text-grey small">{{checkedTeam.length}} selected</span>
                                        </div>
                                    </td>
                                    <td class="text-end py-2">
                                        <div v-if="!isLoading" class="small fst-italic text-grey">{{totalShown}} of {{total}}</div>
                                    </td>
                                </tr>
                            </table>

                            <table v-if="arrDgTeam && arrDgTeam.length > 0 " class="table table-hover" border="0">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrDgTeam" :key="item" :class="viewTeamId === item.teamId ? 'table-secondary' : ''">
                                        
                                        <td v-if="item && itemIdx >= 0" width="50px" class="text-center">
                                            <div>
                                                <input v-model="checkedTeam" type="checkbox" class="form-check-input" :value="item.teamId" />
                                            </div>
                                        </td>
                                    
                                        <td v-if="item  && itemIdx >= 0" class="align-middle">
                                            <div class="row isLink" @click="getTeamMbrList(item)">
                                                <div class="col-lg-5">
                                                    <div>
                                                        <span class="fw-bold me-2 isLink">{{item.teamName}}</span>
                                                        <span v-if="item.isDefault" class="text-grey fst-italic small">Default</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-5 text-end d-none d-md-block hoverShow">
                                                    <span v-if="!(item.teamId === '1' || item.teamId === '2')"  data-bs-toggle="modal" data-bs-target="#mdlDelTeam" @click="teamId = item.teamId; teamName = item.teamName" class="text-grey isLink me-4">
                                                        <Popper class="popperDark" arrow hover content="Delete team">
                                                            <fa icon="trash" size="lg" />
                                                        </Popper>
                                                    </span>

                                                    <span v-if="!item.isDefault" data-bs-toggle="modal" data-bs-target="#mdlTeam" @click.prevent="teamId = item.teamId; teamName = item.teamName" class="text-grey isLink me-4">
                                                        <Popper class="popperDark" arrow hover content="Edit team">
                                                            <fa icon="pen" size="lg" />
                                                        </Popper>
                                                    </span>

                                                    <span data-bs-toggle="modal" data-bs-target="#mdlAddMbr" @click="getAddMbrList(item)" class="text-grey isLink me-4">
                                                        <Popper class="popperDark" arrow hover content="Add user">
                                                            <fa icon="user-plus" size="lg" />
                                                        </Popper>
                                                    </span>

                                                    <span class="text-grey isLink me-2" @click="$router.push({ path: '/team/permission/' + item.teamId })">
                                                        <Popper class="popperDark" arrow hover content="Set permission">
                                                            <fa icon="user-shield" size="lg" />
                                                        </Popper>
                                                    </span>
                                                    
                                                </div>

                                                <div class="col-lg-2 small text-grey text-end">
                                                    <Popper class="popperDark" arrow hover content="Team user">
                                                        <span class="badge isTooltipsBadgeIcon me-1"><fa icon="user" size="lg" /> {{ item.totalMember }}</span>
                                                    </Popper>
                                                </div>
                                                
                                                <!-- Small screen -->
                                                <div class="col-12 text-end d-block d-md-none">
                                                    <span v-if="!(item.teamId === '1' || item.teamId === '2')" data-bs-toggle="modal" data-bs-target="#mdlDelTeam" @click="teamId = item.teamId; teamName = item.teamName" class="text-grey isLink me-4">
                                                        <fa icon="trash" size="lg" />
                                                    </span>

                                                    <span v-if="!item.isDefault" data-bs-toggle="modal" data-bs-target="#mdlTeam" @click="teamId = item.teamId; teamName = item.teamName" class="text-grey isLink me-4">
                                                        <fa icon="pen" size="lg" />
                                                    </span>

                                                    <span data-bs-toggle="modal" data-bs-target="#mdlAddMbr" @click="getAddMbrList(item)" class="text-grey isLink me-4">
                                                        <fa icon="user-plus" size="lg" />
                                                    </span>

                                                    <span class="text-grey isLink me-2" @click="$router.push({ path: '/team/permission/' + item.teamId })">
                                                        <Popper class="popperDark" arrow hover content="Set permission">
                                                            <fa icon="user-shield" size="lg" />
                                                        </Popper>
                                                    </span>

                                                </div>
                                                <!-- End Small screen -->
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="isLoadingMore" class="mt-3 text-center">
                                        <td colSpan="2">
                                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- End of Team list -->

                </div>
            </div>

            <!-- Right column: Team Member -->
            <div class="col-lg-5">
                <div class="generalContentBox border shadow-sm">
                    <div v-if="viewTeamId !== ''" class="fw-bold mb-2">Team User</div>

                    <div v-if="viewTeamId !== ''" class="table-responsive" style="height: 900px">
                        <table class="table table-hover" border="0">
                            <tbody v-if="arrTeamMbr">
                                <tr v-for="(t, tIdx) in arrTeamMbr" :key="t" data-bs-dismiss="modal">
                                    <td v-if="t" class="align-middle" width="50px">
                                        <ImgAvatar :key="tIdx" :isUser="true" :id="t.jAvatar.userId" displayName="" :isVerified="t.isVerified" :isActive="t.status" privilege="" width="30" height="30" />
                                        <!-- <Avatar :key="tIdx" isUser="true" :avatar="t.jAvatar.imgBase64" displayName="" :isVerified="t.isVerified" :isActive="t.status" width="30" height="30" /> -->
                                    </td>
                                    <td class="align-middle" width="*">
                                        <div class="isLink">
                                            <router-link :to="'/team/member/' + t.userId">
                                                <span class="fw-bold">{{t.fullName}}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{ t.displayName }}</span>
                                            </router-link>
                                        </div>
                                        <div class="small text-grey">{{ t.userId }}</div>
                                        <div>
                                            <span v-for="te in t.jTeam" :key="te" class="badge bg-secondary me-1">{{te.teamName}}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Modal - Add/Edit team--> 
    <div
        class="modal fade"
        id="mdlTeam"
        tabindex="-1"
        aria-labelledby="AddEditTeam"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="teamId === null" class="modal-title"> Add team </h5>
                    <h5 v-else class="modal-title">Edit team :: {{teamName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="teamId === null" class="row">
                        <div class="col-12 pb-3">
                            <!-- <input v-model="teamName" class="form-control" placeholder="Team name"> -->
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="teamName" id="teamName" placeholder="Team name">
                                <label for="teamName">Team name</label>
                            </div>
                        </div>
                    </div>

                    <!-- Edit team -->
                    <div v-else class="row">
                        <div class="col-12 pb-4">
                            <div class="small text-grey mb-2">Rename Team To</div>
                            <div class="input-group">
                                <input v-model="teamName" class="form-control" placeholder="Team name">
                                <button class="btn btn-secondary" @click="renameTeam" type="button" data-bs-dismiss="modal" style="width: 140px" >
                                    <fa icon="floppy-disk" class="me-2" />Update
                                </button>
                            </div>
                        </div>

                        <div v-if="showMoreEditTeam === false" class="col-12 small text-primary text-end isLink" @click="showMoreEditTeam = true">
                            + More
                        </div>

                        <div v-if="showMoreEditTeam === true" class="col-12 pb-4">
                            <div class="small text-grey mb-2">Duplicate '{{teamName}}' Team To</div>
                            <div class="input-group">
                                <!-- <select class="form-select" v-model="dupTeamId">
                                    <option value="">Select team</option>
                                    <option :value="t.teamId" v-for="t in arrTeam" :key="t.teamId">{{ t.teamName }}</option>
                                </select> -->
                                <input v-model="dupTeamName" class="form-control" placeholder="New team">
                                <button class="btn btn-secondary" @click="dupTeam(false)" type="button" data-bs-dismiss="modal" style="width: 140px">
                                    <fa icon="clone" class="me-2" />Duplicate
                                </button>
                            </div>
                        </div>

                        <div v-if="showMoreEditTeam === true" class="col-12 pb-4">
                            <div class="small text-grey mb-2">Duplicate '{{teamName}}' Team & Users To</div>
                            <div class="input-group">
                                <!-- <select class="form-select" v-model="dupTeamId">
                                    <option value="">Select team</option>
                                    <option :value="t.teamId" v-for="t in arrTeam" :key="t.teamId">{{ t.teamName }}</option>
                                </select> -->
                                <input v-model="dupTeamName2" class="form-control" placeholder="New team">
                                <button class="btn btn-secondary" @click="dupTeam(true)" type="button" data-bs-dismiss="modal" style="width: 140px">
                                    <fa icon="clone" class="me-2" />Duplicate
                                </button>
                            </div>
                        </div>

                    </div>
                    <!-- New UI End -->

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="teamId === null && teamName !== ''" type="button" @click="addTeam" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - Add team mbr --> 
    <div
        class="modal fade"
        id="mdlAddMbr"
        tabindex="-1"
        aria-labelledby="AddMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Add user :: {{teamName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- <div class="mb-3">
                        <div class="input-group" style="width: 50%">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <select v-model="memberType" class="form-select" @change="getAddMbrList({'teamId': teamId, 'teamName': teamName})">
                                <option value="all">All</option>
                                <option value="member">Member</option>
                                <option value="nonmember">Not Member</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="checkedMember && checkedMember.length > 0" class="fw-bold text-grey text-end align-middle mb-1">
                        {{checkedMember.length}} selected
                    </div> -->

                    <div class="row">
                        <div class="col-6">
                            <div class="input-group" >
                                <span class="input-group-text"><fa icon="search" /></span>
                                <select v-model="memberType" class="form-select" @change="getAddMbrList({'teamId': teamId, 'teamName': teamName})">
                                    <option value="all">All</option>
                                    <option value="member">User</option>
                                    <option value="nonmember">Not User</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 text-end" >
                            <div v-if="checkedMember && checkedMember.length > 0" class="text-grey small py-2">
                                {{checkedMember.length}} selected
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive" style="height: 300px">
                        <table class="table table-hover" border="0">
                            <tbody>
                                <tr v-for="(m, mIdx) in arrMember" :key="m" >
                                    <td width="35px" class="align-middle">
                                        <input v-model="checkedMember" type="checkbox" class="form-check-input" :value="m.userId" />
                                    </td>
                                    <td width="50px" class="align-middle">
                                        <ImgAvatar :key="mIdx" :isUser="true" :id="m.jAvatar.userId" displayName="" :isVerified="m.isVerified" :isActive="m.status" privilege="" mwidth="30" height="30" />
                                        <!-- <Avatar :key="mIdx" isUser="true" avatar="" displayName="" :isVerified="m.isVerified" width="30" height="30" /> -->
                                    </td>
                                    <td class="align-middle">
                                        <div class="isLink" data-bs-dismiss="modal">
                                            <router-link :to="'/team/member/' + m.userId">
                                                <span class="fw-bold">{{ m.fullName }}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{ m.displayName }}</span>
                                            </router-link>
                                        </div>
                                        <div class="small text-grey">{{ m.userId }}</div>
                                    </td>
                                    <td class="text-end">
                                        <div v-if="m.jTeam">
                                            <span v-for="t in m.jTeam" :key="t" class="badge bg-secondary me-1">{{t.name}}</span>
                                        </div>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" @click="updMbrList" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Update
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal - Delete team --> 
    <div
        class="modal fade"
        id="mdlDelTeam"
        tabindex="-1"
        aria-labelledby="AddDelTeam"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title"> Delete team </h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 pb-3">
                            Confirm delete '{{teamName}}' team?
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" @click="delTeam" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'TeamList',
    components: { Alert, TopNavigation, Popper, /* Avatar, */ ImgAvatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        
        const dgMode = ref('all')  // all: load all, part: query db part by part
        const dgCutOffRcd = ref(30)  // decide dgMode if >= dgCutOffRcd part, else all
        const total = ref(0)
        const totalShown = ref(0)   // total records has been displayed
        const rcdFr = ref(0)        // record index from
        const rcdDisplay = ref(15)  // total record to display per scroll, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const filterName = ref('')

        const teamId = ref(null)
        const teamName = ref('')
        const checkedTeam = ref([])
        const checkedMember = ref([])
        const dupTeamId = ref('')
        const dupTeamName = ref('')
        const dupTeamName2 = ref('')
        const viewTeamId = ref('')

        const arrTeam = ref([])       // for dgMode=all, different fr arrDgTeam
        const arrFilterTeam = ref([]) // for dgMode=all only - handle filter and remain orig data in arrTeam
        const arrDgTeam = ref([])     // record shown in dg, total length shown as totalShown

        const arrMember = ref([])  // Add member
        const arrTeamMbr = ref([])
        const memberType = ref('all')   // all/member/nonmember

        const showMoreEditTeam = ref(false)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([filterName], () => {

            if (dgMode.value === 'all') {
                console.info('- - WATCH all', filterName.value.length)
                if (filterName.value.length === 0) {
                    iniDg() // reset filter

                } else if (filterName.value.length >= 3) {
                    dgAllFilter()
                    proDgAllData()
                }
            }
            else {
                console.info('- - WATCH part', filterName.value.length)
                if (filterName.value.length === 0) {
                    arrTeam.value = []
                    arrDgTeam.value = []
                    iniDg() // reset filter

                } else if (filterName.value.length >= 3) {
                    arrDgTeam.value = []
                    getTeamList()
                }
            }
        })

        const resetTeamList = () => {
            if (dgMode.value === 'all') {
                if (filterName.value !== '') {
                    filterName.value = '' // will go to watch

                } else {
                     iniDg() // reset filter
                }

            } else {
                if (filterName.value !== '') {
                    filterName.value = '' // will go to watch

                } else {
                    arrTeam.value = []
                    arrDgTeam.value = []
                    iniDg() // reset filter
                }
            }
        }

        const dgAllFilter = () => {
            console.info('- - func: dgAllFilter')
            let arrTmp = []
            for(var i = 0; i < arrTeam.value.length; i++) {
                if ( arrTeam.value[i].teamName.toUpperCase().includes(filterName.value.toUpperCase()) === true) {
                    arrTmp.push(arrTeam.value[i])
                }
            }

            total.value = arrTmp.length
            totalShown.value = 0
            rcdFr.value = 0

            arrFilterTeam.value = arrTmp
            arrDgTeam.value = []
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                // console.info('- - handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    
                    let tmp = rcdFr.value + rcdDisplay.value
                    // console.info('- handleScroll', 'tmp', tmp, '<', 'total', total.value,'| rcdFr PRE', rcdFr.value)
                    if (tmp < total.value) {
                        
                        isLoadingMore.value = true
                        rcdFr.value = rcdFr.value + rcdDisplay.value
                        // console.info('- - - - - handleScroll load more | rcdFr POST', rcdFr.value)
                        if (dgMode.value === 'all') {

                            proDgAllData()
                            isLoadingMore.value = false
                        }
                        else
                        {
                            getTeamList()
                            isLoadingMore.value = false
                            
                        }
                    }
                }
            }
        }

        const iniDg = async () => {
            total.value = 0
            totalShown.value = 0
            rcdFr.value = 0

            arrTeam.value = []
            arrDgTeam.value = []

            getTeamListIni()
        }

        // Get initial team list, without search criteria & rcd limit to decide dgMode
        const getTeamListIni = async () => {

            isLoading.value = true

            let datagridMode = dgMode.value 
            await axios.post('/entity/team/list', {})
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        total.value = res.data.description.rowCount

                        if (total.value >= dgCutOffRcd.value) {
                            // console.info('- - - part', res.data.data.length)
                            datagridMode = 'part'
                            arrTeam.value = res.data.data
                            proDgAllData() // set arrDgTeam to get partial data fr arrTeam(full, no search criteria & rcd limit)
                            arrTeam.value = arrDgTeam.value  // Set back arrTeam same as arrDgTeam for 
                        } 
                        else 
                        {
                            // console.info('- - - all', JSON.stringify(res.data.data))
                            datagridMode = 'all'
                            arrTeam.value = res.data.data
                            proDgAllData()
                        }

                    } else {
                        func.addLog('team', 'getTeamListIni', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                    isLoading.value = false
                })
                .catch((error) => {
                    isLoading.value = false
                    func.addLog('team', 'getTeamListIni - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })

            dgMode.value = datagridMode
        }

        // For dgMode = part - to get more team list
        const getTeamList = async () => {

            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value 
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.strSearch = filterName.value
            }

            console.info('- - func: getTeamList', 'REQ', JSON.stringify(p))

            axios.post('/entity/team/list', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        total.value = res.data.description.rowCount
    
                        if (res.data.data.length > 0) {
                            let tmp = []
                            tmp = res.data.data

                            for (var i = 0; i < tmp.length; i++) {
                                arrDgTeam.value.push(tmp[i])
                            }

                            totalShown.value = arrDgTeam.value.length
                            arrDgTeam.value = arrDgTeam.value
                        }

                    } else {
                        func.addLog('team', 'getTeamList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                        
                })
                .catch((error) => {
                    func.addLog('team', 'getTeamList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        // for dgMode = part, initial access once only
        // for dgModel = all, access when initial access, scroll more, filter
        const proDgAllData = async () => {
            let len = rcdFr.value + rcdDisplay.value

            if (filterName.value.length >= 3) {
                // console.info('- - - proDgAllData - filter', rcdFr.value, rcdDisplay.value, 'arrFilterTeam', arrFilterTeam.value.length, JSON.stringify(arrFilterTeam.value))
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilterTeam.value[i] !== undefined) {
                        arrDgTeam.value.push(arrFilterTeam.value[i])
                    }
                }
                totalShown.value = arrDgTeam.value.length

            }
            else
            {
                // console.info('- - - proDgAllData', rcdFr.value, rcdDisplay.value, 'len', len)
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrTeam.value[i] !== undefined) {
                        arrDgTeam.value.push(arrTeam.value[i])
                    }
                }
                totalShown.value = arrDgTeam.value.length
            }
        }

        const addTeam = () => {
            rcdFr.value = 0
            totalShown.value = 0
            arrTeam.value = []

            const p = { teamName: teamName.value }

            axios.post('/entity/team', p)
                .then((res) => {
                   
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        resetTeamList()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Team is added."
                        })

                    } else {
                        func.addLog('team', 'addTeam', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }

                    }
                })
                .catch((error) => {
                    func.addLog('team', 'addTeam - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const delTeam = () => {
            axios.delete('/entity/team/' + teamId.value)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        arrTeamMbr.value = []
                        viewTeamId.value = ''
                        totalShown.value = 0
                        rcdFr.value = 0
                        arrTeam.value = []

                        if (filterName.value !== '') {
                            filterName.value = ''

                        } else {
                            iniDg()

                        }

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "'" + teamName.value + "' team is removed."
                        })

                    } else {
                        func.addLog('team', 'delTeam', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                        
                    }
                })
                .catch((error) => {
                    func.addLog('team', 'delTeam - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const delTeams = async () => {
            let totalDel = 0
            for (var i = 0; i < checkedTeam.value.length; i++) {
                axios.delete('/entity/team/' + checkedTeam.value[i])
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            totalDel = totalDel + 1

                            if (totalDel === checkedTeam.value.length) {
                                alert.value.push({
                                    class: "success",
                                    title: "SUCCESS",
                                    message: (checkedTeam.value.length > 1 ? "Teams are removed." : "Team is removed.")
                                })

                                arrTeamMbr.value = []
                                viewTeamId.value = ''

                                resetTeamList()
                            }

                        } else {
                            func.addLog('team', 'delTeams', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('team', 'delTeams - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            } // for
        }

        const renameTeam = () => {
            const p = { teamName: teamName.value }
            axios.put('/entity/team/' + teamId.value + '/rename', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        resetTeamList()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Team is rename to '" + teamName.value + "'."
                        })

                    } else {
                        func.addLog('team', 'renameTeam', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('team', 'renameTeam - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const dupTeam = (copyMember) => {
            const p = {
                teamName: (copyMember === false || copyMember === 'false' ? dupTeamName.value : dupTeamName2.value),
                copyMember: (copyMember === true || copyMember === 'true' ? true : false)
            }

            axios.put('/entity/team/' + teamId.value + '/copy', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        resetTeamList()

                        dupTeamName.value = ''
                        dupTeamName2.value = ''

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Team is duplicated."
                        })

                    } else {
                        func.addLog('team', 'dupTeam', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('team', 'dupTeam - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const getAddMbrList = (data) => {
            teamId.value = data.teamId
            teamName.value = data.teamName 
            checkedMember.value = []

            const p = { type: memberType.value }
            axios.post('/entity/team/' + data.teamId + '/member/list', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        arrMember.value = res.data.data

                        for (var j = 0; j < arrMember.value.length; j++) {
                                if (arrMember.value[j].jTeam && arrMember.value[j].jTeam.length > 0) {
                                    for (var i = 0; i < arrMember.value[j].jTeam.length; i++) {

                                        if (arrMember.value[j].jTeam[i].id === teamId.value) {
                                            checkedMember.value.push(arrMember.value[j].userId)
                                        }
                                    }
                            }
                        }

                    } else {
                        func.addLog('team', 'getAddMbrList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('team', 'getAddMbrList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const updMbrList = () => {
            const p = {
                jMember: checkedMember.value
            }

            axios.put('/entity/team/' + teamId.value + '/member' , p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        resetTeamList()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Member is updated."
                        })

                    } else {
                        func.addLog('team', 'updMbrList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('team', 'updMbrList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getTeamMbrList = async (data) => {
            arrTeamMbr.value = []
            viewTeamId.value = data.teamId

            axios.get('/entity/team/' + data.teamId)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        arrTeamMbr.value = res.data.data.jMember

                    } else {
                        func.addLog('team', 'getTeamMbrList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('team', 'getTeamMbrList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            iniDg()

            let objMdl1 = document.querySelector('#mdlTeam')
            objMdl1.addEventListener("hidden.bs.modal", function (event) {
               showMoreEditTeam.value = false

            })

        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
            
        })

        return {
            alert, closeAlert, route, router, func, store, scrollComponent, 
            dgMode, dgCutOffRcd, total, totalShown, rcdFr, rcdDisplay, isLoading, isLoadingMore, 
            filterName, arrTeam, arrDgTeam, arrFilterTeam, 
            teamId, teamName, dupTeamId, dupTeamName, dupTeamName2, checkedTeam, checkedMember, memberType, viewTeamId, 
            arrMember, arrTeamMbr, 
            addTeam, delTeam, delTeams, renameTeam, dupTeam, getAddMbrList, updMbrList, getTeamMbrList, 
            showMoreEditTeam,
        }
    }

}
</script>

<style>

</style>