<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Contact support" icon="fa-solid fa-user-headset" />

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'

export default {
    name: 'Support',
    components: { Alert, TopNavigation },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getUserId, getDisplayName } = useStore()

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {

        })

        return {
            axios, func, route, router, store, alert, closeAlert, getUserId, getDisplayName
        }
    }
}
</script>

<style>
    
</style>