<template>
    
    <!-- <Vue3DraggableResizable
        :id="'box' + id" 
        :class="(objType === 'fullSignature' && signSrc !== null ? 'boxSign ' : (objType === 'initial' && iniSrc !== null ? 'boxInitial ' : (objType === 'stamp' && staSrc !== null ? 'boxStamp ' : (objType === 'comboSignature' && signSrc !== null ? 'boxCombo ' : (objType === 'fullSignature' ? 'boxSignDef ' : (objType === 'initial' ? 'boxInitialDef ' : (objType === 'stamp' ? 'boxStampDef ' : ( objType === 'comboSignature' ? 'boxComboDef ' : 'boxDisabled ' )))))))) + boxClass + 'Bg'"
        :initW="w2"
        :initH="h2"
        v-model:x="x"
        v-model:y="y" 
        v-model:w="w2"
        v-model:h="h2"
        :disabledX="true"
        :disabledY="true"
        :parent="true"
        :draggable="true"
        :resizable="objType === 'fullSignature' || objType === 'initial' || objType === 'stamp' ? true : false"
        @resizing="handleResizeEnd"
        :lockAspectRatio="objType === 'fullSignature' || objType === 'initial' || objType === 'stamp' ? true : false" 
        style="z-index: 2"
        > -->
        <!-- NOTE: must set false for disabledX & disabledY, to remain same position after scale -->
        <!-- :disabledX="false"
        :disabledY="false" -->

    <Vue3DraggableResizable
        :id="'box' + id" 
        :class="boxClass2" 
        :initW="w2"
        :initH="h2"
        v-model:x="x2"
        v-model:y="y2" 
        v-model:w="w2"
        v-model:h="h2"
        :parent="true"
        :draggable="isResizeable"
        :resizable="isResizeable"
        @resizing="handleResizeEnd"
        @drag-start="handleDragEnd"
        :lockAspectRatio="isResizeable" 
        style="z-index: 2"
        :key="randKey"
    >

    <div v-if="objType === 'name'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Name</span>
    </div>

    <div v-if="objType === 'email'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Email</span>
    </div>

    <div v-if="objType === 'idPassport'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">ID/Passport</span>
    </div>

    <div v-if="objType === 'textbox'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="font" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight+ '; font-style: ' + fontStyle">Textbox</span>
    </div>

    <div v-if="objType === 'textarea'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="bars" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Textarea</span>
    </div>

    <div v-if="objType === 'checkbox'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="check-square" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
        <span class="p-0 align-top">Checkbox</span>
    </div>

    <div v-if="objType === 'dateSigned'" class="ps-1 p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
        <fa icon="calendar-alt" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" />
        <span class="p-0 align-top">Date Signed</span>
        <!-- <VueDatePicker v-model="date" model-type="yyyy-MM-dd" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" :enable-time-picker="false" auto-apply></VueDatePicker> -->
    </div>

    <div v-if="objType === 'fullSignature'">
        <div v-if="signSrc !== null" class="text-center" >
            <ImgSign :id="signSrc" :width="signW" :height="signH" label="signature" :key="signW"/>
        </div>
        <div class="p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
            <fa icon="signature" size="lg" class="me-1 ps-0 align-top" style="cursor: pointer" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + 'cursor: pointer'">Full Signature</span>
        </div>
        <span class="resizeIcon d-none d-md-block"><fa icon="down-left-and-up-right-to-center"/></span>
    </div>

    <div v-if="objType === 'stamp'" >
        <div v-if="staSrc !== null" class="text-center">
            <ImgSign :id="staSrc" :width="staW" :height="staH" label="stamp"/>
        </div>
        <div class="p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
            <fa icon="stamp" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + 'cursor: pointer'">Stamp</span>
        </div>
        <span class="resizeIcon d-none d-md-block"><fa icon="down-left-and-up-right-to-center"/></span>
    </div>

    <div v-if="objType === 'initial'">
        <div v-if="iniSrc !== null" class="text-center">
            <ImgSign :id="iniSrc" label="initial" :width="iniW" :height="iniH" />
        </div>
        <div class="p-0" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
            <fa icon="pen-nib" class="me-1 ps-0 align-top" style="margin-top: 0px" />
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + 'cursor: pointer'">Initial</span>
        </div>
        <span class="resizeIcon d-none d-md-block"><fa icon="down-left-and-up-right-to-center"/></span>
    </div>


    <!-- data Signature -->
    <div v-if="objType === 'comboSignature' && comboStructure === 'sign' && signSrc === dataSignatureId" class="p-0 h-100"  data-bs-toggle="modal" :data-bs-target="'#' + mdl">
       <div :style="'height: ' + (h2 - dataSignatureBottomGap) + 'px'" :class="boxClass + 'Bg'">
           <div :style="'height: ' + (h2 - dataSignatureBottomGap) + 'px; width: 100%; top: 0; position: absolute; z-index: 8'">
               <div>
                   <fa icon="signature" size="lg" class="me-1 ps-1 align-top" style="margin-top: 0px; " /> 
                   <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Signature Block</span>
               </div>
               
               <div :style="'position: absolute; left: 0; bottom: 0; width: ' +comboDataW+ 'px; height: ' +comboDataH+ 'px'">
                   <img src="./../assets/template/template1_96.png" class="img-responsive" :style="'height: '+comboDataH+'px ; width: '+comboDataW+'px; display: block;'" />
                   <div :style="'font-size: '+(4*scale)+'pt; font-family: courier; padding-left: '+(2*scale)+'px'" class="text-info">
                        <div>{{ dateSignIdSample.replace(/(.{4})/g, '$1 ').trim() }}</div>
                        <div>{{ func.convDateTimeFormat(new Date(), 'datetimegmt') }}</div>
                    </div>
                    <div :style="'color: black; position: absolute; z-index: 0; top: '+(10*scale)+'px; left: '+(10*scale)+'px'">
                        <!-- orig
                        <div v-for="c in data.combo" :key="c">
                            <div v-if="c === 'cFullLegalName' && comboData !== undefined">{{comboData.fullLegalName}}</div>
                            <div v-if="c === 'cOrganization' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.organization}}</div>
                            <div v-if="c === 'cDesignation' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.designation}}</div>
                            <div v-if="c === 'cNric' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.organization}}</div>
                            <div v-if="c === 'cPassport' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.passport}}</div>
                            <div v-if="c === 'cDateSigned' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{func.convDateTimeFormat(new Date(), 'datetext')}}</div>
                        </div> -->
                        <div v-for="c in data.combo" :key="c">
                            <div v-if="c === 'cFullLegalName' && comboData !== undefined">{{comboData.fullLegalName}}</div>
                            <div v-if="c === 'cOrganization' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.organization}}</div>
                            <div v-if="c === 'cDesignation' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.designation}}</div>
                            <div v-if="c === 'cNric' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.organization}}</div>
                            <div v-if="c === 'cPassport' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{comboData.passport}}</div>
                            <div v-if="c === 'cDateSigned' && comboData !== undefined" :style="'line-height: '+ (fontSize*scale) +'pt;'">{{func.convDateTimeFormat(new Date(), 'datetext')}}</div>
                        </div>
                    </div>
               </div>
           </div> 
       </div>
    </div>

    <!-- e-signature (signature / stamp) -->
    <div v-else-if="objType === 'comboSignature'" class="p-0 h-100"  data-bs-toggle="modal" :data-bs-target="'#' + mdl">
       <div :style="'height: ' + (h2*comboRatio) + 'px'" :class="boxClass + 'Bg'">
           <div :style="'height: ' + (h2*comboRatio) + 'px; width: 100%; top: 0; position: absolute; z-index: 8'">
               <div>
                   <fa icon="signature" size="lg" class="me-1 ps-1 align-top" style="margin-top: 0px; " /> 
                   <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Signature Block</span>
               </div>
               
               <div :style="'position: absolute; left: 0; bottom: 0; width: ' +comboSignW+ 'px; height: ' +comboSignH+ 'px'">
                   <ImgSign :id="signSrc" :width="comboSignW" :height="comboSignH" />
               </div>
               <div v-if="comboStructure === 'signstaoverlap'" :style="'position: absolute; bottom: 0; right: 0; width: ' +comboStaW+ 'px; height: '+comboStaH+ 'px'">
                   <ImgSign :id="staSrc" :width="comboStaW" :height="comboStaH" />
               </div>
           </div> 
       </div>

       <div :style="'cursor: pointer; margin-top: ' +comboGap+ 'px; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">
           <div v-for="c in data.combo" :key="c" class="m-0 p-0">
               <div :style="'font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle" class="m-0 p-0">
                   <p v-if="c === 'cFullLegalName' && comboData !== undefined" :style="'margin-left: '+(scale*5)+'px; margin-right: '+(scale*5)+'px; margin-top: 0px; margin-bottom: 0px; padding-bottom: ' + (6*scale) + 'px; line-height: 1.2'">{{comboData.fullLegalName}}</p>
                   <p v-if="c === 'cOrganization' && comboData !== undefined" :style="'margin-left: '+(scale*5)+'px; margin-right: '+(scale*5)+'px; margin-top: 0px; margin-bottom: 0px; padding-bottom: ' + (6*scale) + 'px; line-height: 1.2'">{{comboData.organization}}</p>
                   <p v-if="c === 'cDesignation' && comboData !== undefined" :style="'margin-left: '+(scale*5)+'px; margin-right: '+(scale*5)+'px; margin-top: 0px; margin-bottom: 0px; padding-bottom: ' + (6*scale) + 'px; line-height: 1.2'">{{comboData.designation}}</p>
                   <p v-if="c === 'cNric' && comboData !== undefined" :style="'margin-left: '+(scale*5)+'px; margin-right: '+(scale*5)+'px; margin-top: 0px; margin-bottom: 0px; padding-bottom: ' + (6*scale) + 'px; line-height: 1.2'">{{comboData.nric}}</p>
                   <p v-if="c === 'cPassport' && comboData !== undefined" :style="'margin-left: '+(scale*5)+'px; margin-right: '+(scale*5)+'px; margin-top: 0px; margin-bottom: 0px; padding-bottom: ' + (6*scale) + 'px; line-height: 1.2'">{{comboData.passport}}</p>
                   <p v-if="c === 'cDateSigned' && comboData !== undefined" :style="'margin-left: '+(scale*5)+'px; margin-right: '+(scale*5)+'px; margin-top: 0px; margin-bottom: 0px; padding-bottom: ' + (6*scale) + 'px; line-height: 1.2'">{{func.convDateTimeFormat(new Date(), 'datetext')}}</p>
               </div>
           </div>
       </div>
    </div>



    <!-- Temporary hide date picker feature
    <div v-if="title === 'Date Signed'" @click="openAttr()" class="p-1">
        <fa icon="calendar-alt" size="lg" class="me-1" />
        <span>{{title}}</span>
        <VueDatePicker v-model="date" model-type="yyyy-MM-dd" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" :enable-time-picker="false" auto-apply></VueDatePicker>
        <Datepicker :modelValue="date" @update:modelValue="setDate" :enableTimePicker="false" position="left" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" class="boxCal" autoApply></Datepicker>
    </div> -->

    <!-- Temporary hide click to edit feature 
    <div v-if="title === 'Textbox' && editable[id]" class="p-1">
        <table>
            <tr>
                <td><fa icon="font" size="lg" class="me-1" /></td>
                <td><input v-if="editable[id]" type="text" v-model="label" class="form-control form-control-sm" style="margin: 0px 8px; width: 150px" @blur="toggleEdit(id)" /></td>
            </tr>
        </table>
    </div>

    <div v-if="title === 'Textbox' && !editable[id]" class="p-1">
        <fa icon="font" size="lg" class="me-1" />
        <span @click="toggleEdit(id)">{{ label }}</span>
    </div> -->

    <!-- <div class="text-success small">comboGap: {{ comboGap }}</div> -->
    <!-- <div class="text-danger small">comboStructure: {{ comboStructure }}</div> -->
    <!-- <div class="text-success small">box: {{w2}}, {{ h2 }}</div> -->
    <!-- <div class="text-success small">fontSize: {{fontSize}}</div> -->
    <!-- <div class="text-success small">scale: {{scale}} | {{ x2 }}, {{ y2 }}</div> -->
    <!-- <div class="text-danger small">{{ id }} | {{ comboRatio }}</div> -->
    <!-- <div class="text-danger small">h2: {{ h2 }} | comboDataH: {{ comboDataH }}</div> -->
    <!-- <div class="text-danger small">{{ comboSignWOrig }} x {{ comboSignHOrig }} | {{ comboSignW }} x {{ comboSignH }}</div> -->
    

    </Vue3DraggableResizable>
   
</template>
 
<script>
import { ref, watch, onMounted, inject } from 'vue'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import funcs from '@/functions/function'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import ImgSign from '@/components/ImgSign.vue'


export default {
    props: [ 'data', 'boxClass', 'mdl', 'comboData', 'signSrc', 'iniSrc', 'staSrc', 'comboSignSrc', 'sWidth', 'sHeight', 'iWidth', 'iHeight', 'stWidth', 'stHeight', 'signRatio', 'iniRatio', 'staRatio', 'viewWidth', 'scale'],
    components: { Vue3DraggableResizable, VueDatePicker, ImgSign },
    emits: ['getResize'],
    setup (props, { emit }) {

        const func = funcs

        const signMaxW = ref(250)
        const signMaxH = ref(120)
        const iniMaxW = ref(140)
        const iniMaxH = ref(60)
        const staMaxW = ref(250)
        const staMaxH = ref(140)

        const x = ref(props.data.position.x)
        const y = ref(props.data.position.y)
        const x2 = ref(props.data.position.x)
        const y2 = ref(props.data.position.y)

        const w = ref(200)
        const h = ref(32)
        const w2 = ref(280) // ui
        const h2 = ref(38)  // ui

        const signee = ref(props.signee)
        const label = ref(props.data.label)
        const placeHolder = ref(props.data.placeholder)
        const mandatory = ref(props.data.isMandatory)
        const page = ref(props.data.position.page)
        const objType = ref(props.data.type)
        // const fontFamily = ref(props.data.format.font)
        // const fontFamily = ref('Arial Unicode MS')
        const fontFamily = ref('Noto Sans')
        // const fontFamily = ref('Times New Roman')
        const fontSize = ref(props.data.format.size)    
        const isBold = ref(props.data.format.bold)      // true/false
        const isItalic = ref(props.data.format.italic)  // true/false
        // const isBold = ref(true) 
        // const isItalic = ref(true)

        const fontWeight = ref('normal') // normal/bold
        const fontStyle = ref('normal')  // normal/italic
        const boxClass = ref(props.boxClass)
        const boxClass2 = ref(null)
        const mdlId = ref(props.mdl)
        const viewWidth = ref(props.viewWidth)
        const scale = ref(props.scale)
        const signSrc = ref(props.signSrc)
        const iniSrc = ref(props.iniSrc)
        const staSrc = ref(props.staSrc)
        const comboSignSrc = ref(props.comboSignSrc)
        const comboStructure = ref(props.data.comboStructure)
        const comboGap = ref(7) // in px aka 5pt
        const comboRatio = ref(props.data.ratio)
        const combo = ref(props.data.combo)

        const comboSignWOrig = ref(385)
        const comboSignHOrig = ref(224)
        const comboStaWOrig = ref(648)
        const comboStaHOrig = ref(648)
        const comboDataWOrig = ref(3024)
        const comboDataHOrig = ref(1134)

        const comboSignW = ref(385)
        const comboSignH = ref(224)
        const comboStaW = ref(648)
        const comboStaH = ref(648)
        const comboDataW = ref(3024)
        const comboDataH = ref(1134)
        const dataSignatureId = ref(1)  //hardcoded 
        const dataSignatureBottomGap = ref(15)  //hardcoded px

        const signW = ref(props.sWidth)
        const signH = ref(props.sHeight)
        const iniW = ref(props.iWidth)
        const iniH = ref(props.iHeight)
        const staW = ref(props.stWidth)
        const staH = ref(props.stHeight)

        const dataSignW = ref(props.sWidth)
        const dataSignH = ref(props.sHeight)
        const dateSignIdSample = ref('EA1EF1F09B181EF973EA2EB3A8803120')

        const isResizeable = ref(false) // for fullSignature, initial and stamp to resize image
        const randKey = ref(0)
        
        /**
         * Applicable to potrait & landscape, adjust bigger & smaller
         * Img ratio = Orig Width / Orig Height
         * 
         * New Height = New Width / Img Ratio
         * New Width = New Height * Img ratio
         * 
         */
   
        if (objType.value === 'fullSignature') {
            w.value = 250
            h.value = 120 

        } else if (objType.value === 'initial') {
            w.value = 140
            h.value = 60

        } else if (objType.value === 'stamp') {
            w.value = 250
            h.value = 140

        } else if (objType.value === 'comboSignature') {
            w.value = props.data.width === 0 ? 250 : props.data.width
            h.value = props.data.height === 0 ? 300 : props.data.height

        } else if (objType.value === 'textarea') {
            w.value = props.data.width
            h.value = props.data.height

        } else {
            if (props.data.width !== 0) {
                w.value = props.data.width
            }
            if (props.data.height !== 0) {
                h.value = props.data.height
            }
        }

        if (fontFamily.value === undefined) {
            fontFamily.value = 'arial'
        }

        if (fontSize.value === undefined) {
            fontSize.value = 14
        }

        if (isBold.value === true || isBold.value === 'true') {
            fontWeight.value = 'bold'
        } else {
            fontWeight.value = 'normal'
        }
  
        if (isItalic.value === true || isItalic.value === 'true') {
            fontStyle.value = 'italic'
        } else {
            fontStyle.value = 'normal'
        }

        const id = ref(props.data.id)
        const editable = ref([])
        const mdlBoxId = ref(null)


        const detectUnicodeInObject = (obj) => {
            // console.info('obj', JSON.stringify(obj))
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key]

                    // If the value is a string and contains Unicode characters
                    if (typeof value === 'string' && containsUnicode(value)) {
                        return true; // Unicode found
                    }

                    // If the value is an object, recurse into it
                    if (typeof value === 'object' && value !== null) {
                        if (detectUnicodeInObject(value)) {
                            return true // Unicode found in nested object
                        }
                    }
                }
            }

            return false // No Unicode found
        }

        const containsUnicode = (value) => {
            // Regular expression to match any character outside of the ASCII range
            const unicodeRegex = /[^\x00-\x7F]/
            return unicodeRegex.test(value)
        }

        const enlargeImg = async (imgType, imgW, imgH) => {
            const maxW = w2.value
            let maxH = h2.value * comboRatio.value
            if (imgType === 'dataSign') {
                maxH = h2.value - dataSignatureBottomGap.value
            }

            let newWidth = imgW
            let newHeight = imgH

            let i = 0
            while ((imgW < maxW && imgH < maxH) && i <= 10) {
                i = i + 1

                newHeight = newHeight * 1.1
                newWidth = newWidth * 1.1

                imgW = newWidth
                imgH = newHeight
            }

            if (imgType === 'sta') {
                // stamp
                comboStaW.value = Math.floor(newWidth)
                comboStaH.value = Math.floor(newHeight)

                if (comboStaW.value > maxW || comboStaH.value > maxH) {
                    resizeImg('sta', comboStaW.value, comboStaH.value)
                }

            } else if (imgType === 'sign') {

                // signature
                comboSignW.value = Math.floor(newWidth)
                comboSignH.value = Math.floor(newHeight)

                if (comboSignW.value > maxW || comboSignH.value > maxH) {
                    resizeImg('sign', comboSignW.value, comboSignH.value)
                }

            } else if (imgType === 'dataSign') {
                // data signature
                comboDataW.value = Math.floor(newWidth)
                comboDataH.value = Math.floor(newHeight)

                if (comboDataW.value > maxW || comboDataH.value > maxH) {
                    resizeImg('dataSign', comboDataW.value, comboDataH.value)
                }
            }
            
        }

        const resizeImg = async (imgType, imgW, imgH) => {
            const maxW = w2.value
            let maxH = h2.value * comboRatio.value
            if (imgType === 'dataSign') {
                maxH = h2.value - dataSignatureBottomGap.value
            }
            const aspectRatio = imgW / imgH

            let newWidth = imgW
            let newHeight = imgH

            /* ISSUE: failed to get bigger image when enlarge from small */
            let i = 0
            // Resize based on max constraints, limit max resize 5 times to avoid unfinite loop
            // if (imgW > maxW || imgH > maxH) {
                while ((imgW > maxW || imgH > maxH) && i <= 5) {
                    i = i + 1
                    if (imgW / maxW > imgH / maxH) {
                        // Width is the limiting factor
                        newWidth = maxW
                        newHeight = maxW / aspectRatio
                    } else {
                        // Height is the limiting factor
                        newHeight = maxH
                        newWidth = maxH * aspectRatio
                    }

                    imgW = newWidth
                    imgH = newHeight
                }
            // }
                

            if (imgType === 'sta') {
                // stamp
                comboStaW.value = Math.floor(newWidth)
                comboStaH.value = Math.floor(newHeight)

            } else if (imgType === 'sign') {
                // signature
                comboSignW.value = Math.floor(newWidth)
                comboSignH.value = Math.floor(newHeight)

            } else if (imgType === 'dataSign') {
                comboDataW.value = Math.floor(newWidth)
                comboDataH.value = Math.floor(newHeight)
            }

        }

        const handleDragEnd = () => {
            // disabled img to change coordiate when resize, as need to set draggable = true when activate resize
            x.value = props.data.position.x * scale.value
            y.value = props.data.position.y * scale.value
        }

        const toggleEdit = (id) => {
            editable.value[id] = !(editable.value[id] === true ? true : false)
        }
       
        const handleResizeEnd = async (obj) => {
            //temp
        }

        onMounted(async() => {
            w2.value = w.value * scale.value
            h2.value = h.value * scale.value

            x2.value = x.value * scale.value
            y2.value = y.value * scale.value

            // signMaxW.value = signMaxW.value * scale.value
            // signMaxH.value = signMaxH.value * scale.value
            iniMaxW.value = iniMaxW.value * scale.value
            iniMaxH.value = iniMaxH.value * scale.value
            // staMaxW.value = staMaxW.value * scale.value
            // staMaxH.value = staMaxH.value * scale.value

            // signW.value = signW.value * scale.value
            // signH.value = signH.value * scale.value
            iniW.value = iniW.value * scale.value
            iniH.value = iniH.value * scale.value
            // staW.value = staW.value * scale.value
            // staH.value = staH.value * scale.value

            comboSignWOrig.value = signW.value * scale.value

            // console.info('------------- ', JSON.stringify(props.sWidth, props.sHeight))
            // console.info('------------- ', comboSignWOrig.value, signW.value, '*', scale.value)
            comboSignHOrig.value = signH.value * scale.value
            comboStaWOrig.value = staW.value * scale.value
            comboStaHOrig.value = staH.value * scale.value

            comboSignW.value = signW.value * scale.value
            comboSignH.value = signH.value * scale.value
            comboStaW.value = staW.value * scale.value
            comboStaH.value = staH.value * scale.value

            // console.info('---- signSrc', signSrc.value)
            // console.info('------- signW', signW.value, signH.value)

            if (objType.value === 'fullSignature' || objType.value === 'initial' || objType.value === 'stamp') {
                isResizeable.value = true

                if (objType.value === 'fullSignature' && signSrc.value !== null) {
                    boxClass2.value = 'boxSign ' + boxClass.value + 'Bg'

                } else if (objType.value === 'initial' && iniSrc.value !== null) {
                    boxClass2.value = 'boxInitial ' + boxClass.value + 'Bg'

                } else if (objType.value === 'stamp' && staSrc.value !== null) {
                    boxClass2.value = 'boxStamp ' + boxClass.value + 'Bg'

                } else if (objType.value === 'comboSignature' && signSrc.value !== null) {
                    boxClass2.value = 'boxCombo ' + boxClass.value + 'Bg'

                } else if (objType.value === 'fullSignature') {
                    boxClass2.value = 'boxSignDef ' + boxClass.value + 'Bg'
                
                } else if (objType.value === 'initial') {
                    boxClass2.value = 'boxInitialDef ' + boxClass.value + 'Bg'

                } else if (objType.value === 'stamp') {
                    boxClass2.value = 'boxStampDef ' + boxClass.value + 'Bg'

                } else if (objType.value === 'comboSignature') {
                    boxClass2.value = 'boxComboDef ' + boxClass.value + 'Bg'

                } else {
                    boxClass2.value = 'boxDisabled ' + boxClass.value + 'Bg'
                }

            } else {
                isResizeable.value = false
                boxClass2.value = 'boxDisabled ' + boxClass.value + 'Bg'
            }

            if (objType.value === 'comboSignature') {
                
                // console.info('comboSignature', comboStructure.value, id.value)
                // resizeImg = async (imgType, imgW, imgH)
                const maxW = w2.value
                const maxH = h2.value *comboRatio.value

                // console.info('comboSignature w2, h2', w2.value, h2.value, 'maxH:', maxH)

                // console.info('comboSignature', comboStructure.value, id.value, maxW, maxH)
                if (signSrc.value === dataSignatureId.value) {
                    
                    h2.value = (h2.value + dataSignatureBottomGap.value) * comboRatio.value

                    // signature data method choosen
                    if (comboDataWOrig.value > maxW || comboDataHOrig.value > maxH) {
                        resizeImg('dataSign', comboDataWOrig.value, comboDataHOrig.value)

                    } else {
                        // Enlarge image
                        enlargeImg('dataSign', comboDataWOrig.value, comboDataHOrig.value)
                    }

                } else {
                    if (comboStructure.value === 'signstaoverlap') {

                        if (comboStaWOrig.value > maxW || comboStaHOrig.value > maxH) {
                            resizeImg('sta', comboStaWOrig.value, comboStaHOrig.value)

                        } else {
                            enlargeImg('sta', comboStaWOrig.value, comboStaHOrig.value)
                        }
                    }

                    if (comboSignWOrig.value > maxW || comboSignHOrig.value > maxH) {
                        resizeImg('sign', comboSignWOrig.value, comboSignHOrig.value)

                    } else {
                        // Enlarge image
                        enlargeImg('sign', comboSignWOrig.value, comboSignHOrig.value)
                    }
                }
            }

            comboGap.value = comboGap.value * scale.value
            
            randKey.value = Math.floor(Math.random() * 1000)

            // console.info('OBJ', objType.value, 'coordinate (x,y):', x2.value, y2.value, 'W x H:', w2.value, h2.value)
            // console.info('* onmounted ' + objType.value, JSON.stringify(props.data))

            /* if (props.comboData) {
                
                let strWithUnicode = detectUnicodeInObject(props.comboData)
                console.info('- - hasUnicode', hasUnicode, JSON.stringify(props.comboData))
            } */
                
            // emit('getResize', objType.value, id.value, w2.value, h2.value)
        })

        return { 
          func, x, y, x2, y2, id, w, h, signee, label, page, placeHolder, mandatory, objType, boxClass,
          fontFamily, fontSize, fontWeight, fontStyle, toggleEdit, editable, mdlBoxId, handleResizeEnd, mdlId,
          signW, signH, iniW, iniH, staW, staH, viewWidth, 
          signMaxW, signMaxH, iniMaxW, iniMaxH, staMaxW, staMaxH, 
          w2, h2, isResizeable, signSrc, iniSrc, staSrc, comboSignSrc, boxClass2, handleDragEnd, randKey, 
          comboStructure, isBold, isItalic, comboGap, comboRatio, 
          comboSignW, comboSignH, comboStaW, comboStaH, combo, 
          comboSignWOrig, comboSignHOrig, comboStaWOrig, comboStaHOrig, dataSignatureId, comboDataW, comboDataH, comboDataWOrig, comboDataHOrig, 
          dataSignatureBottomGap, dateSignIdSample
        }
    }
}
</script>

<style>
</style>