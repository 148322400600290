<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="KYC Verification" icon="fa-solid fa-address-card" />

    <div class="generalFullWidthBorderlessBox">
        <div class="row mb-3">
            <div class="col-12 mb-3">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <router-link :to="{ name: 'BOKycList'}">
                          <div class="text-secondary nav-link">User</div>    
                      </router-link>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link active" href="#">Entity</a>
                  </li>
                </ul> 
            </div>
            <div class="col-12 col-lg-4 mb-3">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Entity name">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="filterName = ''">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <!-- Small screen -->
            <div class="col-12 col-lg-8 text-primary isLink d-lg-none">
                <select v-model="filterStatus" class="form-select">
                    <option value="">All</option>
                    <option value="PENDING">PENDING</option>
                    <option value="SUBMITTED">SUBMITTED</option>
                    <option value="PROCESS">PROCESS</option>
                    <option value="APPROVED">APPROVED</option>
                    <option value="REJECTED">REJECTED</option>
                </select>
            </div>

            <!-- Large screen -->
            <div class="col-12 col-lg-8 text-primary text-end isLink d-none d-lg-block">
                <span class="filter-pill-border me-2">
                    <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">All</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'PENDING' ? 'active' : ''" @click="filterStatus = 'PENDING'">PENDING</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'SUBMITTED' ? 'active' : ''" @click="filterStatus = 'SUBMITTED'">SUBMITTED</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'PROCESS' ? 'active' : ''" @click="filterStatus = 'PROCESS'">PROCESS</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'APPROVED' ? 'active' : ''" @click="filterStatus = 'APPROVED'">APPROVED</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'">REJECTED</span>
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="generalContentBox border shadow-sm">
                    <div v-if="isLoadDg === false" class="row">
                        <div class="col-12">
                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                        </div>
                    </div>
                    <div v-else class="row">
                        <div v-if="total === 0 && isLoadDg === false" class="col-12">No record</div>
                        <div v-else class="col-12">
                            <div v-if="isLoading === true" class="mb-3">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>  

                            <div v-if="isLoading === false" class="mb-3 text-end small fst-italic">{{totalShown}} of {{total}}</div>
                            <div ref="scrollComponent">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr v-for="item in arrDgItem" :key="item">
                                            <td v-if="item && item.jEntity.jLogo" width="100px" class="text-center isLink" @click="$router.push('/backoffice/ekyc/' + item.entityId)">
                                                <ImgLogo :key="item.jEntity.jLogo.entityId"  :id="item.jEntity.jLogo.entityId" :width="item.jEntity.jLogo.width" :height="item.jEntity.jLogo.height" />
                                                <!-- <Logo :url="item.jEntity.jLogo.imgBase64" :width="item.jEntity.jLogo.width" :height="item.jEntity.jLogo.height" /> -->
                                            </td>
                                            <td>
                                                <div class="row">
                                                    <div class="col-lg-5 fw-bold isLink" @click="$router.push('/backoffice/ekyc/' + item.entityId)">{{item.jEntity.entityName}}</div>
                                                    <div class="col-lg-3 small text-grey">{{item.jEntity.jCreator.fullName}}</div>
                                                    <div class="col-lg-2 fst-italic text-grey small">{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateCreate), new Date()) }}</div>
                                                    <div class="col-lg-2 text-grey small text-end">{{item.status}}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Logo from '@/components/Logo.vue'
import ImgLogo from '@/components/ImgLogo.vue'

export default {
    name: 'BOEKycList',
    components: { Alert, TopNavigation, Popper, /* Logo, */ ImgLogo },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getUserId } = useStore()

        const scrollComponent = ref(null)
        const total = ref(0)        // total folder user can see w/o filter
        const totalShown = ref(0) // total records has been displayed
        const rcdFr = ref(0) // record index from 
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoadDg = ref(false)   // to indicate has load the arrDgItem, arrDgItem cannot set to null by default
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const arrItem = ref([])       // for dgMode=all, different fr arrDgItem
        const arrFilter = ref([]) // for dgMode=all only - handle filter and remain orig data in arrItem
        const arrDgItem = ref([])     // record shown in dg, total length shown as totalShown

        const totalFilter = ref(0)
        const filterName = ref('')
        const filterStatus = ref('')    // PENDING, ACTIVE,INACTIVE,SUSPENDED

        watch([filterName, filterStatus], () => {

            if (filterName.value.length === 0 && filterStatus.value === '') {
                // console.info('- - WATCH - I')
                rcdFr.value = 0
                totalFilter.value = 0
                totalShown.value = 0
                arrItem.value = []
                arrDgItem.value = []
                iniDg() // reset filter

            } else if (filterName.value.length >= 3 || filterStatus.value !== '') {
                // console.info('- - WATCH - II')
                totalFilter.value = 0
                if (filterName.value.length >= 3) totalFilter.value++
                if (filterStatus.value !== '') totalFilter.value++

                if (totalFilter.value > 0) {
                    rcdFr.value = 0
                    totalShown.value = 0
                    arrDgItem.value = []
                    getList()
                }
            }
        })

        const iniDg = async () => {
            total.value = 0
            totalShown.value = 0
            rcdFr.value = 0

            arrItem.value = []
            arrDgItem.value = []

            await getListIni()
        }

        // Get initial proj list, without search criteria & rcd limit to decide dgMode
        const getListIni = async () => {

            isLoading.value = true

            const p = {
                orderBy: [
                    {
                        field: 'dateCreate',
                        order: 'asc'
                    }
                ] 
            }
            await axios.post('/report/su/entity/kyc/list', p)
            .then((res) => {

                if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    // console.info('- - - total/rowCount', res.data.description.rowCount, JSON.stringify(res.data))
                    isLoadDg.value = true
                    total.value = res.data.description.rowCount

                    // Query db partially based on scroll
                    arrItem.value = res.data.data
                    proDgAllData() // set arrDgItem to get partial data fr arrItem(full, no search criteria & rcd limit)
                    arrItem.value = arrDgItem.value  // Set back arrItem same as arrDgItem for 
      
                } else {
                    func.addLog('BO:entityKycList', 'getListIni', res)
                }
                isLoading.value = false

            })
            .catch((error) => {

                func.addLog('BO:entityKycList', 'getListIni - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                isLoading.value = false
                isLoadDg.value = true

            })
        }

        // For dgMode = part - to get more proj list
        const getList = async () => {

            isLoading.value = true

            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value,
                orderBy: [
                    {
                        field: 'dateCreate',
                        order: 'asc'
                    }
                ] 
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.entityName = filterName.value
            }

            if (filterStatus.value !== '') {
                p.status = filterStatus.value
            }

            axios.post('/report/su/entity/kyc/list', p)
            .then((res) => {
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    total.value = res.data.description.rowCount

                    if (rcdFr.value === 0) {
                        totalShown.value = 0
                        arrDgItem.value = []
                    }
                    
                    // console.info('- - - getList RES rowCount', res.data.description.rowCount, 'length', res.data.data.length, 'REQ:', JSON.stringify(p))
   
                    if (res.data.data.length > 0) {
                        // console.info('- - - getList RES', res.data.data.length, JSON.stringify(res.data.data), 'REQ', JSON.stringify(p))
                        // console.info('- - - getList RES', JSON.stringify(res.data.data))
                        let tmp = []
                        tmp = res.data.data

                        for (var i = 0; i < tmp.length; i++) {
                            arrDgItem.value.push(tmp[i])
                        }

                        totalShown.value = arrDgItem.value.length
                        // console.info('after', arrDgItem.value.length)
                    }
                } else {
                    func.addLog('BO:entityKycList', 'getList', res)
                }

                isLoading.value = false    
            })
            .catch((error) => {
                func.addLog('BO:entityKycList', 'getList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                isLoading.value = false
            })
        }

        const proDgAllData = async () => {

            let len = rcdFr.value + rcdDisplay.value

            if (filterName.value.length >= 3 || filterStatus.value !== '') {
                // console.info('- - - proDgAllData - filter', rcdFr.value, rcdDisplay.value, 'arrFilterTeam', arrFilterTeam.value.length, JSON.stringify(arrFilterTeam.value))
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilter.value[i] !== undefined) {
                        arrDgItem.value.push(arrFilter.value[i])
                    }
                }
                totalShown.value = arrDgItem.value.length
            }
            else
            {
                // console.info('- - - proDgAllData', rcdFr.value, rcdDisplay.value, 'len', len)
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrItem.value[i] !== undefined) {
                        arrDgItem.value.push(arrItem.value[i])
                    }
                }
                totalShown.value = arrDgItem.value.length
            }
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    
                    let tmp = rcdFr.value + rcdDisplay.value
                    if (tmp < total.value) {
                        
                        isLoadingMore.value = true
                        rcdFr.value = rcdFr.value + rcdDisplay.value
                        
                        getList()
                        isLoadingMore.value = false
                            
                    }
                }
            }
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(async() => {
          
            window.addEventListener("scroll", handleScroll)
            func.clearPrevLocalStorage()

            await iniDg()

            console.info('onmounted -- isLoading', isLoading.value)
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return {
          alert, closeAlert, route, router, func, store,
          scrollComponent, total, totalShown, rcdFr, rcdDisplay, isLoadDg, isLoading, isLoadingMore,
          arrItem, arrFilter, arrDgItem, totalFilter, filterName, filterStatus, getUserId
        }
    }
}
</script>

<style>

</style>